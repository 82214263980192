import classNames from 'classnames'
import React from 'react'
import cx from "classnames"
import classes from "./Switch.module.scss";

const Switch = (props) => {
    return (
        <div>
            <label className={classes.switch}>
                <input type="checkbox" defaultChecked={props.checked} onChange={props.onChange}/>
                <span className={cx(classes.slider, classes.round)}></span>
            </label>
        </div>
    )
}

export default Switch;
