import React from 'react'
import Modal from '../Modal'
import Cross from "../../../assets/icons/Cross.svg"
import classes from "./DeleteEmployeeModal.module.css"
import ButtonError from '../../buttons/ButtonError'
import axios from 'axios'
import { useHistory } from 'react-router'

const DeleteEmployeeModal = (props) => {
    const {details, deleteEmployeeModalStatus, handleOpenDeleteModal, handleCloseDeleteModal} = props;
    const history = useHistory()
    const handleDeleteStaff = () => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/delete_staff`,
            method: "POST",
            data: {
                id: details.id
            }
        }
        axios(settings).then((res)=> {
            if(res.data.result === true) {
                alert("Deleted Successfully")
                history.push("/employees")
            } else {
                alert("Something went wrong!! Please Try Again")
            }
        }).catch((err) => {
            alert("Something went wrong!! Please Try after sometimes")
        })
    }
    return (
        <Modal modalStatus={deleteEmployeeModalStatus} onRequestClose={handleCloseDeleteModal} style={{height: "50%"}}>
            <div>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Delete Employee</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={handleCloseDeleteModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>Employee: {details.employee_name}</div>
            </div>
            <div className={classes.content}>
                <div>Are you sure you want to delete Employee <span style={{color: "#FF605C", fontWeight: "500"}}>{details.employee_name}</span> ?</div>
                <div>You won't be able to retrive them later.</div>
            </div>
            <ButtonError contained large onClick={handleDeleteStaff}>Delete Employee</ButtonError>
        </Modal>
    )
}

export default DeleteEmployeeModal
