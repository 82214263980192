import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  error: false,
  response: "",
};

const getstaffloanSlice = createSlice({
  name: "getstaffloan",
  initialState,
  reducers: {
    getstaffloan: (state) => {
      state.loading = true;
    },
    getstaffloanSuccess: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = false;
    },
    getstaffloanFailure: (state) => {
      state.loading = false;
      state.error = "Something went wrong";
      state.response = "";
    },
  },
});
export default getstaffloanSlice.reducer;

export const { getstaffloan, getstaffloanSuccess, getstaffloanFailure } =
  getstaffloanSlice.actions;

export const getstaffloanSelector = (state) => state.loading;
