import React from "react";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import Button, { createRipple } from "../compnents/buttons/Button";
import InputField from "../compnents/inputs/Input";
import Switch from "../compnents/switch/Switch";
import Checkbox from "../compnents/checkbox/Checkbox";
import Radio from "../compnents/radio/Radio";
import Tag from "../compnents/tag/Tag";
import Dropdown from "../compnents/dropdown/Dropdown.jsx";
import classNames from "classnames";
import TextField from "./TextField";

const dropdownitems = [
  { id: 1, name: "hello", link: "/" },
  { id: 2, name: "Deexit", link: "/" },
];

const Inputs = () => {
  const [firstname, setFirstName] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [switchOn, setSwitchOn] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [radio, setRadio] = React.useState("");
  const [activeTag, setActiveTag] = React.useState("");
  const [selectDropdown, setSelectDropdown] = React.useState(
    dropdownitems[1].name
  );
  const handleChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleBlur = () => {
    if (firstname.length <= 2) {
      setError((prevState) => ({
        ...prevState,
        firstname: "Not Valid",
      }));
      setSuccess((prevState) => ({
        ...prevState,
        firstname: "",
      }));
    } else {
      setError((prevState) => ({
        ...prevState,
        firstname: "",
      }));
      setSuccess((prevState) => ({
        ...prevState,
        firstname: "success",
      }));
    }
  };
  console.log(firstname);
  const handleSwitch = () => {
    setSwitchOn(!switchOn);
  };
  const handleChecked = () => {
    setChecked(!checked);
  };
  const handleRadio = (val) => {
    setRadio(val);
  };
  const handleSubmit = (e) => {
    createRipple(e);
    alert(firstname);
  };
  const handleActiveTag = (val) => {
    setActiveTag(val);
  };
  const handleDropdown = (e) => {
    console.log(e.target.innerText);
    setSelectDropdown(e.target.innerText);
  };
  return (
    <div>
      <InputField
        type="text"
        label="First Name"
        placeholder="Enter Name"
        id="firstname"
        onChange={(e) => handleChange(e)}
        onBlur={handleBlur}
        value={firstname}
        error={error.firstname}
        success={success.firstname}
        icon={faEye}
      />
      <Button contained onClick={(e) => handleSubmit(e)}>
        Submit
      </Button>
      <Switch checked={switchOn} onChange={handleSwitch} />
      <Checkbox checked={checked} onChange={handleChecked} />
      <Radio
        description="Hello"
        value="hello"
        checked={radio == "hello" ? true : false}
        onChange={() => handleRadio("hello")}
      />
      <Radio
        description="Deexit"
        value="deexit"
        checked={radio == "deexit" ? true : false}
        onChange={() => handleRadio("deexit")}
      />
      <Tag
        active={activeTag == "Hellop" ? true : false}
        onClick={() => handleActiveTag("Hellop")}
      >
        Hellop
      </Tag>
      <Tag
        active={activeTag == "Deexit" ? true : false}
        onClick={() => handleActiveTag("Deexit")}
      >
        Hellop
      </Tag>
      <Dropdown
        selected={selectDropdown}
        items={dropdownitems}
        onClick={(e) => handleDropdown(e)}
      />
      <Dropdown
        small
        selected={selectDropdown}
        items={dropdownitems}
        onClick={(e) => handleDropdown(e)}
      />
      <Dropdown
        large
        selected={selectDropdown}
        items={dropdownitems}
        onClick={(e) => handleDropdown(e)}
      />
      <TextField
        type="text"
        value={firstname}
        placeholder="Name"
        onChange={(e) => handleChange(e)}
        error=""
        success="success"
      />
    </div>
  );
};

export default Inputs;
