import React from 'react'
import classes from "./AddEmployeeModal.module.css"
import Modal from "../../modal/Modal";
import Cross from "../../../assets/icons/Cross.svg";
import InputField from "../../inputs/Input";
import Button, { createRipple } from "../../buttons/Button"
import Tag from "../../tag/Tag"
import Back from "../../../assets/icons/arrow-left.svg"
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import CalendarModal from "../../calendar/Calendar_modal"
import Calendar_dates from '../../calendar/Calendar_dates';
import DownArrow from "../../../assets/icons/caret_down.svg"
import Radio from '../../radio/Radio';
import axios from 'axios';
import Cookies from "js-cookie"

const AddEmployeeModal = (props) => {
  const {formStep, startingAmount,  name, handleInput, handleValidation,handleTag, error,success,  mobile, handleContinue, handleBack, tag, salary, handleCalendar, salaryCycle, handleSalaryCycle, openCalendar, radio, handleRadio, handleAddEmployee} = props
  
  let salaryLabel;
  if (tag === "Monthly") {
    salaryLabel = "Monthly Salary for Employee"
  } else if (tag === "Daily") {
    salaryLabel = "Daily Salary for Employee"
  } else if (tag === "Weekly") {
    salaryLabel = "Daily Salary of Employee"
  } else if (tag === "Hourly") {
    salaryLabel = "Hourly Salary of Employee"
  } else if (tag === "Work_Basis") {
    salaryLabel = "Salary based on work"
  }

  let modalContent;
  if (formStep === "one") {
    modalContent = <>
    <div>
      <div className={classes.heading_wrapper}>
        <div className={classes.modal_heading}>Add Employee</div>
        <img
          src={Cross}
          alt="Close"
          onClick={props.handleCloseModal}
          style={{ cursor: "pointer" }}
        />
      </div>

      <InputField
        id="employee_name"
        label="Employee Name"
        placeholder="Employee Name"
        value={name}
        onChange={(e) => handleInput(e, "name")}
        onBlur={() => handleValidation("name")}
        error={error.name}
        success={success.name}
        width="100%"
        margin="3vh 0 0 0 "
        large
      />
      <InputField
        id="mobile"
        label="Employee Mobile Number"
        placeholder="Employee Mobile Number"
        value={mobile}
        onChange={(e) => handleInput(e, "mobile")}
        onBlur={() => handleValidation("mobile")}
        error={error.mobile}
        success={success.mobile}
        width="100%"
        margin="2vh 0 0 0 "
        large
      />
    </div>
    <Button large contained onClick={(e) => handleContinue(e)} width="100%">
      Continue
    </Button>
  </>
  } else if (formStep === "two") {
    modalContent = <>
    <div style={{height: "80%"}}>
      <div className={classes.heading_wrapper}>
        <img src={Back} alt="Back" className={classes.backButton} onClick={handleBack}/>
        <div className={classes.modal_heading}>Add Employee</div>
        <img
          src={Cross}
          alt="Close"
          onClick={props.handleCloseModal}
          style={{ cursor: "pointer" }}

        />
      </div>
      <div className={classes.content_wrapper}>
        <Tag active={tag === "Monthly"} style={{width: "100%", marginTop: "10px 0", padding: "15px"}} onClick={() => handleTag("Monthly")}>
          <div className={classes.tag_head}>Monthly</div>
          <div className={classes.tag_content}>Fixed salary, salary payment per month</div>
        </Tag>
        <Tag active={tag === "Hourly"} style={{width: "100%", margin: "10px 0", padding: "15px"}} onClick={() => handleTag("Hourly")}>
          <div className={classes.tag_head}>Per Hour Basis</div>
          <div className={classes.tag_content}>Punch-in punch-out time, Paid hourly</div>
        </Tag>
        <Tag active={tag === "Daily"} style={{width: "100%", margin: "10px 0", padding: "15px"}} onClick={() => handleTag("Daily")}>
          <div className={classes.tag_head}>Daily</div>
          <div className={classes.tag_content}>Payment based on number of days worked</div>
        </Tag>
        <Tag active={tag === "Work_Basis"} style={{width: "100%", margin: "10px 0", padding: "15px"}} onClick={() => handleTag("Work_Basis")}>
          <div className={classes.tag_head}>Work Basis</div>
          <div className={classes.tag_content}>Payment per piece, work basis payment</div>
        </Tag>
        <Tag active={tag === "Weekly"} style={{width: "100%", margin: "10px 0", padding: "15px"}} onClick={() => handleTag("Weekly")}>
          <div className={classes.tag_head}>Weekly</div>
          <div className={classes.tag_content}>7 days work cycle, weekly payment system</div>
        </Tag>
      </div>
    </div>
    <Button padding="16px 35px" large contained onClick={(e) => handleContinue(e)} width="100%">
      Continue
    </Button>
    </>
  } else if (formStep === "three") {
    modalContent = <>
    <div style={{height: "80%"}}>
      <div className={classes.heading_wrapper}>
        <img src={Back} alt="Back" className={classes.backButton} onClick={handleBack}/>
        <div className={classes.modal_heading}>Add {tag} Employee</div>
        <img
          src={Cross}
          alt="Close"
          onClick={props.handleCloseModal}
          style={{ cursor: "pointer" }}

        />
      </div>
      <div className={classes.content_wrapper}>
      <InputField
        id="salary"
        label={salaryLabel}
        placeholder={salaryLabel}
        value={salary}
        onChange={(e) => handleInput(e, "salary")}
        onBlur={() => handleValidation("salary")}
        error={error.salary}
        success={success.salary}
        width="100%"
        margin="2vh 0 0 0"
        large
      />
      
      <div className={classes.calendar_input} onClick={handleCalendar}>
        <div className={classes.left_calender}>
          <div className={classes.label}>Salary Cycle</div>
          <div>
          {tag === "Weekly" ? `${salaryCycle} to ${salaryCycle} for every Week` : `${salaryCycle} to ${salaryCycle} for every ${tag}`}</div>
        </div>
        <img src={DownArrow} alt="Arrow" />
      </div>
      <Calendar_dates handleSalaryCycle={(e) => handleSalaryCycle(e)}  isopen={openCalendar} value={salaryCycle} tag={tag}/>
      </div>
    </div>
    <Button padding="16px 35px" large contained onClick={(e) => handleContinue(e)} width="100%">
      Continue
    </Button>
    </>
  } else if (formStep === "four") {
    modalContent = <>
    <div style={{height: "80%"}}>
      <div className={classes.heading_wrapper}>
        <img src={Back} alt="Back" className={classes.backButton} onClick={handleBack}/>
        <div className={classes.modal_heading}>Add Opening Balance</div>
        <img
          src={Cross}
          alt="Close"
          onClick={props.handleCloseModal}
          style={{ cursor: "pointer" }}
        />
      </div>
      <div className={classes.radio_wrapper}>
        <Radio
          description="Advanced"
          value="Advanced"
          checked={radio == "Advance" ? true : false}
          onChange={() => handleRadio("Advance")}
        />
        <Radio
          description="Pending"
          value="Pending"
          checked={radio == "Pending" ? true : false}
          onChange={() => handleRadio("Pending")}
        />
      </div>
      <InputField
        id="salary_type_with_amount"
        label={`Enter ${radio} amount`}
        placeholder={`Enter ${radio} amount`}
        value={startingAmount}
        onChange={(e) => handleInput(e, "startingAmount")}
        onBlur={() => handleValidation("startingAmount")}
        error={error.amount}
        success={success.amount}
        width="100%"
        margin="2vh 0 0 0 "
        large
      />
    </div>
    <Button padding="16px 35px" large contained onClick={(e) => handleAddEmployee(e)} width="100%">
      Add Employee
    </Button>
    </>
  }
    return (
        <Modal modalStatus={props.modalStatus} onRequestClose={props.handleCloseModal}>
        {modalContent}
      </Modal>
    )
}

export default AddEmployeeModal
