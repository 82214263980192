import classes from "./PersonalEdit.module.css";
import React from "react";
import { useLocation } from "react-router";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import EmployeeTopPanel from "../../compnents/EmployeeTopPanel/EmployeeTopPanel";
import Button from "../../compnents/buttons/Button";
import ButtonError from "../../compnents/buttons/ButtonError";
import EditBlue from "../../assets/icons/edit_blue.svg";
import DeleteWhite from "../../assets/icons/Delete_white.svg";
import EditPersonalDetailsModal from "../../compnents/modal/EditPersonalDetailsModal/EditPersonalDetailsModal";
import Cookies from "js-cookie";
import axios from "axios";
import DeleteEmployeeModal from "../../compnents/modal/DeleteEmployeeModal/DeleteEmployeeModal";

const PersonalEdit = () => {
  const location = useLocation();
  const details = location.state;
  const [personalDetailsModal, setPersonalDetailsModal] = React.useState(false);
  const [deleteEmployeeModalStatus, setDeleteEmployeeModalStatus] =
    React.useState(false);

  const [salaryDetailsModal, setSalaryDetailsModal] = React.useState(false);

  const [status, setStatus] = React.useState(
    details.status === "Active" ? true : false
  );
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const handleSwitch = () => {
    setStatus(!status);
  };

  const handleChange = (e, type) => {
    switch (type) {
      case "name":
        setName(e.target.value);
        break;
      case "email":
        setEmail(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
    }
  };
  const handleBlur = (type) => {
    switch (type) {
      case "name":
        if (name === "") {
          setError((prevState) => ({
            ...prevState,
            name: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            name: "",
          }));
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            name: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            name: "",
          }));
        }
        break;
      case "email":
        if (email === "") {
          setError((prevState) => ({
            ...prevState,
            email: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            email: "",
          }));
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            email: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            email: "",
          }));
        }
        break;
      case "phone":
        if (email === "") {
          setError((prevState) => ({
            ...prevState,
            phone: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            phone: "",
          }));
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            phone: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            phone: "",
          }));
        }
        break;
    }
  };
  const handleOpenDeleteModal = () => {
    setDeleteEmployeeModalStatus(true);
  };
  const handleCloseDeleteModal = () => {
    setDeleteEmployeeModalStatus(false);
  };

  const handleOpenPersonalDetailsModal = () => {
    setPersonalDetailsModal(true);
  };
  const handleClosePersonalDetailsModal = () => {
    setPersonalDetailsModal(false);
  };
  const handleOpenSalaryDetailsModal = () => {
    setSalaryDetailsModal(false);
  };
  const handleCloseSalaryDetailsModal = () => {
    setSalaryDetailsModal(true);
  };

  return (
    <Boilerplate>
      <EmployeeTopPanel details={details} />
      <div className={classes.edit_wrapper}>
        <div className={classes.details_wrapper}>
          <div className={classes.details_heading}>
            <div className={classes.heading}>Personal Details</div>
            <Button onClick={handleOpenPersonalDetailsModal}>
              <img src={EditBlue} alt="Edit" />
              Edit Personal Details
            </Button>
          </div>
          <div className={classes.row}>
            <div className={classes.internal_wrapper}>
              <div className={classes.title}>Employee Name</div>
              <div className={classes.value}>{details.employee_name}</div>
            </div>
            <div className={classes.internal_wrapper}>
              <div className={classes.title}>Phone Number</div>
              <div className={classes.value}>{details.employee_mobile}</div>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.internal_wrapper}>
              <div className={classes.title}>Email Id</div>
              <div className={classes.value}>Employee Name</div>
            </div>
            <div className={classes.internal_wrapper}>
              <div className={classes.title}>Employee Status</div>
              <div className={classes.value}>{details.status}</div>
            </div>
          </div>
        </div>
        <div className={classes.details_wrapper}>
          <div className={classes.details_heading}>
            <div className={classes.heading}>Bank Details</div>
            <Button>
              <img src={EditBlue} alt="Edit" />
              Edit Bank Details
            </Button>
          </div>
          <div className={classes.row}>
            <div className={classes.internal_wrapper}>
              <div className={classes.title}>Account Details</div>
              <div className={classes.value}>Employee Name</div>
            </div>
            <div className={classes.internal_wrapper}>
              <div className={classes.title}>UPI ID</div>
              <div className={classes.value}>Employee Name</div>
            </div>
          </div>
        </div>
        <div className={classes.details_wrapper}>
          <div className={classes.details_heading}>
            <div className={classes.heading}>Salary Details</div>
            <Button>
              <img src={EditBlue} alt="Edit" />
              Edit Salary Details
            </Button>
          </div>
          <div className={classes.row}>
            <div className={classes.internal_wrapper}>
              <div className={classes.title}>Shift Hours</div>
              <div className={classes.value}>{details.shift_hours}</div>
            </div>
            <div className={classes.internal_wrapper}>
              <div className={classes.title}>Salary Cycle</div>
              <div className={classes.value}>
                {details.salary_cycle} to {details.salary_cycle} every month
              </div>
            </div>
          </div>
        </div>
        <ButtonError
          contained
          width="100%"
          large
          margin="0 0 10px 0"
          onClick={handleOpenDeleteModal}
        >
          <img src={DeleteWhite} alt="Remove" />
          Permanently remove this employee
        </ButtonError>
      </div>
      <EditPersonalDetailsModal
        personalDetailsModal={personalDetailsModal}
        handleOpenPersonalDetailsModal={handleOpenPersonalDetailsModal}
        handleClosePersonalDetailsModal={handleClosePersonalDetailsModal}
        handleChange={handleChange}
        handleBlur={handleBlur}
        handleSwitch={handleSwitch}
        name={name}
        phone={phone}
        email={email}
        status={status}
        error={error}
        success={success}
        details={details}
      />
      <DeleteEmployeeModal
        details={details}
        deleteEmployeeModalStatus={deleteEmployeeModalStatus}
        handleCloseDeleteModal={handleCloseDeleteModal}
        handleOpenDeleteModal={handleOpenDeleteModal}
      />
    </Boilerplate>
  );
};

export default PersonalEdit;
