import React from "react";
import { useLocation } from "react-router";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import EmployeeTopPanel from "../../compnents/EmployeeTopPanel/EmployeeTopPanel";
import classes from "./PersonalOvertime.module.css";
import LeftArrow from "../../assets/icons/Left_blue.svg";
import RightArrow from "../../assets/icons/Right_blue.svg";
import Switch from "../../compnents/switch/Switch";
import Button from "../../compnents/buttons/Button";
import Overtime from "../../assets/icons/Overtime_white.svg";
import { useDispatch, useSelector } from "react-redux";
import loader from "../../compnents/animations/finding.json";
import EditBlue from "../../assets/icons/edit_blue.svg";
import {
  getMonthlyAttendance,
  getMonthlyAttendanceFailure,
  getMonthlyAttendanceSuccess,
} from "../../actions/getMonthlyAttendence";
import axios from "axios";
import Lottie from "react-lottie";
import moment from "moment";

const PersonalOvertime = () => {
  const location = useLocation();
  const details = location.state;
  const dispatch = useDispatch();
  const employee_data = useSelector((state) => state.monthlyAttendance);
  const [time, setTime] = React.useState(
    moment(new Date()).format("MMMM, YYYY")
  );

  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_monthly_attendance`,
      method: "POST",
      data: {
        staff_id: details.id,
        month: moment(time).format("MM"),
        year: moment(time).format("YYYY"),
      },
    };
    dispatch(getMonthlyAttendance());
    axios(settings)
      .then((res) => {
        if (res.data.result == true) {
          dispatch(getMonthlyAttendanceSuccess(res.data));
        } else {
          dispatch(getMonthlyAttendanceFailure());
        }
      })
      .catch((err) => {
        dispatch(getMonthlyAttendanceFailure());
      });
  }, [time]);
  let overtimedata;
  if (employee_data.loading === true) {
    overtimedata = (
      <div
        style={{
          // position: "absolute",
          // width: "100vw",
          // height: "100vh",
          // backgroundColor: "rgba(0,0,0,0.1)",
          margin: "20px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
          height={200}
          width={200}
          style={{ padding: "0", marginTop: "-50px" }}
        />
      </div>
    );
  } else if (employee_data.response === "") {
    overtimedata = <div>No Employees</div>;
  } else if (employee_data.response.result === false) {
    overtimedata = <div>Something went wrong !! Refresh the page</div>;
  } else if (employee_data.response.result === true) {
    overtimedata = employee_data.response.staff_data.map((entry) => {
      return (
        <div className={classes.entry_wrapper}>
          <div className={classes.entry_date}>{entry.date_full}</div>
          <div className={classes.charges}>&#8377; {entry.overtime_charge}</div>
          <div className={classes.hours}>
            08:00 +{" "}
            <span style={{ color: "#157EF8" }}>{entry.overtime} Hrs</span>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button width="60%">
              <img src={EditBlue} alt="Edit" />
              Edit
            </Button>
          </div>
        </div>
      );
    });
  }
  const handlePreviousMonth = () => {
    let month = time;
    let previousMonth = moment(time).subtract(1, "months").calendar();
    previousMonth = moment(previousMonth).format("MMM, YYYY");
    setTime(previousMonth);
  };
  const handleNextMonth = () => {
    let currentMonth = moment().format("M");
    let nextMonth = moment(time).add(1, "months").calendar();
    nextMonth = moment(nextMonth).format("MMM, YYYY");
    if (currentMonth >= moment(nextMonth).format("M")) {
      setTime(nextMonth);
    }
  };
  return (
    <Boilerplate>
      <EmployeeTopPanel details={details} />
      <div className={classes.date_wrapper}>
        <div className={classes.date}>
          <img
            src={LeftArrow}
            alt="Previous"
            onClick={handlePreviousMonth}
            style={{ cursor: "pointer", zIndex: "2" }}
          />
          <div>{time}</div>
          <img
            src={RightArrow}
            alt="Next"
            onClick={handleNextMonth}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Button contained large>
          <img src={Overtime} alt="Alarm" />
          <div>Add Overtime</div>
        </Button>
      </div>
      <div className={classes.details_wrapper}>
        {overtimedata}

        <div></div>
      </div>
    </Boilerplate>
  );
};

export default PersonalOvertime;
