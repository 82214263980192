import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  error: false,
  response: "",
};

const getprofileSlice = createSlice({
  name: "getprofile",
  initialState,
  reducers: {
    getprofile: (state) => {
      state.loading = true;
    },
    getprofileSuccess: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = false;
    },
    getprofileFailure: (state) => {
      state.loading = false;
      state.error = "Something went wrong";
      state.response = "";
    },
  },
});
export default getprofileSlice.reducer;

export const { getprofile, getprofileSuccess, getprofileFailure } =
  getprofileSlice.actions;

export const getprofileSelector = (state) => state.loading;

// export const fetchgetstaff = (owner_id, salary_type) => async (dispatch) => {
//   var settings = {
//     url: `http://salarybook.co.in/public/api/get_staff`,
//     method: "POST",
//     data: {
//       owner_id: owner_id,
//       salary_type: salary_type,
//     },
//   };
//   dispatch(getstaff());
//   axios(settings).then((res) => {
//     if (res.data.owner == "yes") {
//       dispatch(getstaffSuccess(res.data));
//     } else {
//       dispatch(getstaffFailure());
//     }
//   });
// };
