import classes from "./JobApplicants.module.css";
import React from "react";
import { useHistory, useLocation } from "react-router";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import moment from "moment";
import Button from "../../compnents/buttons/Button";
import UserDetails from "../../assets/icons/UserDetails.svg";
import Calendarplus from "../../assets/icons/Calendar_plus.svg";
import Calendarminus from "../../assets/icons/Calendar_minus.svg";
import Search from "../../assets/icons/search_grey.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const JobApplicants = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [applicants, setApplicants] = React.useState("");
  const [search, setSearch] = React.useState("");

  const businessid = useSelector((state) => state.businessid.value);

  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const details = location.state.details.filter(
    (job) => job.id === location.state.jobid
  );
  const handlejob = (jobid, jobstatus) => {
    if (jobstatus === "Active") {
      var settings = {
        url: `${process.env.REACT_APP_API_URL}/update_job`,
        method: "POST",
        data: {
          id: jobid,
          status: "Inactive",
        },
      };
    } else if (jobstatus === "Inactive") {
      var settings = {
        url: `${process.env.REACT_APP_API_URL}/update_job`,
        method: "POST",
        data: {
          id: jobid,
          status: "Active",
        },
      };
    }
    axios(settings)
      .then((res) => {
        alert(res.data.message);
        window.location.reload();
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };
  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/owner_get_apply_job`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? Cookies.get("owner_id") : businessid,
        job_id: location.state.jobid,
      },
    };
    axios(settings).then((res) => {
      if (res.data.result === true) {
        setApplicants(res.data.data);
      }
    });
  }, []);
  let applicants_list;
  if (applicants.length === 0) {
    applicants_list = <div>No Applicants</div>;
  } else {
    applicants_list = applicants
      .filter(
        (f) => f.job_profile.toLowerCase().includes(search) || search === ""
      )
      .map((job) => {
        return (
          <div className={classes.joblist_row}>
            <div className={classes.column1}>
              <div className={classes.top}>Deexit Patel</div>
              <div className={classes.bottom}>Mankhurd | Mumbai</div>
            </div>
            <div className={classes.column1}>
              <div className={classes.top}>12th Pass</div>
              <div className={classes.bottom}>Education</div>
            </div>
            <div className={classes.column1}>
              <div className={classes.top}>06/05/2021</div>
              <div className={classes.bottom}>Applied on</div>
            </div>
            <div className={classes.column1}>
              <div className={classes.top}>+91-9999999999</div>
              <div className={classes.bottom}>Contact no.</div>
            </div>
          </div>
        );
      });
  }
  const handleEditjob = () => {
    history.push({
      pathname: `/${details[0].id}/editjob`,
      state: { details: details },
    });
  };
  return (
    <Boilerplate>
      <div className={classes.toppanel}>
        <div className={classes.top_row}>
          <div className={classes.column1}>
            <div className={classes.top}>{details[0].job_profile}</div>
            <div className={classes.bottom}>
              &#8377; {details[0].salary_from} - &#8377; {details[0].salary_to}
            </div>
          </div>
          <div className={classes.column2}>
            <div className={classes.top}>
              {moment(details[0].created_at).format("DD/MM/YYYY")}
            </div>
            <div className={classes.bottom}>Posted on</div>
          </div>
          <div className={classes.column3}>
            <div
              className={classes.top_row}
              style={{
                color: details[0].status === "Active" ? "#27AE60" : "#FF605C",
                fontWeight: "500",
              }}
            >
              {details[0].status}
            </div>
            <div className={classes.bottom}>Status</div>
          </div>
          <div className={classes.column4}>
            <Button
              large
              onClick={() => handlejob(details[0].id, details[0].status)}
            >
              {details[0].status === "Active"
                ? "Deactivate Job"
                : "Reactivate Job"}
            </Button>
            <Button large onClick={handleEditjob}>
              Edit Details
            </Button>
          </div>
        </div>
        <hr className={classes.firstline} />
        <div className={classes.middle_row}>
          <img src={UserDetails} alt="Applicants" />
          <span className={classes.content}>
            {applicants.length} Applicants
          </span>
          <span className={classes.content}>&#183;</span>
          <img src={Calendarplus} alt="Post" />
          <span className={classes.content}>
            Posted on: {moment(details[0].created_at).format("DD-MM-YYYY")}
          </span>
          <span className={classes.content}>&#183;</span>
          {/* <img src={Calendarminus} alt="Post" /> */}
        </div>

        <hr className={classes.secondline} />
        <div
          className={classes.top_row}
          style={{ margin: "2vh 0", flexWrap: "wrap" }}
        >
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].experience}</div>
            <div className={classes.bottom}>Experience</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].qualification}</div>
            <div className={classes.bottom}>Education</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].english_level}</div>
            <div className={classes.bottom}>English Level</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].gender}</div>
            <div className={classes.bottom}>Gender</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>
              {details[0].start_work_day} to {details[0].end_work_day}
            </div>
            <div className={classes.bottom}>working days</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>
              {details[0].start_work_time} to {details[0].end_work_time}
            </div>
            <div className={classes.bottom}>Timing</div>
          </div>
        </div>
        <div
          className={classes.top_row}
          style={{ margin: "2vh 0", flexWrap: "wrap" }}
        >
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].description}</div>
            <div className={classes.bottom}>Job Description</div>
          </div>
        </div>
        <div
          className={classes.top_row}
          style={{ margin: "2vh 0", flexWrap: "wrap" }}
        >
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].address}</div>
            <div className={classes.bottom}>Address</div>
          </div>
        </div>
      </div>
      <div className={classes.searchbar_wrapper}>
        <img src={Search} alt="Search" />
        <input
          type="text"
          className={classes.searchbar}
          value={search}
          onChange={(e) => handleChange(e)}
          placeholder="Search Job"
        />
      </div>
      <div className={classes.joblist_wrapper}>{applicants_list}</div>
    </Boilerplate>
  );
};

export default JobApplicants;
