import React from 'react'
import PostModal from '../PostModal'
import classes from "./PostJobModal.module.css"
import Cross from "../../../assets/icons/Cross.svg";
import InputField from '../../inputs/Input';
import Radio from '../../radio/Radio';
import Button from '../../buttons/Button';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Back from "../../../assets/icons/arrow-left.svg"
import { useHistory, useLocation } from 'react-router';

const EditSalaryDetails = (props) => {
    const businessid = useSelector((state) => state.businessid.value);
    const [minimumSalary, setMinimumSalary] = React.useState("");
    const [maximumSalary, setMaximumSalary] = React.useState("");
    const [position, setPosition] = React.useState("");
    const [workfromhome, setworkfromhome] = React.useState("No");
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const handleRadioWorkFromHome = (workfromhome) => {
        setworkfromhome(workfromhome)
    }
    const handleInput = (e, type) => {
        switch(type) {
            case "Minimum Salary":
                setMinimumSalary(e.target.value);
                break;
            case "Maximum Salary":
                setMaximumSalary(e.target.value);
                break;
            case "Number of Positions":
                setPosition(e.target.value);
                break;
        }
    }

    const handleValidation = (type) => {
        let phoneRegx = /^\d+$/;
        switch(type) {
            case "Minimum Salary":
                if (minimumSalary.length === 0 || phoneRegx.test(minimumSalary) == false) {
                    setError((prevState) => ({
                        ...prevState,
                        minimumSalary: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        minimumSalary: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        minimumSalary: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        minimumSalary: "",
                    }));
                }
                break;
            case "Maximum Salary":
                if (maximumSalary.length === 0 || phoneRegx.test(maximumSalary) == false) {
                    setError((prevState) => ({
                        ...prevState,
                        maximumSalary: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        maximumSalary: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        maximumSalary: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        maximumSalary: "",
                    }));
                }
                break;
            case "Number of Positions":
                if (position.length < 1 || position.startsWith("0") === true || phoneRegx.test(position) === false) {
                    setError((prevState) => ({
                        ...prevState,
                        position: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        position: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        position: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        position: "",
                    }));
                }
                break;
        }
    }
    const handleEdit = () => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/update_job`,
            method: "POST",
            data: {
                id: props.id,
                salary_from: minimumSalary,
                salary_to: maximumSalary,
                no_of_openings: position,
                work_from_home: workfromhome,
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === true) {
                alert(res.data.message);
                window.location.reload();
            } else {
                alert("Something went wrong !!")
            }
            }).catch(() => {
                alert("Something went wrong !! Try again after sometimes")
        })
    }
    const handleCancel = () => {
        props.handleCloseModal()
    }
    return (
        <PostModal modalStatus={props.jobModalStatus} onRequestClose={props.handleCloseModal}>
            <div style={{height: "90%", boxSizing: "border-box"}}>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Post a new job</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleCloseModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>Salary and Postions available</div>
            <div className={classes.details_wrapper}>
                <InputField
                    type="text"
                    id="minimum salary"
                    label="Minimum Salary"
                    placeholder="Minimum Salary"
                    value={minimumSalary}
                    onChange={(e) => handleInput(e, "Minimum Salary")}
                    onBlur={() => handleValidation("Minimum Salary")}
                    error={error.minimumSalary}
                    success={success.minimumSalary}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                
                <InputField
                    type="text"
                    id="maximum salary"
                    label="Maximum Salary"
                    placeholder="Maximum Salary"
                    value={maximumSalary}
                    onChange={(e) => handleInput(e, "Maximum Salary")}
                    onBlur={() => handleValidation("Maximum Salary")}
                    error={error.maximumSalary}
                    success={success.maximumSalary}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                
                <div>
                <InputField
                    type="text"
                    id="Number of Positions"
                    label="Number of Positions"
                    placeholder="Number of Positions"
                    value={position}
                    onChange={(e) => handleInput(e, "Number of Positions")}
                    onBlur={() => handleValidation("Number of Positions")}
                    error={error.position}
                    success={success.position}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                </div>
                <div className={classes.radiohead}>Is work from home available ?</div>
                <div className={classes.row} id="workfromhome">
                    <Radio
                        name="workfromhome"
                        description="Yes"
                        value="Yes"
                        checked={workfromhome === "Yes" ? true : false}
                        onChange={() => handleRadioWorkFromHome("Yes")}
                    />
                    <Radio
                        name="workfromhome"
                        description="No"
                        value="No"
                        checked={workfromhome === "No" ? true : false}
                        onChange={() => handleRadioWorkFromHome("No")}
                    />
                </div>
            </div>
            </div>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <Button large width="45%" onClick={handleCancel}>Cancel</Button>
                <Button large contained width="45%" onClick={handleEdit}>Edit Details</Button>
            </div>
        </PostModal>
    )
}

export default EditSalaryDetails
