import React from 'react'
import classes from "./EmployeeTopPanel.module.css";
import EditBlue from "../../assets/icons/edit_blue.svg";
import Summary from "../../assets/icons/User_Details.svg";
import SummaryBlue from "../../assets/icons/User_Details_blue.svg";
import Presence from "../../assets/icons/Presence.svg";
import PresenceBlue from "../../assets/icons/Presence_blue.svg";
import Payments from "../../assets/icons/payments.svg";
import PaymentsBlue from "../../assets/icons/payments_blue.svg";
import Payslip from "../../assets/icons/Payment_Slip-Alt.svg";
import Overtime from "../../assets/icons/Overtime_alt.svg";
import OvertimeBlue from "../../assets/icons/Overtime_alt_blue.svg";
import Allowance from "../../assets/icons/allowance.svg";
import AllowanceBlue from "../../assets/icons/allowance_blue.svg";
import Bonus from "../../assets/icons/bonus.svg";
import BonusBlue from "../../assets/icons/bonus_blue.svg";
import Deductions from "../../assets/icons/deduction.svg";
import DeductionsBlue from "../../assets/icons/deduction_blue.svg";
import Loans from "../../assets/icons/loans.svg";
import LoansBlue from "../../assets/icons/loans_blue.svg";
import Button from "../../compnents/buttons/Button";
import { Link, useHistory, useLocation } from "react-router-dom";
import Back from "../../assets/icons/arrow-left.svg";
import PaymentReportModal from '../modal/PaymentReportModal/PaymentReportModal';

const EmployeeTopPanel = (props) => {
    const location = useLocation().pathname;
    const [payslipModalStatus, setPayslipModalStatus] = React.useState(false);
    const [radio, setRadio] = React.useState("");
    const handleRadio = (val) => {
        setRadio(val);
    };
    const handleOpenPayslipModal = () => {
        setPayslipModalStatus(true);
    }
    const handleClosePayslipModal = () => {
        setPayslipModalStatus(false);
    }
    const history = useHistory();
    
    const {details} = props;

    const handleSaveDetails = () => {
        console.log("Save");
    }
    console.log(details);
    return (
        <>
            <div className={classes.topbar}>
                <div className={classes.top_row}>
                    <Link to="/employees">
                    <img src={Back} alt="Back" />
                    </Link>
                    <div className={classes.column}>
                    <div className={classes.top}>{details.employee_name}</div>
                    <div className={classes.bottom}>+91{details.employee_mobile}</div>
                    </div>
                    <div className={classes.column}>
                    <div className={classes.top}>{details.salary_type}</div>
                    <div className={classes.bottom}>Employee Type</div>
                    </div>
                    <div className={classes.column}>
                        <div className={classes.top}>&#8377; 200200</div>
                        <div className={classes.bottom}>{details.salary_type} Salary</div>
                    </div>
                    <div className={classes.column}>
                        <div className={classes.top}>&#8377; {details.amount}</div>
                        <div className={classes.bottom}>Current Balance</div>
                    </div>
                    <Link to={{
                        pathname: `/${details.id}/edit`,
                        state: details
                    }} className={classes.column} style={{ textDecoration: "none" }}>
                    {
                        
                        location === `${details.id}/edit` ? (
                            <Button style={{ padding: "0 10px" }} onClick={handleSaveDetails}>
                            <img src={EditBlue} alt="Edit" />
                            Save Details
                        </Button>
                            ) : (
                                <Button style={{ padding: "0 10px" }}>
                                <img src={EditBlue} alt="Edit" />
                                Edit Details
                            </Button>
                            )
                    }
                    
                    </Link>
                </div>
                <div className={classes.actions}>
                    <Link to={{
                        pathname: `/${details.id}/summary`,
                        state: details,
                    }} className={classes.link} style={{boxShadow: location === `/${details.id}/summary` ? "0 0 12px 2px rgba(0,0,0,0.06)" : "0 0 2px 1px rgba(0,0,0,0.06)"}}>
                    <div className={classes.box}>
                        <img src={location === `/${details.id}/summary` ? SummaryBlue : Summary} alt="icon" />
                        <div className={classes.boxInfo} style={{color: location === `/${details.id}/summary` ? "#157EF8" : "#202020"}}>Summary</div>
                    </div>
                    </Link>
                    <Link
                    to={{
                        pathname: `/${details.id}/presence`,
                        state: details,
                    }}
                    className={classes.link}
                    style={{boxShadow: location === `/${details.id}/presence` ? "0 0 12px 2px rgba(0,0,0,0.06)" : "0 0 2px 1px rgba(0,0,0,0.06)"}}
                    >
                    <div className={classes.box}>
                        <img src={location === `/${details.id}/presence` ? PresenceBlue : Presence} alt="icon" />
                        <div className={classes.boxInfo} style={{color: location === `/${details.id}/presence` ? "#157EF8" : "#202020"}}>Presence</div>
                    </div>
                    </Link>
                    <Link to={{
                        pathname: `/${details.id}/payments`,
                        state: details,
                    }} className={classes.link} style={{boxShadow: location === `/${details.id}/payments` ? "0 0 12px 2px rgba(0,0,0,0.06)" : "0 0 2px 1px rgba(0,0,0,0.06)"}}>
                    <div className={classes.box}>
                        <img src={location === `/${details.id}/payments` ? PaymentsBlue : Payments} alt="icon" />
                        <div className={classes.boxInfo} style={{color: location === `/${details.id}/payments` ? "#157EF8" : "#202020"}}>Payments</div>
                    </div>
                    </Link>
                    {/* <Link to="/presence" className={classes.link}> */}
                    <div className={classes.box} style={{cursor: "pointer"}} onClick={handleOpenPayslipModal}>
                        <img src={Payslip} alt="icon" />
                        <div className={classes.boxInfo}>Pay Slip</div>
                    </div>
                    {/* </Link> */}
                    <Link  to={{
                        pathname: `/${details.id}/overtime`,
                        state: details,
                    }} className={classes.link} style={{boxShadow: location === `/${details.id}/overtime` ? "0 0 12px 2px rgba(0,0,0,0.06)" : "0 0 2px 1px rgba(0,0,0,0.06)"}}>
                    <div className={classes.box}>
                        <img src={location === `/${details.id}/overtime` ? OvertimeBlue : Overtime} alt="icon" />
                        <div className={classes.boxInfo} style={{color: location === `/${details.id}/overtime` ? "#157EF8" : "#202020"}}>Overtime</div>
                    </div>
                    </Link>
                    <Link to={{
                        pathname: `/${details.id}/allowance`,
                        state: details,
                    }} className={classes.link} style={{boxShadow: location === `/${details.id}/allowance` ? "0 0 12px 2px rgba(0,0,0,0.06)" : "0 0 2px 1px rgba(0,0,0,0.06)"}}>
                    <div className={classes.box}>
                        <img src={location === `/${details.id}/allowance` ? AllowanceBlue : Allowance} alt="icon" />
                        <div className={classes.boxInfo} style={{color: location === `/${details.id}/allowance` ? "#157EF8" : "#202020"}}>Allowance</div>
                    </div>
                    </Link>
                    <Link to={{
                        pathname: `/${details.id}/bonus`,
                        state: details,
                    }} className={classes.link} style={{boxShadow: location === `/${details.id}/bonus` ? "0 0 12px 2px rgba(0,0,0,0.06)" : "0 0 2px 1px rgba(0,0,0,0.06)"}}>
                    <div className={classes.box}>
                        <img src={location === `/${details.id}/bonus` ? BonusBlue : Bonus} alt="icon" />
                        <div className={classes.boxInfo} style={{color: location === `/${details.id}/bonus` ? "#157EF8" : "#202020"}}>Bonus</div>
                    </div>
                    </Link>
                    <Link to={{
                        pathname: `/${details.id}/deductions`,
                        state: details,
                    }} className={classes.link} style={{boxShadow: location === `/${details.id}/deductions` ? "0 0 12px 2px rgba(0,0,0,0.06)" : "0 0 2px 1px rgba(0,0,0,0.06)"}}>
                    <div className={classes.box}>
                        <img src={location === `/${details.id}/deductions` ? DeductionsBlue : Deductions} alt="icon" />
                        <div className={classes.boxInfo} style={{color: location === `/${details.id}/deductions` ? "#157EF8" : "#202020"}}>Deductions</div>
                    </div>
                    </Link>
                    <Link to={{
                        pathname: `/${details.id}/loans`,
                        state: details,
                    }} className={classes.link} style={{boxShadow: location === `/${details.id}/loans` ? "0 0 12px 2px rgba(0,0,0,0.06)" : "0 0 2px 1px rgba(0,0,0,0.06)"}}>
                    <div className={classes.box}>
                        <img src={location === `/${details.id}/loans` ? LoansBlue : Loans} alt="icon" />
                        <div className={classes.boxInfo} style={{color: location === `/${details.id}/loans` ? "#157EF8" : "#202020"}}>Loans</div>
                    </div>
                    </Link>
                </div>
            </div>  
            <PaymentReportModal radio={radio} handleRadio={handleRadio} details={details} payslipModalStatus={payslipModalStatus} handleOpenPayslipModal={handleOpenPayslipModal} handleClosePayslipModal={handleClosePayslipModal}/>
        </>
    )
}

export default EmployeeTopPanel
