import React from "react";
import Button from "../../buttons/Button";
import Modal from "../Modal";
import classes from "./AddAdminModal.module.css";
import Cross from "../../../assets/icons/Cross.svg";
import InputField from "../../inputs/Input";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import axios from "axios";

const AddAdminModal = (props) => {
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const businessid = useSelector((state) => state.businessid.value);

  const handleChange = (e, type) => {
    switch (type) {
      case "name":
        setName(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
    }
  };
  const handleValidation = (type) => {
    let phoneRegx = /^\d[0-9]/;
    switch (type) {
      case "name":
        if (name.length <= 3) {
          setError((prevState) => ({
            ...prevState,
            name: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            name: "",
          }));
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            name: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            name: "",
          }));
        }
        break;

      case "phone":
        if (phone.length !== 10 || phoneRegx.test(phone) == false) {
          setError((prevState) => ({
            ...prevState,
            phone: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            phone: "",
          }));
          break;
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            phone: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            phone: "",
          }));
          break;
        }
    }
  };
  const handleAddAdmin = () => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/add_owner_admin`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? Cookies.get("owner_id") : businessid,
        name: name,
        mobile: phone,
      },
    };
    if (error.name === "" && error.phone === "") {
      axios(settings)
        .then((res) => {
          if (res.data.result === true) {
            alert(res.data.message);
            window.location.reload();
          } else {
            console.log(res.data);
            alert("Something went wrong !!");
          }
        })
        .catch((err) => {
          alert("Something went wrong");
        });
    }
  };
  return (
    <Modal
      modalStatus={props.adminModalStatus}
      onRequestClose={props.handleCloseAdminModal}
    >
      <div>
        <div className={classes.heading_wrapper}>
          <div className={classes.modal_heading}>Add Admin</div>
          <img
            src={Cross}
            alt="Close"
            onClick={props.handleCloseAdminModal}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className={classes.secondary_heading}>
          For Managing Salarybook App
        </div>
        <div width="100%" style={{ margin: "20px 0" }}>
          <InputField
            type="text"
            id="name"
            value={name}
            label="Name"
            placeholder="Name"
            onChange={(e) => handleChange(e, "name")}
            onBlur={() => handleValidation("name")}
            width="100%"
            error={error.name}
            success={success.name}
          />
        </div>
        <div width="100%" style={{ margin: "20px 0" }}>
          <InputField
            type="text"
            id="phone"
            value={phone}
            label="Phone"
            placeholder="Phone"
            onChange={(e) => handleChange(e, "phone")}
            onBlur={() => handleValidation("phone")}
            width="100%"
            error={error.phone}
            success={success.phone}
          />
        </div>
      </div>
      <Button contained large onClick={handleAddAdmin}>
        Add Admin
      </Button>
    </Modal>
  );
};

export default AddAdminModal;
