import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "../actions/login";
import verifyloginReducer from "../actions/verifylogin";
import updateprofileReducer from "../actions/update_profile";
import getStaffReducer from "../actions/getStaff";
import getAllStaffReducer from "../actions/getAllStaff";
import getMonthlyAttendanceReducer from "../actions/getMonthlyAttendence";
import getprofileReducer from "../actions/getProfile";
import getstaffPaymentReducer from "../actions/getStaffPayment";
import getstaffmonthlybalanceReducer from "../actions/getstaffmonthlybalance";
import getstaffallowanceReducer from "../actions/getstaffallowance";
import getstaffBonusReducer from "../actions/getstaffBonus";
import getstaffdeductionReducer from "../actions/getstaffdeductions";
import getstaffloanReducer from "../actions/getstaffloan";
import businessIdReducer from "../actions/businessId";
import getjoblistReducer from "../actions/getjobslist";

export const store = configureStore({
  reducer: {
    loginResponse: loginReducer,
    verifyResponse: verifyloginReducer,
    updateprofileresponse: updateprofileReducer,
    staffResponse: getStaffReducer,
    allstaffResponse: getAllStaffReducer,
    monthlyAttendance: getMonthlyAttendanceReducer,
    profile: getprofileReducer,
    staffPayment: getstaffPaymentReducer,
    staffMonthlyBalance: getstaffmonthlybalanceReducer,
    staffAllowance: getstaffallowanceReducer,
    staffBonus: getstaffBonusReducer,
    staffDeduction: getstaffdeductionReducer,
    staffLoan: getstaffloanReducer,
    businessid: businessIdReducer,
    joblist: getjoblistReducer,
  },
});
