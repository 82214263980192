import React from "react";
import classes from "./TextField.module.css";
// import Clear from "../../../assets/icons/clear.svg";
// import View from "../../../assets/icons/view.svg";

export default function TextField(props) {
  let style;
  const errorStyle = {
    margin: props.margin,
    border: "2px solid #FF605C",
    width: props.width ? props.width : "250px",
  };
  const disableStyle = {
    margin: props.margin,
    border: "2px solid #009E55",
    width: props.width ? props.width : "250px",
  };
  if (props.error) {
    style = errorStyle;
  } else if (props.disabled) {
    style = disableStyle;
  } else {
    style = {
      width: props.width ? props.width : "250px",
      margin: props.margin,
    };
  }

  return (
    <>
      <div className={classes.floating_wrapper} style={style}>
        <div className={classes.floating}>
          <input
            id="input__username"
            className={classes.floating__input}
            name={props.placeholder}
            type={props.type}
            placeholder={props.placeholder}
            value={props.value}
            disabled={props.disabled}
            onChange={props.onChange}
            onBlur={props.onBlur}
            autoComplete="new-password"
          />
          <label
            for="input__username"
            className={classes.floating__label}
            data-content={props.placeholder}
            style={{
              color: props.error ? "#FF4855" : null,
              color: props.disabled ? "#C1C1C1" : null,
            }}
          >
            <span className={classes.hidden__visually}>
              {props.placeholder}
            </span>
          </label>
        </div>
        {/* {sideImage} */}
      </div>
      {props.error && <div className={classes.error}>{props.error}</div>}
    </>
  );
}
