import classes from "./Settings.module.css";
import React from "react";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import Back from "../../assets/icons/arrow-left.svg";
import Share from "../../assets/icons/Share_blue.svg";
import Check from "../../assets/icons/check_blue.svg";
import Cookies from "js-cookie";
import axios from "axios";
import Button from "../../compnents/buttons/Button";
import ButtonError from "../../compnents/buttons/ButtonError";
import Edit from "../../assets/icons/edit_blue.svg";
import Pin from "../../assets/icons/Userpin_white.svg";
import Logout from "../../assets/icons/logout_white.svg";
import Delete from "../../assets/icons/Delete_white.svg";
import Add from "../../assets/icons/plus-circle_blue.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getprofile,
  getprofileFailure,
  getprofileSuccess,
} from "../../actions/getProfile";
import { Link, useHistory } from "react-router-dom";
import AddAdminModal from "../../compnents/modal/AddAdmin/AddAdminModal";
import AddManagerModal from "../../compnents/modal/AddManager/AddManagerModal";

const Settings = (props) => {
  const [ownerid, setOwnerid] = React.useState(Cookies.get("owner_id"));
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const [adminModalStatus, setAdminModalStatus] = React.useState(false);
  const [managerModalStatus, setManagerModalStatus] = React.useState(false);
  const businessid = useSelector((state) => state.businessid.value);
  const [adminList, setAdminList] = React.useState([]);
  const [managerList, setManagerList] = React.useState([]);

  const handleOpenAdminModal = () => {
    setAdminModalStatus(true);
  };
  const handleCloseAdminModal = () => {
    setAdminModalStatus(false);
  };
  const handleOpenManagerModal = () => {
    setManagerModalStatus(true);
  };
  const handleCloseManagerModal = () => {
    setManagerModalStatus(false);
  };

  let data;
  const dispatch = useDispatch();

  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_profile`,
      method: "POST",
      data: {
        id: Cookies.get("owner_id"),
        owner_id: Cookies.get("owner_id"),
      },
    };
    dispatch(getprofile());
    axios(settings)
      .then((res) => {
        if (res.data.result === true) {
          dispatch(getprofileSuccess(res.data.data));
        } else {
          dispatch(getprofileFailure());
        }
      })
      .catch((err) => {
        dispatch(getprofileFailure());
      });

    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_owner_admin`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? Cookies.get("owner_id") : businessid,
      },
    };
    axios(settings)
      .then((res) => {
        if (res.data.result === true) {
          setAdminList(res.data.data);
        } else {
          if (res.data.message !== "data not found") {
            alert("Something went wrong while fetching admin list");
          }
        }
      })
      .catch((err) => {
        alert("Something went wrong....please try later");
      });

    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_staff_manager`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? Cookies.get("owner_id") : businessid,
      },
    };
    axios(settings)
      .then((res) => {
        if (res.data.result === true) {
          setManagerList(res.data.staff_data);
        } else {
          alert("Something went wrong while fetching manager list");
        }
      })
      .catch((err) => {
        alert("Something went wrong....please try later");
      });
  }, []);

  const handleLogout = () => {
    Cookies.remove("owner_id");
    // history.push("/");
    window.location.replace("/");
  };

  const handleDeleteAdmin = (id) => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/delete_owner_admin`,
      method: "POST",
      data: {
        id: id,
      },
    };
    axios(settings)
      .then((res) => {
        if (res.data.result === true) {
          alert(res.data.message);
          window.location.reload();
        } else {
          alert("Something went wrong while deleting admin list");
        }
      })
      .catch((err) => {
        alert("Something went wrong....please try later");
      });
  };
  const handleRemoveManager = (id) => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/edit_staff`,
      method: "POST",
      data: {
        id: id,
        type: "Staff",
      },
    };
    axios(settings)
      .then((res) => {
        if (res.data.result === true) {
          alert(res.data.message);
          window.location.reload();
        } else {
          alert("Something went wrong while removing manager!!");
        }
      })
      .catch((err) => {
        alert("Something went wrong !! Try again after sometimes");
      });
  };

  let adminListUI;

  if (adminList.length === 0) {
    adminListUI = (
      <div
        className={classes.field_row}
        style={{ width: "100%", margin: "20px 0", boxSizing: "border-box" }}
      >
        No Admins Found
      </div>
    );
  } else {
    adminListUI = adminList.map((admin) => {
      return (
        <div
          className={classes.field_row}
          style={{ width: "100%", margin: "20px 0", boxSizing: "border-box" }}
        >
          <div>{admin.name}</div>
          <div>Admin</div>
          <ButtonError contained onClick={() => handleDeleteAdmin(admin.id)}>
            <img src={Delete} alt="Delete" />
            Delete Admin
          </ButtonError>
        </div>
      );
    });
  }

  let managerListUI;
  if (managerList.length === 0) {
    managerListUI = (
      <div
        className={classes.field_row}
        style={{ width: "100%", margin: "20px 0", boxSizing: "border-box" }}
      >
        No Managers Found
      </div>
    );
  } else {
    managerListUI = managerList.map((manager) => {
      return (
        <div
          className={classes.field_row}
          style={{ width: "100%", margin: "20px 0", boxSizing: "border-box" }}
        >
          <div>{manager.employee_name}</div>
          <div>Manager</div>
          <ButtonError
            contained
            onClick={() => handleRemoveManager(manager.id)}
          >
            <img src={Delete} alt="Delete" />
            Delete Manger
          </ButtonError>
        </div>
      );
    });
  }
  return (
    <Boilerplate>
      <div className={classes.top_panel}>
        <div className={classes.elementbox}>
          <Link to="/employees">
            <img src={Back} alt="back" />
          </Link>
          <div>&nbsp;</div>
        </div>
        <div className={classes.elementbox}>
          <div className={classes.top_content}>
            {profile.response.business_name}
          </div>
          <div className={classes.bottom_content}>
            STATUS:{"   "}
            <span
              style={{
                color: "#157EF8",
                textTransform: "uppercase",
                letterSpacing: "1px",
              }}
            >
              {profile.response.status}
            </span>
          </div>
        </div>
        <div className={classes.elementbox}>
          <div className={classes.top_content}>30 days every month</div>
          <div className={classes.bottom_content}>Month Calculationt</div>
        </div>
        <div className={classes.elementbox}>
          <div className={classes.top_content}>{profile.response.mobile}</div>
          <div className={classes.bottom_content}>Primary Contact</div>
        </div>
        <div className={classes.icon_row}>
          <div className={classes.iconbox}>
            <img src={Share} alt="Share" />
          </div>
          <Button large>
            <img src={Check} alt="Save Details" />
            Save Details
          </Button>
        </div>
      </div>
      <div className={classes.content_scroll_wrapper}>
        <div className={classes.content_wrapper}>
          <div className={classes.heading_row}>
            <div className={classes.heading}>Business Preferences</div>
            <Button>
              <img src={Edit} alt="Edit" />
              Edit Business Preferences
            </Button>
          </div>
          <div className={classes.fields_row}>
            <div className={classes.field_row}>
              <div>Language</div>
              <div style={{ color: "#707070" }}>English</div>
            </div>
            <div className={classes.field_row}>
              <div>Primary Contact</div>
              <div style={{ color: "#707070" }}>{profile.response.mobile}</div>
            </div>
          </div>
          <div className={classes.fields_row}>
            <div className={classes.field_row}>
              <div>Security Password</div>
              <div style={{ color: "#707070", fontWeight: "600" }}>
                . . . . . . . . . . . . . . . . . . . .
              </div>
            </div>
            <div className={classes.field_row}>
              <div>Weekly Holidays</div>
              <div style={{ color: "#707070" }}>Saturday, Sunday</div>
            </div>
          </div>
          <div className={classes.fields_row}>
            <div className={classes.field_row}>
              <div>Auto Attendance</div>
              <div style={{ color: "#707070" }}>Mark Present by default</div>
            </div>
            <div className={classes.field_row}>
              <div>Business Status</div>
              <div style={{ color: "#707070" }}>{profile.response.status}</div>
            </div>
          </div>
          <div className={classes.fields_row}>
            <div className={classes.field_row}>
              <div>Daily SMS Report</div>
              <div style={{ color: "#707070" }}>Active</div>
            </div>
            <div className={classes.field_row}>
              <div>Whatsapp Notifications</div>
              <div style={{ color: "#707070" }}>Active</div>
            </div>
          </div>
        </div>
        <div className={classes.content_wrapper}>
          <div className={classes.heading_row}>
            <div className={classes.heading}>Manage Admins</div>

            <Button onClick={handleOpenAdminModal}>
              <img src={Add} alt="Add" />
              Add New Admin
            </Button>
          </div>
          {adminListUI}
        </div>
        <div className={classes.content_wrapper}>
          <div className={classes.heading_row}>
            <div className={classes.heading}>Manage Managers</div>
            <Button onClick={handleOpenManagerModal}>
              <img src={Add} alt="Add" />
              Add New Manager
            </Button>
          </div>
          {managerListUI}
        </div>
        <div className={classes.content_wrapper}>
          <div className={classes.heading_row}>
            <div className={classes.heading}>Hire Staff</div>
            <Button>
              <img src={Add} alt="Add" />
              Create new job poster
            </Button>
          </div>
          <div
            className={classes.field_row}
            style={{ width: "100%", margin: "20px 0", boxSizing: "border-box" }}
          >
            <div>Job Posts</div>
            <Button contained>
              <img src={Pin} alt="View" />
              View
            </Button>
          </div>
        </div>
        <div className={classes.content_wrapper}>
          <div className={classes.heading_row}>
            <div className={classes.heading}>Others</div>
          </div>
          <div
            className={classes.field_row}
            style={{ width: "100%", margin: "20px 0", boxSizing: "border-box" }}
          >
            <div>Frequently Asked Questions</div>
            <Button contained>
              <img src={Pin} alt="View" />
              View FAQs
            </Button>
          </div>
        </div>
        <Button contained large width="100%" onClick={handleLogout}>
          <img src={Logout} alt="Logout" />
          Log out
        </Button>
      </div>
      <AddAdminModal
        adminModalStatus={adminModalStatus}
        handleCloseAdminModal={handleCloseAdminModal}
        handleOpenAdminModal={handleOpenAdminModal}
      />
      <AddManagerModal
        managerModalStatus={managerModalStatus}
        handleCloseManagerModal={handleCloseManagerModal}
        handleOpenManagerModal={handleOpenManagerModal}
      />
    </Boilerplate>
  );
};

export default Settings;
