import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  error: false,
  response: "",
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    getLogin: (state) => {
      state.loading = true;
    },
    getLoginSuccess: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = false;
    },
    getLoginFailure: (state) => {
      state.loading = false;
      state.error = "Something went wrong";
      state.response = "";
    },
  },
});
export default loginSlice.reducer;

export const {
  getLogin,
  getLoginSuccess,
  getLoginFailure,
} = loginSlice.actions;

export const loginSelector = (state) => state.response;

export const fetchLogin = (countryCode, phone) => async (dispatch) => {
  var settings = {
    url: `http://salarybook.co.in/public/api/login`,
    method: "POST",
    data: {
      mobile: phone,
      country_code: countryCode,
    },
  };
  dispatch(getLogin());
  axios(settings).then((res) => {
    if (res.data.result == true) {
      dispatch(getLoginSuccess(res.data));
    } else {
      dispatch(getLoginFailure(res.data.message));
    }
  });
};
