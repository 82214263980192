import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  error: false,
  response: "",
};

const updateprofileSlice = createSlice({
  name: "updateprofile",
  initialState,
  reducers: {
    getupdateprofile: (state) => {
      state.loading = true;
    },
    getupdateprofileSuccess: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = false;
    },
    getupdateprofileFailure: (state) => {
      state.loading = false;
      state.error = "Enter valid OTP";
      state.response = "";
    },
  },
});
export default updateprofileSlice.reducer;

export const {
  getupdateprofile,
  getupdateprofileSuccess,
  getupdateprofileFailure,
} = updateprofileSlice.actions;

export const updateprofileSelector = (state) => state.loading;

export const fetchupdateprofile = (phone, otp) => async (dispatch) => {
  var settings = {
    url: `http://salarybook.co.in/public/api/update_profile`,
    method: "POST",
    data: {
      mobile: phone,
      otp: otp,
    },
  };
  dispatch(getupdateprofile());
  axios(settings).then((res) => {
    if (res.data.owner == "yes") {
      dispatch(getupdateprofileSuccess(res.data));
    } else {
      dispatch(getupdateprofileFailure());
    }
  });
};
