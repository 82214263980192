import React from 'react'
import Modal from '../Modal'
import Cross from "../../../assets/icons/Cross.svg";
import Calendar from 'react-calendar';
import CalendarIcon from "../../../assets/icons/Calendar_grey.svg"
import moment from 'moment';
import classes from "./AddDeductionModal.module.css"
import InputField from '../../inputs/Input';
import Button from '../../buttons/Button';
import Cookies from 'js-cookie';
import axios from 'axios';
import Tag from '../../tag/Tag';
import { useSelector } from 'react-redux';

const AddDeductionModal = (props) => {
    const [date, setDate] = React.useState(new Date());
    const [showCalendar, setShowCalendar] = React.useState(false);
    const [amount, setAmount] = React.useState("");
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const [noteerror, setNoteError] = React.useState("");
    const [notesuccess, setNoteSuccess] = React.useState("");
    const [note, setNote] = React.useState("");
    const businessid = useSelector((state) => state.businessid.value);
    const [type, setType] = React.useState("")
    const {details} = props;
    const handleDate = (e) => {
        setDate(e)
        setShowCalendar(false);
    }
    const handleChange = (e) => {
        setAmount(e.target.value);
    }
    
    const handleValidation = () =>  {
        let regx = /^\d[0-9]/
        if (amount !== "" || regx.test(amount) !== false) {
            setSuccess("success")
        } else {
            setError("Enter valid Amount")
        }
    }
    const handleChangeNote = (e) => {
        setNote(e.target.value);
    }
    const handleError = () => {
        if (note === "") {
            setNoteError("Fields can not be empty")
            setNoteSuccess("")
        } else {
            setNoteError("")
            setNoteSuccess("success")
        }
    }
    const handleDeductionBonus = () => {
        console.log(moment(date).format("DD MMMM, YYYY"),Cookies.get("owner_id"),details.id, amount, note);
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/add_staff_deduction`,
            method: "POST",
            data: {
                owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid,
                staff_id: details.id,
                date: moment(date).format("DD MMMM, YYYY"),
                amount: amount,
                type:type,
                description: note
            }
        }
        axios(settings).then((res) => {
            console.log(res);
            if(res.data.result === true) {
                alert("Added Successfully")
            } else {
                alert("Something went wrong!! Add missing values")
            }
        }).catch((err) => {
            alert("Something went wrong!! Try again after sometimes")
        })
    }
    const handleActiveTag = (type) => {
        setType(type)
    }
    return (
        <Modal modalStatus={props.deductionModalStatus} onRequestClose={props.handleCloseDeductionModal}>
            <div style={{height: "90%", boxSizing: "border-box"}}>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Add Bonus</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleCloseDeductionModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.secondary_heading}>For {details.employee_name}</div>
                <div className={classes.content_wrapper}>
                {
                    date === undefined || props.date === null ? null : <div className={classes.dateInput} style={{position: "relative", border: "2px solid #bcbcbc", height: "56px", borderRadius: "16px", margin: "20px 0"}}>
                    <div style={{position: "absolute", top: "5px", left: "20px", fontSize: "0.7rem", color: "#707070", fontWeight: "500"}}>Date</div>
                    <div style={{padding: "20px"}}>{moment(date).format("DD MMMM, YYYY")}</div>
                    <img src={CalendarIcon} alt="calendar" style={{position: "absolute", top: "20px", right: "20px"}} onClick={() => setShowCalendar(!showCalendar)}/>
                </div>
                }
                {showCalendar ? (
                    <div style={{position:"absolute", zIndex: "1"}}>
                        <Calendar
                            value={date}
                            onChange={(e) => handleDate(e)}
                            className={classes.calendar}
                            style={{ display: "none" }}
                            maxDate={new Date()}
                        /></div>
                    ) : null}
                    <InputField type="text" large value={amount} placeholder="&#8377; Enter Amount" label="&#8377; Enter Amount" onChange={(e) => handleChange(e)} onBlur={() => handleValidation()} width="100%" />
                    <div className={classes.tag_wrapper}>
                        <Tag
                            active={type == "PF" ? true : false}
                            onClick={() => handleActiveTag("PF")} 
                        >
                            PF
                        </Tag>

                        <Tag
                            active={type == "ESI" ? true : false}
                            onClick={() => handleActiveTag("ESI")} 
                        >
                            ESI
                        </Tag>
                        <Tag
                            active={type == "Other" ? true : false}
                            onClick={() => handleActiveTag("Other")} 
                        >
                            Other
                        </Tag>
                    </div>
                    
                    <textarea value={note} onChange={(e) => handleChangeNote(e)} onBlur={handleError}  placeholder="Enter Note Here" className={classes.textarea}/>
                    </div>
            </div>
            <Button contained large width="100%" onClick={handleDeductionBonus}>Add Bonus</Button>
        </Modal>
    )
}

export default AddDeductionModal
