export const fonts = {
      // download default Roboto font from cdnjs.com
      Roboto: {
        normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      },
   
      // example of usage fonts in collection
    //   PingFangSC: {
    //     normal: ['https://example.com/fonts/pingfang.ttc', 'PingFangSC-Regular'],
    //     bold: ['https://example.com/fonts/pingfang.ttc', 'PingFangSC-Semibold'],
    //   }
}