import React from "react";
import classes from "./PersonalAllowance.module.css";
import { useLocation } from "react-router";
import LeftArrow from "../../assets/icons/Left_blue.svg";
import RightArrow from "../../assets/icons/Right_blue.svg";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import EmployeeTopPanel from "../../compnents/EmployeeTopPanel/EmployeeTopPanel";
import Button from "../../compnents/buttons/Button";
import Allowance from "../../assets/icons/allowance_white.svg";
import loader from "../../compnents/animations/finding.json";
import moment from "moment";
import {
  getstaffmonthlybalance,
  getstaffmonthlybalanceFailure,
  getstaffmonthlybalanceSuccess,
} from "../../actions/getstaffmonthlybalance";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Lottie from "react-lottie";
import AddAllowanceModal from "../../compnents/modal/AddAllowanceModal/AddAllowanceModal";
import {
  getstaffallowance,
  getstaffallowanceFailure,
  getstaffallowanceSuccess,
} from "../../actions/getstaffallowance";

const PersonalAllowance = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const details = location.state;
  const [time, setTime] = React.useState(
    moment(new Date()).format("MMMM, YYYY")
  );
  const allowancedata = useSelector((state) => state.staffAllowance);

  const [allowanceModalStatus, setAllowanceModalStatus] = React.useState(false);
  const handleOpenAllowanceModal = () => {
    setAllowanceModalStatus(true);
  };
  const handleCloseAllowanceModal = () => {
    setAllowanceModalStatus(false);
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_staff_allowance`,
      method: "POST",
      data: {
        staff_id: details.id,
        month: moment(time).format("MM"),
        year: moment(time).format("YYYY"),
      },
    };
    dispatch(getstaffallowance());
    axios(settings)
      .then((res) => {
        if (res.data.result == true) {
          dispatch(getstaffallowanceSuccess(res.data));
        } else {
          dispatch(getstaffallowanceFailure());
        }
      })
      .catch((err) => {
        dispatch(getstaffallowanceFailure());
      });
  };

  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_staff_allowance`,
      method: "POST",
      data: {
        staff_id: details.id,
        month: moment(time).format("MM"),
        year: moment(time).format("YYYY"),
      },
    };
    dispatch(getstaffallowance());
    axios(settings)
      .then((res) => {
        if (res.data.result == true) {
          dispatch(getstaffallowanceSuccess(res.data));
        } else {
          dispatch(getstaffallowanceFailure());
        }
      })
      .catch((err) => {
        dispatch(getstaffallowanceFailure());
      });
  }, []);
  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_staff_allowance`,
      method: "POST",
      data: {
        staff_id: details.id,
        month: moment(time).format("MM"),
        year: moment(time).format("YYYY"),
      },
    };
    dispatch(getstaffallowance());
    axios(settings)
      .then((res) => {
        if (res.data.result == true) {
          dispatch(getstaffallowanceSuccess(res.data));
        } else {
          dispatch(getstaffallowanceFailure());
        }
      })
      .catch((err) => {
        dispatch(getstaffallowanceFailure());
      });
  }, [time]);

  const handlePreviousMonth = () => {
    let month = time;
    let previousMonth = moment(time).subtract(1, "months").calendar();
    previousMonth = moment(previousMonth).format("MMM, YYYY");
    setTime(previousMonth);
  };
  const handleNextMonth = () => {
    let currentMonth = moment().format("M");
    let nextMonth = moment(time).add(1, "months").calendar();
    nextMonth = moment(nextMonth).format("MMM, YYYY");
    if (currentMonth >= moment(nextMonth).format("M")) {
      setTime(nextMonth);
    }
  };

  let displaydata;
  if (allowancedata.loading === true) {
    displaydata = (
      <div
        style={{
          // position: "absolute",
          // width: "100vw",
          // height: "100vh",
          // backgroundColor: "rgba(0,0,0,0.1)",
          margin: "20px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
          height={200}
          width={200}
          style={{ padding: "0", marginTop: "-50px" }}
        />
      </div>
    );
  } else if (allowancedata.reponse === "") {
    displaydata = <div> No Employees</div>;
  } else if (allowancedata.response.result === false) {
    displaydata = <div>Something went wrong !! Refresh the page</div>;
  } else if (allowancedata.response.result === true) {
    if (allowancedata.response.data.length === 0) {
      displaydata = <div>No Allowances found</div>;
    } else {
      displaydata = allowancedata.response.data.map((entry) => {
        return (
          <div className={classes.row}>
            <div className={classes.date_content}>{entry.date}</div>
            <div className={classes.amount}>&#8377; {entry.amount}</div>
            <div className={classes.notes}>{entry.description}</div>
          </div>
        );
      });
    }
  }
  return (
    <Boilerplate>
      <EmployeeTopPanel details={details} />
      <div className={classes.date_wrapper}>
        <div className={classes.date}>
          <img
            src={LeftArrow}
            alt="Previous"
            onClick={handlePreviousMonth}
            style={{ cursor: "pointer", zIndex: "2" }}
          />
          <div>{time}</div>
          <img
            src={RightArrow}
            alt="Next"
            onClick={handleNextMonth}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className={classes.smsswitch}>
          <Button contained large onClick={handleOpenAllowanceModal}>
            <img
              src={Allowance}
              alt="Allowance"
              onClick={handleNextMonth}
              style={{ cursor: "pointer" }}
            />
            Add Allowance
          </Button>
        </div>
      </div>
      <div className={classes.content_wrapper}>{displaydata}</div>
      <AddAllowanceModal
        allowanceModalStatus={allowanceModalStatus}
        handleOpenAllowanceModal={handleOpenAllowanceModal}
        handleCloseAllowanceModal={handleCloseAllowanceModal}
        details={details}
      />
    </Boilerplate>
  );
};

export default PersonalAllowance;
