import React from 'react'
import Modal from '../Modal'
import classes from "./OvertimeModal.module.css"
import Cross from "../../../assets/icons/Cross.svg";
import InputField from "../../inputs/Input";
import Button from '../../buttons/Button';
import axios from 'axios';
import Calendar from 'react-calendar';
import CalendarIcon from "../../../assets/icons/Calendar_grey.svg"
import ButtonError from '../../buttons/ButtonError';
import moment from 'moment';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getallstaff, getallstaffFailure, getallstaffSuccess } from '../../../actions/getAllStaff';
const OvertimeModal = (props) => {
    const dispatch = useDispatch();
    const [time, setTime] = React.useState("");
    const [date, setDate] = React.useState(new Date());
    const [showCalendar, setShowCalendar] = React.useState(false);
    const [overtimeAmount, setOvertimeAmount] = React.useState("");
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    let timeHours = parseInt(time.split(":")[0])
    let timeMinutes = parseInt(time.split(":")[1])
    const businessid = useSelector((state) => state.businessid.value);
    const handleAmountChange = (e) => {
        setOvertimeAmount(e.target.value);
    }
    
    const handleValidation = () =>  {
        let regx = /^\d[0-9]/
        if (overtimeAmount !== "" || regx.test(overtimeAmount) !== false) {
            setSuccess("success")
        } else {
            setError("Enter valid Amount")
        }
    }
    const handleDate = (e) => {
        setDate(e)
        setShowCalendar(false);
    }
    const handleTime = (e) => {
        setTime(e.target.value)
    }
    const handleOvertime = () => {
        if (date === "" || overtimeAmount == "" || time === "") {
            setError("Fields can not be empty")
        } else {
            var settings = {
                url: `${process.env.REACT_APP_API_URL}/update_staff_attendance`,
                method: "POST",
                data: {
                  staff_id: props.staff_id,
                  attendance: "Present",
                  date: date,
                  overtime: `${timeHours}.${timeMinutes}`,
                  status: "approved",
                },
            };
            axios(settings).then((res) => {
                if (res.data.result === true) {
                    alert(res.data.message);
                } else {
                    console.log(res);
                }
            }).catch((err) => {
                alert(err)
            })
            var settings = {
                url: `${process.env.REACT_APP_API_URL}/get_staff_attendance`,
                method: "POST",
                data: {
                  owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid,
                  date: date,
                },
              };
              dispatch(getallstaff());
              axios(settings)
                .then((res) => {
                  if (res.data.result == true) {
                    dispatch(getallstaffSuccess(res.data));
                  } else {
                    dispatch(getallstaffFailure());
                  }
                })
                .catch((err) => {
                  dispatch(getallstaffFailure());
                });
            
        }
        
    }    
     console.log(props.date);


    return (
        <Modal modalStatus={props.overtimeModalStatus} onRequestClose={props.handleOvertimeModalClose}>
            <div>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Add Overtime</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleOvertimeModalClose}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>For {props.staffName}</div>
                
                {
                    props.date === undefined || props.date === null ? null : <div className={classes.dateInput} style={{position: "relative", border: "2px solid #bcbcbc", height: "56px", borderRadius: "16px", margin: "20px 0"}}>
                    <div style={{position: "absolute", top: "5px", left: "20px", fontSize: "0.7rem", color: "#707070", fontWeight: "500"}}>Date</div>
                    <div style={{padding: "20px"}}>{moment(date).format("DD MMMM, YYYY")}</div>
                    <img src={CalendarIcon} alt="calendar" style={{position: "absolute", top: "20px", right: "20px"}} onClick={() => setShowCalendar(!showCalendar)}/>
                </div>
                }

                
                {showCalendar ? (
                        <Calendar
                            value={date}
                            onChange={(e) => handleDate(e)}
                            className={classes.calendar}
                            style={{ display: "none" }}
                            maxDate={new Date()}
                        />
                    ) : null}
                <div style={{margin: "0 0 20px 0"}}>
                    <InputField type="text" large value={overtimeAmount} placeholder="Overtime Hourly wage" label="Overtime Hourly wage" onChange={(e) => handleAmountChange(e)} onBlur={() => handleValidation()} width="100%" />
                </div>
                <InputField type="time" large value={time} onChange={handleTime} width="100%"/>
                <div style={{margin: "10px 0"}}>&#8377; {overtimeAmount} x {time} = <span style={{color: "#157EF8", fontWeight: "500"}}>&#8377;{((overtimeAmount*timeHours)+(overtimeAmount*(timeMinutes/60))).toFixed(2)}</span> </div>
                {
                    error ? <div style={{color: "red", margin: "10px 0"}}>{error}</div> : null
                }
            </div>
            {
                props.staff_overtime === 0 ? <Button contained large onClick={handleOvertime}>Add Overtime</Button> : <ButtonError contained large onClick={handleOvertime}>Edit Overtime</ButtonError>
            }
            
        </Modal>
    )
}

export default OvertimeModal
