import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "0",
};

export const businessIdSlice = createSlice({
  name: "businessid",
  initialState,
  reducers: {
    change: (state, action) => {
      state.value = action.payload;
    },
  },
});
export const { change } = businessIdSlice.actions;
export default businessIdSlice.reducer;
