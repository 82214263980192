import classes from "./Editjob.module.css";
import React from "react";
import { useHistory, useLocation } from "react-router";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import moment from "moment";
import Button from "../../compnents/buttons/Button";
import UserDetails from "../../assets/icons/UserDetails.svg";
import Calendarplus from "../../assets/icons/Calendar_plus.svg";
import Calendarminus from "../../assets/icons/Calendar_minus.svg";
import Search from "../../assets/icons/search_grey.svg";
import axios from "axios";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import PostJobModal from "../../compnents/modal/PostJobModal/PostJobModal";
import EditBasicDetails from "../../compnents/modal/PostJobModal/EditBasicDetails";
import EditSalaryDetails from "../../compnents/modal/PostJobModal/EditSalaryDetails";
import EditInterviewerDetails from "../../compnents/modal/PostJobModal/EditInterviewerDetails";
import EditAddressDetails from "../../compnents/modal/PostJobModal/EditAddressDetails";

const Editjob = (props) => {
  const location = useLocation();
  const history = useHistory();
  const businessid = useSelector((state) => state.businessid.value);
  const [jobModalStatus, setJobStatusModal] = React.useState(false);
  const [applicants, setApplicants] = React.useState("");
  const details = location.state.details;
  const [basicDetailsStatus, setBasicDetailsStatus] = React.useState(false);
  const [salaryDetailsStatus, setSalaryDetailsStatus] = React.useState(false);
  const [interviewerDetailsStatus, setInterviewerDetailsStatus] =
    React.useState(false);
  const [addressDetailsStatus, setAddressDetailsStatus] = React.useState(false);

  const handleCloseAddressDetailsModal = () => {
    setAddressDetailsStatus(false);
  };

  const handleOpenAddressDetailsModal = () => {
    // createRipple(e);
    setAddressDetailsStatus(true);
  };

  const handleCloseInterviewerDetailsModal = () => {
    setInterviewerDetailsStatus(false);
  };

  const handleOpenInterviewerDetailsModal = () => {
    // createRipple(e);
    setInterviewerDetailsStatus(true);
  };
  const handleCloseSalaryDetailsModal = () => {
    setSalaryDetailsStatus(false);
  };

  const handleOpenSalaryDetailsModal = () => {
    // createRipple(e);
    setSalaryDetailsStatus(true);
  };
  const handleCloseBasicDetailsModal = () => {
    setBasicDetailsStatus(false);
  };

  const handleOpenBasicDetailsModal = () => {
    // createRipple(e);
    setBasicDetailsStatus(true);
  };

  const handleCloseModal = () => {
    setJobStatusModal(false);
  };

  const handleOpenModal = () => {
    // createRipple(e);
    setJobStatusModal(true);
  };
  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/owner_get_apply_job`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? Cookies.get("owner_id") : businessid,
        job_id: location.state.jobid,
      },
    };
    axios(settings).then((res) => {
      if (res.data.result === true) {
        setApplicants(res.data.data);
      }
    });
  }, []);
  const handlejob = (jobid, jobstatus) => {
    if (jobstatus === "Active") {
      var settings = {
        url: `${process.env.REACT_APP_API_URL}/update_job`,
        method: "POST",
        data: {
          id: jobid,
          status: "Inactive",
        },
      };
    } else if (jobstatus === "Inactive") {
      var settings = {
        url: `${process.env.REACT_APP_API_URL}/update_job`,
        method: "POST",
        data: {
          id: jobid,
          status: "Active",
        },
      };
    }
    axios(settings)
      .then((res) => {
        alert(res.data.message);
        window.location.reload();
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };
  const handleEditjob = () => {
    history.push({
      pathname: `/${details.id}/editjob`,
      state: { details: details },
    });
  };
  console.log(location);
  return (
    <Boilerplate>
      <div className={classes.toppanel}>
        <div className={classes.top_row}>
          <div className={classes.column1}>
            <div className={classes.top}>{details[0].job_profile}</div>
            <div className={classes.bottom}>
              &#8377; {details[0].salary_from} - &#8377; {details[0].salary_to}
            </div>
          </div>
          <div className={classes.column2}>
            <div className={classes.top}>
              {moment(details[0].created_at).format("DD/MM/YYYY")}
            </div>
            <div className={classes.bottom}>Posted on</div>
          </div>
          <div className={classes.column3}>
            <div
              className={classes.top_row}
              style={{
                color: details[0].status === "Active" ? "#27AE60" : "#FF605C",
                fontWeight: "500",
              }}
            >
              {details[0].status}
            </div>
            <div className={classes.bottom}>Status</div>
          </div>
          <div className={classes.column4}>
            <Button large onClick={() => handlejob(details.id, details.status)}>
              {details.status === "Active"
                ? "Deactivate Job"
                : "Reactivate Job"}
            </Button>
            <Button large onClick={handleEditjob}>
              Edit Details
            </Button>
          </div>
        </div>
        <hr className={classes.firstline} />
        <div className={classes.middle_row}>
          <img src={UserDetails} alt="Applicants" />
          <span className={classes.content}>
            {applicants.length} Applicants
          </span>
          <span className={classes.content}>&#183;</span>
          <img src={Calendarplus} alt="Post" />
          <span className={classes.content}>
            Posted on: {moment(details[0].created_at).format("DD-MM-YYYY")}
          </span>
          <span className={classes.content}>&#183;</span>
          {/* <img src={Calendarminus} alt="Post" /> */}
        </div>

        <hr className={classes.secondline} />
        <div
          className={classes.top_row}
          style={{ margin: "2vh 0", flexWrap: "wrap" }}
        >
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].experience}</div>
            <div className={classes.bottom}>Experience</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].qualification}</div>
            <div className={classes.bottom}>Education</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].english_level}</div>
            <div className={classes.bottom}>English Level</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].gender}</div>
            <div className={classes.bottom}>Gender</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>
              {details[0].start_work_day} to {details[0].end_work_day}
            </div>
            <div className={classes.bottom}>working days</div>
          </div>
          <div className={classes.column1}>
            <div className={classes.top2}>
              {details[0].start_work_time} to {details[0].end_work_time}
            </div>
            <div className={classes.bottom}>Timing</div>
          </div>
        </div>
        <div
          className={classes.top_row}
          style={{ margin: "2vh 0", flexWrap: "wrap" }}
        >
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].description}</div>
            <div className={classes.bottom}>Job Description</div>
          </div>
        </div>
        <div
          className={classes.top_row}
          style={{ margin: "2vh 0", flexWrap: "wrap" }}
        >
          <div className={classes.column1}>
            <div className={classes.top2}>{details[0].address}</div>
            <div className={classes.bottom}>Address</div>
          </div>
        </div>
      </div>
      <div className={classes.joblist_wrapper}>
        <div className={classes.joblist_row}>
          <div className={classes.header_row}>
            <div className={classes.heading}>Basic Details</div>
            <Button onClick={handleOpenBasicDetailsModal}>Edit</Button>
          </div>
          <div className={classes.details_row}>
            <div className={classes.single_row}>
              <div className={classes.left}>Job Role</div>
              <div className={classes.right}>{details[0].job_profile}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Minimum Education</div>
              <div className={classes.right}>{details[0].qualification}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Job Type</div>
              <div className={classes.right}>{details[0].job_type}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Shift Type</div>
              <div className={classes.right}>{details[0].shift_type}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Gender Profile</div>
              <div className={classes.right}>{details[0].gender}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>English Level</div>
              <div className={classes.right}>{details[0].english_level}</div>
            </div>
          </div>
        </div>
        <div className={classes.joblist_row}>
          <div className={classes.header_row}>
            <div className={classes.heading}>Salary & Positions Details</div>
            <Button onClick={handleOpenSalaryDetailsModal}>Edit</Button>
          </div>
          <div className={classes.details_row}>
            <div className={classes.single_row}>
              <div className={classes.left}>Minimum Salary</div>
              <div className={classes.right}>{details[0].salary_from}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Maximum Salary</div>
              <div className={classes.right}>{details[0].salary_to}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Postitions Available</div>
              <div className={classes.right}>{details[0].no_of_openings}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Work from Home</div>
              <div className={classes.right}>{details[0].work_from_home}</div>
            </div>
          </div>
        </div>
        <div className={classes.joblist_row}>
          <div className={classes.header_row}>
            <div className={classes.heading}>
              Timing and Description Details
            </div>
            <Button onClick={handleOpenModal}>Edit</Button>
          </div>
          <div className={classes.details_row}>
            <div className={classes.single_row}>
              <div className={classes.left}>Tags</div>
              <div className={classes.right}>{details[0].tags}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Work days</div>
              <div className={classes.right}>
                {details[0].start_work_day} - {details[0].end_work_day}
              </div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Work Hours</div>
              <div className={classes.right}>
                {details[0].start_work_time} - {details[0].end_work_time}
              </div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Job Desctiption</div>
              <div className={classes.right}>{details[0].description}</div>
            </div>
          </div>
        </div>
        <div className={classes.joblist_row}>
          <div className={classes.header_row}>
            <div className={classes.heading}>Interviewer's Information</div>
            <Button onClick={handleOpenInterviewerDetailsModal}>Edit</Button>
          </div>
          <div className={classes.details_row}>
            <div className={classes.single_row}>
              <div className={classes.left}>Company Name</div>
              <div className={classes.right}>{details[0].company_name}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Recruiter's Name</div>
              <div className={classes.right}>{details[0].contact_person}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Recruiter's Number</div>
              <div className={classes.right}>{details[0].phone}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Recruiter's Email</div>
              <div className={classes.right}>{details[0].email}</div>
            </div>
          </div>
        </div>
        <div className={classes.joblist_row}>
          <div className={classes.header_row}>
            <div className={classes.heading}>Address Information</div>
            <Button onClick={handleOpenAddressDetailsModal}>Edit</Button>
          </div>
          <div className={classes.details_row}>
            <div className={classes.single_row}>
              <div className={classes.left}>Flat no./ Plat no.</div>
              <div className={classes.right}>{details[0].address}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>City</div>
              <div className={classes.right}>{details[0].city}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Area</div>
              <div className={classes.right}>{details[0].area}</div>
            </div>
            <div className={classes.single_row}>
              <div className={classes.left}>Receive applications from</div>
              <div className={classes.right}>
                {details[0].application_range}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PostJobModal
        jobModalStatus={jobModalStatus}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
        id={details[0].id}
      />
      <EditBasicDetails
        jobModalStatus={basicDetailsStatus}
        handleCloseModal={handleCloseBasicDetailsModal}
        handleOpenModal={handleOpenBasicDetailsModal}
        id={details[0].id}
      />
      <EditSalaryDetails
        jobModalStatus={salaryDetailsStatus}
        handleCloseModal={handleCloseSalaryDetailsModal}
        handleOpenModal={handleOpenSalaryDetailsModal}
        id={details[0].id}
      />
      <EditInterviewerDetails
        jobModalStatus={interviewerDetailsStatus}
        handleCloseModal={handleCloseInterviewerDetailsModal}
        handleOpenModal={handleCloseInterviewerDetailsModal}
        id={details[0].id}
      />
      <EditAddressDetails
        jobModalStatus={addressDetailsStatus}
        handleCloseModal={handleCloseAddressDetailsModal}
        handleOpenModal={handleOpenAddressDetailsModal}
        id={details[0].id}
      />
    </Boilerplate>
  );
};

export default Editjob;
