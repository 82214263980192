import axios from 'axios';
import Cookies from 'js-cookie';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import classes from "./ProfileDropdown.module.css"

const ProfileDropdown = (props) => {
    const businessid = useSelector((state) => state.businessid.value);
    const [selected, setSelected] = React.useState("");
    React.useEffect(() => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/get_subowners`,
            method: "POST",
            data: {
                main_owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === true) {
                setSelected(res.data.data[0].name)
            }
        })
    }, [])
    let dropdowncontentstyle;
    let dropdown_head_style;
    if(props.size === "large") {
        dropdown_head_style = {
            color: props.white ? "#202020" : "",
            backgroundColor: props.white ? "#fff" : "",
            boxShadow: props.white ? "0 0 4px 2px rgba(0,0,0,0.06)" : "",
            
        }
    } else if(props.size === "small") {
        dropdown_head_style = {
            
            color: props.white ? "#202020" : "",
            backgroundColor: props.white ? "#fff" : "",
            boxShadow: props.white ? "0 0 4px 2px rgba(0,0,0,0.06)" : "",
            
        }
    } else {
        dropdown_head_style = {
           
            color: props.white ? "#202020" : "",
            backgroundColor: props.white ? "#fff" : "",
            boxShadow: props.white ? "0 0 4px 2px rgba(0,0,0,0.06)" : "",
            
        }
    }
    if(props.drop) {
        dropdowncontentstyle= {
            opacity: "1",
            visibility: "visible",
            transition: "all 0.4s ease-in-out",
            fontSize: props.fontsize
        }
    } else {
        dropdowncontentstyle= {
            marginTop: "7px",
            display: "block",
            visibility: "hidden",
            opacity: "0",
            position: "absolute",
            boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
            minWidth: "150px",
            boxSizing: "border-box",
            border: "5px solid #fff",
            borderRadius: "5px",
            backgroundColor: "#fff",
            fontSize: props.fontsize
        }
    }
    const history = useHistory();
    return (
        <div className={classes.dropdown_wrapper}>
            <span className={classes.dropdown_head} style={dropdown_head_style}>
                <div style={{marginRight: "1rem", display: "flex", gap: "10px", alignItems: "center", textOverflow: "ellipsis", width: "90%"}}>
                    {
                        props.icon ? <div style={{border: "2px solid white", borderRadius: "16px",  overflow: "hidden", display: "flex", justifyContent: "center", alignItems: 'center'}}><img src={props.icon} alt="logo" /></div> : null
                    }
                    {selected}</div>
                <div className={classes.dropdown_arrow}></div>
            </span>
            <div className={classes.dropdown_content_wrapper} style={{right: props.rtl ? "20px": ""}}>
                <div className={classes.dropdown_content}>
                    <div style={{
                        padding: "6px 24px", 
                        backgroundColor: "#fff", 
                        color: "#202020",
                        marginBottom: "10px"
                    }} className={classes.dropdown_link} onClick={() => {history.push("/settings")}}>View Profile</div>
                    <div style={{
                        padding: "6px 24px", 
                        backgroundColor: "#fff", 
                        color: "#202020",
                        marginBottom: "10px"
                    }} className={classes.dropdown_link} onClick={() => {
                        Cookies.remove("owner_id");
                        // history.push("/");
                        window.location.replace("/")
                        }}>Log out</div>
                </div>
            </div>
        </div>
    )
}

export default ProfileDropdown
