import React from "react";
import classes from "./PersonalPayment.module.css";
import { useLocation } from "react-router";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import EmployeeTopPanel from "../../compnents/EmployeeTopPanel/EmployeeTopPanel";
import LeftArrow from "../../assets/icons/Left_blue.svg";
import RightArrow from "../../assets/icons/Right_blue.svg";
import Pay from "../../assets/icons/Payalt_white.svg";
import Download from "../../assets/icons/Download_white.svg";
import DownloadBlue from "../../assets/icons/Download_blue.svg";
import moment from "moment";
import Button from "../../compnents/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getstaffPayment,
  getstaffPaymentFailure,
  getstaffPaymentSuccess,
} from "../../actions/getStaffPayment";

const PersonalPayment = (props) => {
  const location = useLocation();
  const details = location.state;
  const dispatch = useDispatch();
  const paymentDetails = useSelector((state) => state.staffPayment);
  const [time, setTime] = React.useState(
    moment(new Date()).format("MMMM, YYYY")
  );

  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_staff_payment_history`,
      method: "POST",
      data: {
        staff_id: details.id,
        month: moment(time).format("MM"),
        year: moment(time).format("YYYY"),
      },
    };
    dispatch(getstaffPayment());
    axios(settings)
      .then((res) => {
        if (res.data.result === true) {
          dispatch(getstaffPaymentSuccess(res.data.data));
        } else {
          dispatch(getstaffPaymentFailure());
          alert("something went wrong");
        }
      })
      .catch((err) => {
        dispatch(getstaffPaymentFailure());
        alert("something went wrong!! Try after sometime");
      });
  }, [time]);
  const handlePreviousMonth = () => {
    let month = time;
    let previousMonth = moment(time).subtract(1, "months").calendar();
    previousMonth = moment(previousMonth).format("MMM, YYYY");
    setTime(previousMonth);
  };
  const handleNextMonth = () => {
    let currentMonth = moment().format("M");
    let nextMonth = moment(time).add(1, "months").calendar();
    nextMonth = moment(nextMonth).format("MMM, YYYY");
    if (currentMonth >= moment(nextMonth).format("M")) {
      setTime(nextMonth);
    }
  };
  return (
    <Boilerplate>
      <EmployeeTopPanel details={details} />
      <div className={classes.date_wrapper}>
        <div className={classes.date}>
          <img
            src={LeftArrow}
            alt="Previous"
            onClick={handlePreviousMonth}
            style={{ cursor: "pointer", zIndex: "2" }}
          />
          <div>{time}</div>
          <img
            src={RightArrow}
            alt="Next"
            onClick={handleNextMonth}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className={classes.smsswitch}>
          <Button contained large>
            <img src={Pay} alt="Pay" />
            Add Payment
          </Button>
          <Button contained large>
            <img src={Download} alt="Pay" />
            Download Payment Report
          </Button>
        </div>
      </div>
      <div className={classes.content_wrapper}>
        <div className={classes.row}>
          <div className={classes.datecontent}>03/03/2021</div>
          <div className={classes.amount}>&#8377; 20000</div>
          <div className={classes.notes}>Salary Payment</div>
          <Button>
            <img src={DownloadBlue} alt="Download" />
            Download Receipt
          </Button>
        </div>
      </div>
    </Boilerplate>
  );
};

export default PersonalPayment;
