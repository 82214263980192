import React from 'react'
import PostModal from '../PostModal'
import classes from "./PostJobModal.module.css"
import Cross from "../../../assets/icons/Cross.svg";
import InputField from '../../inputs/Input';
import Radio from '../../radio/Radio';
import Button from '../../buttons/Button';
import Tag from '../../tag/Tag';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Back from "../../../assets/icons/arrow-left.svg"
import { useHistory, useLocation } from 'react-router';

const PostJobModal = (props) => {
    let modal_content;
    const businessid = useSelector((state) => state.businessid.value)
    const [formStep, setFormStep] = React.useState("one");
    const [jobrole, setJobRole] = React.useState("");
    const [qualification, setQualification] = React.useState("");
    const [jobtype, setJobtype] = React.useState("Part Time");
    const [shifttype, setShiftType] = React.useState("Day Shift");
    const [gender, setGender] = React.useState("Both");
    const [englishlevel, setEnglishLevel] = React.useState("Great");
    const [minimumSalary, setMinimumSalary] = React.useState("");
    const [maximumSalary, setMaximumSalary] = React.useState("");
    const [position, setPosition] = React.useState("");
    const [workfromhome, setworkfromhome] = React.useState("No");
    const [tags, setTags] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [companyname, setCompanyName] = React.useState("");
    const [recruitername, setRecruiterName] = React.useState("");
    const [recruiternumber, setRecruiterNumber] = React.useState("");
    const [recruiterEmail, setRecruiterEmail] = React.useState("");
    const [flatno, setflatno] = React.useState("");
    const [city, setCity] = React.useState("");
    const [area, setArea] = React.useState("");
    const [applicationRange, setApplicationRange] = React.useState("");
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const location = useLocation();
    console.log(location.pathname.includes("editjob"));
    
    const handlePostJob = () => {
        let dynamic_url;
        let data;
        
        if (location.pathname.includes("editjob") === true) {
            dynamic_url = `${process.env.REACT_APP_API_URL}/update_job`
            data = {
                id: props.id,
                job_profile: jobrole,
                job_type: jobtype,
                qualification: qualification,
                salary_from: minimumSalary,
                salary_to: maximumSalary,
                description: description,
                no_of_openings: qualification,
                address: flatno,
                gender: gender,
                company_name: companyname,
                contact_person: recruitername,
                phone: recruiternumber,
                email: recruiterEmail,
                tags: tags,
                city: city,
                area: area,
                work_from_home: workfromhome,
                application_range: applicationRange,
                shift_type: shifttype,
                english_level: englishlevel
            }
        } else {
            dynamic_url = `${process.env.REACT_APP_API_URL}/add_job`
            data = {
                owner_id: businessid === "0" ? Cookies.get("owner_id") : businessid,
                job_profile: jobrole,
                job_type: jobtype,
                qualification: qualification,
                salary_from: minimumSalary,
                salary_to: maximumSalary,
                description: description,
                no_of_openings: qualification,
                address: flatno,
                gender: gender,
                company_name: companyname,
                contact_person: recruitername,
                phone: recruiternumber,
                email: recruiterEmail,
                tags: tags,
                city: city,
                area: area,
                work_from_home: workfromhome,
                application_range: applicationRange,
                shift_type: shifttype,
                english_level: englishlevel
            }
        }
        var settings = {
            url: dynamic_url,
            method: "POST",
            data: data
        }
        if (error.flatno === "" && error.city === "" && error.area === "" && applicationRange !== "") {
            axios(settings).then((res) => {
                if (res.data.result === true) {
                    alert(res.data.message);
                    window.location.reload();
                } else {
                    alert("Something went wrong !!")
                }
                }).catch(() => {
                    alert("Something went wrong !! Try again after sometimes")
            })
        }
        
        
    }

    const handleCancel = () => {
        setFormStep("one")
        props.handleCloseModal();
    }

    const handleRadioJobtype = (jobtype) => {
        setJobtype(jobtype);
    }
    const handleRadioShifttype = (shifttype) => {
        setShiftType(shifttype)
    }
    const handleRadioGendertype = (gendertype) => {
        setGender(gendertype)
    }
    const handleRadioEnglishLevel = (englishlevel) => {
        setEnglishLevel(englishlevel);
    }
    const handleRadioWorkFromHome = (workfromhome) => {
        setworkfromhome(workfromhome)
    }
    const handleTag = (range) => {
        setApplicationRange(range);
    }
    const handleInput = (e, type) => {
        switch(type) {
            case "jobrole":
                setJobRole(e.target.value);
                break;
            case "qualification":
                setQualification(e.target.value);
                break;
            case "Minimum Salary":
                setMinimumSalary(e.target.value);
                break;
            case "Maximum Salary":
                setMaximumSalary(e.target.value);
                break;
            case "Number of Positions":
                setPosition(e.target.value);
                break;
            case "Tags":
                setTags(e.target.value);
                break;
            case "Company Name":
                setCompanyName(e.target.value);
                break;
            case "Recruiter's Name":
                setRecruiterName(e.target.value);
                break;
            case "Recruiter's Number":
                setRecruiterNumber(e.target.value);
                break;
            case "Recruiter's Email":
                setRecruiterEmail(e.target.value);
                break;
            case "Flat no./Plot no.":
                setflatno(e.target.value);
                break;
            case "City":
                setCity(e.target.value);
                break;
            case "Area":
                setArea(e.target.value);
                break;
            default:
                break
        }
    }
    const handleTextArea = (e) => {
        setDescription(e.target.value)
    }
    const handleValidation = (type) => {
        let phoneRegx = /^\d+$/;
        switch(type) {
            case "jobrole":
                if (jobrole.length <= 3) {
                    setError((prevState) => ({
                      ...prevState,
                      jobrole: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                      ...prevState,
                      jobrole: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                      ...prevState,
                      jobrole: "success",
                    }));
                    setError((prevState) => ({
                      ...prevState,
                      jobrole: "",
                    }));
                }
                break;
            case "qualification":
                if (qualification.length <= 2) {
                    setError((prevState) => ({
                      ...prevState,
                      qualification: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                      ...prevState,
                      qualification: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                      ...prevState,
                      qualification: "success",
                    }));
                    setError((prevState) => ({
                      ...prevState,
                      qualification: "",
                    }));
                }
                break;
            case "Minimum Salary":
                if (minimumSalary.length === 0 || phoneRegx.test(minimumSalary) == false) {
                    setError((prevState) => ({
                        ...prevState,
                        minimumSalary: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        minimumSalary: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        minimumSalary: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        minimumSalary: "",
                    }));
                }
                break;
            case "Maximum Salary":
                if (maximumSalary.length === 0 || phoneRegx.test(maximumSalary) == false) {
                    setError((prevState) => ({
                        ...prevState,
                        maximumSalary: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        maximumSalary: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        maximumSalary: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        maximumSalary: "",
                    }));
                }
                break;
            case "Number of Positions":
                if (position.length < 1 || position.startsWith("0") === true || phoneRegx.test(position) === false) {
                    setError((prevState) => ({
                        ...prevState,
                        position: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        position: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        position: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        position: "",
                    }));
                }
                break;
            case "Tags":
                if (tags.length <= 3) {
                    setError((prevState) => ({
                      ...prevState,
                      tags: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                      ...prevState,
                      tags: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                      ...prevState,
                      tags: "success",
                    }));
                    setError((prevState) => ({
                      ...prevState,
                      tags: "",
                    }));
                }
                break;
            case "description":
                if (description.length <= 3) {
                    setError((prevState) => ({
                        ...prevState,
                        description: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        description: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        description: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        description: "",
                    }));
                }
                break;
            case "Company Name":
                if (companyname.length <= 3) {
                    setError((prevState) => ({
                        ...prevState,
                        companyname: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        companyname: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        companyname: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        companyname: "",
                    }));
                }
                break;
            case "Recruiter's Name":
                if (recruitername.length <= 3) {
                    setError((prevState) => ({
                        ...prevState,
                        recruitername: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruitername: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruitername: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        recruitername: "",
                    }));
                }
                break;
            case "Recruiter's Number":
                if (recruiternumber.length !== 10 || phoneRegx.test(recruiternumber) == false) {
                    setError((prevState) => ({
                        ...prevState,
                        recruiternumber: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruiternumber: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruiternumber: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        recruiternumber: "",
                    }));
                }
                break;
            case "Recruiter's Email":
                if (recruiterEmail.length <= 3) {
                    setError((prevState) => ({
                        ...prevState,
                        recruiterEmail: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruiterEmail: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruiterEmail: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        recruiterEmail: "",
                    }));
                }
                break;
            case "Flat no./Plot no.":
                if (flatno.length < 1) {
                    setError((prevState) => ({
                        ...prevState,
                        flatno: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        flatno: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        flatno: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        flatno: "",
                    }));
                }
                break;
            case "City":
                if (city.length < 3) {
                    setError((prevState) => ({
                        ...prevState,
                        city: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        city: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        city: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        city: "",
                    }));
                }
                break;
            case "Area":
                if (area.length < 3) {
                    setError((prevState) => ({
                        ...prevState,
                        area: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        area: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        area: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        area: "",
                    }));
                }
                break;
        }
    }
    if (formStep === "one") {
        modal_content = (<>
            <div className={classes.user}>Basic Details</div>
            <div className={classes.details_wrapper}>
                <div>
                    <InputField
                        type="text"
                        id="job role"
                        label="Job role"
                        placeholder="Job role"
                        value={jobrole}
                        onChange={(e) => handleInput(e, "jobrole")}
                        onBlur={() => handleValidation("jobrole")}
                        error={error.jobrole}
                        success={success.jobrole}
                        width="100%"
                        margin="3vh 0 0 0"
                    />
                </div>
                <div>
                    <InputField
                        type="text"
                        id="minimum education"
                        label="Minimum Education"
                        placeholder="Minimum Education"
                        value={qualification}
                        onChange={(e) => handleInput(e, "qualification")}
                        onBlur={() => handleValidation("qualification")}
                        error={error.qualification}
                        success={success.qualification}
                        width="100%"
                        margin="3vh 0 0 0 "
                    />
                </div>
                <div className={classes.radiohead}>Job Type</div>
                <div className={classes.row} id="jobtype">
                    <Radio
                        name="jobtype"
                        description="Part Time"
                        value="Part Time"
                        checked={jobtype === "Part Time" ? true : false}
                        onChange={() => handleRadioJobtype("Part Time")}
                    />
                    <Radio
                        name="jobtype"
                        description="Full Time"
                        value="Full Time"
                        checked={jobtype === "Full Time" ? true : false}
                        onChange={() => handleRadioJobtype("Full Time")}
                    />
                </div>
                <div className={classes.radiohead}>Shift Type</div>
                <div className={classes.row} id="shifttype">
                    <Radio
                        name="shifttype"
                        description="Day Shift"
                        value="Day Shift"
                        checked={shifttype === "Day Shift" ? true : false}
                        onChange={() => handleRadioShifttype("Day Shift")}
                    />
                    <Radio
                        name="shifttype"
                        description="Night Shift"
                        value="Night Shift"
                        checked={shifttype === "Night Shift" ? true : false}
                        onChange={() => handleRadioShifttype("Night Shift")}
                    />
                </div>
                <div className={classes.radiohead}>Gender Preference</div>
                <div className={classes.row} id="gender">
                    <Radio
                        name="gender"
                        description="Female"
                        value="Female"
                        checked={gender === "Female" ? true : false}
                        onChange={() => handleRadioGendertype("Female")}
                    />
                    <Radio
                        name="gender"
                        description="Male"
                        value="Male"
                        checked={gender === "Male" ? true : false}
                        onChange={() => handleRadioGendertype("Male")}
                    />
                    <Radio
                        name="gender"
                        description="Both"
                        value="Both"
                        checked={gender === "Both" ? true : false}
                        onChange={() => handleRadioGendertype("Both")}
                    />
                </div>
                <div className={classes.radiohead}>English Preference</div>
                <div className={classes.row} id="english">
                    <Radio
                        name="english"
                        description="Excellent"
                        value="Excellent"
                        checked={englishlevel === "Excellent" ? true : false}
                        onChange={() => handleRadioEnglishLevel("Excellent")}
                    />
                    <Radio
                        name="english"
                        description="Great"
                        value="Great"
                        checked={englishlevel === "Great" ? true : false}
                        onChange={() => handleRadioEnglishLevel("Great")}
                    />
                    <Radio
                        name="english"
                        description="Average"
                        value="Average"
                        checked={englishlevel === "Average" ? true : false}
                        onChange={() => handleRadioEnglishLevel("Average")}
                    />
                </div>
            </div>
        </>)
    } else if (formStep === "two") {
        modal_content = (<>
            <div className={classes.user}>Salary and Postions available</div>
            <div className={classes.details_wrapper}>
                <InputField
                    type="text"
                    id="minimum salary"
                    label="Minimum Salary"
                    placeholder="Minimum Salary"
                    value={minimumSalary}
                    onChange={(e) => handleInput(e, "Minimum Salary")}
                    onBlur={() => handleValidation("Minimum Salary")}
                    error={error.minimumSalary}
                    success={success.minimumSalary}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                
                <InputField
                    type="text"
                    id="maximum salary"
                    label="Maximum Salary"
                    placeholder="Maximum Salary"
                    value={maximumSalary}
                    onChange={(e) => handleInput(e, "Maximum Salary")}
                    onBlur={() => handleValidation("Maximum Salary")}
                    error={error.maximumSalary}
                    success={success.maximumSalary}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                <InputField
                    type="text"
                    id="Number of Positions"
                    label="Number of Positions"
                    placeholder="Number of Positions"
                    value={position}
                    onChange={(e) => handleInput(e, "Number of Positions")}
                    onBlur={() => handleValidation("Number of Positions")}
                    error={error.position}
                    success={success.position}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                
                <div className={classes.radiohead}>Is work from home available ?</div>
                <div className={classes.row} id="workfromhome">
                    <Radio
                        name="workfromhome"
                        description="Yes"
                        value="Yes"
                        checked={workfromhome === "Yes" ? true : false}
                        onChange={() => handleRadioWorkFromHome("Yes")}
                    />
                    <Radio
                        name="workfromhome"
                        description="No"
                        value="No"
                        checked={workfromhome === "No" ? true : false}
                        onChange={() => handleRadioWorkFromHome("No")}
                    />
                </div>
            </div>
        </>)
    } else if (formStep === "three") {
        modal_content = (<>
            <div className={classes.user}>Interviewer's Information</div>
            <div className={classes.details_wrapper} style={{marginTop: "2vh"}}>
                <div className={classes.user}>This information will be shared to the candidate who applies to this job.</div>
                <div>
                <InputField
                    type="text"
                    id="Company Name"
                    label="Company Name"
                    placeholder="Company Name"
                    value={companyname}
                    onChange={(e) => handleInput(e, "Company Name")}
                    onBlur={() => handleValidation("Company Name")}
                    error={error.companyname}
                    success={success.companyname}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                </div>
                <div>
                <InputField
                    type="text"
                    id="Recruiter's Name"
                    label="Recruiter's Name"
                    placeholder="Recruiter's Name"
                    value={recruitername}
                    onChange={(e) => handleInput(e, "Recruiter's Name")}
                    onBlur={() => handleValidation("Recruiter's Name")}
                    error={error.recruitername}
                    success={success.recruitername}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                </div>
                <div>
                <InputField
                    type="text"
                    id="Recruiter's Number"
                    label="Recruiter's Number"
                    placeholder="Recruiter's Number"
                    value={recruiternumber}
                    onChange={(e) => handleInput(e, "Recruiter's Number")}
                    onBlur={() => handleValidation("Recruiter's Number")}
                    error={error.recruiternumber}
                    success={success.recruiternumber}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                </div>
                <div>
                <InputField
                    type="text"
                    id="Recruiter's Email"
                    label="Recruiter's Email"
                    placeholder="Recruiter's Email"
                    value={recruiterEmail}
                    onChange={(e) => handleInput(e, "Recruiter's Email")}
                    onBlur={() => handleValidation("Recruiter's Email")}
                    error={error.recruiterEmail}
                    success={success.recruiterEmail}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                </div>
            </div>
        </>)
    } else if (formStep === "four") {
        modal_content = (<>
            <div className={classes.user}>Interview Address</div>
            <div className={classes.details_wrapper} style={{marginTop: "2vh"}}>
            
            <InputField
                type="text"
                id="Flat no./Plot no."
                label="Flat no./Plot no."
                placeholder="Flat no./Plot no."
                value={flatno}
                onChange={(e) => handleInput(e, "Flat no./Plot no.")}
                onBlur={() => handleValidation("Flat no./Plot no.")}
                error={error.flatno}
                success={success.flatno}
                width="100%"
                margin="3vh 0 0 0 "
            />
            
            
            <InputField
                type="text"
                id="City"
                label="City"
                placeholder="City"
                value={city}
                onChange={(e) => handleInput(e, "City")}
                onBlur={() => handleValidation("City")}
                error={error.city}
                success={success.city}
                width="100%"
                margin="3vh 0 0 0 "
            />
            
            <InputField
                type="text"
                id="Area"
                label="Area"
                placeholder="Area"
                value={area}
                onChange={(e) => handleInput(e, "Area")}
                onBlur={() => handleValidation("Area")}
                error={error.area}
                success={success.area}
                width="100%"
                margin="3vh 0 0 0 "
            />
            
            
            <div className={classes.user} style={{fontWeight: "500", margin: "2vh 0"}}>Receive applications from</div>
            <div className={classes.row}>
            <Tag
                active={applicationRange == "Within 10km" ? true : false}
                onClick={() => handleTag("Within 10km")}
            >
                Within 10km
            </Tag>
            <Tag
                active={applicationRange == "Within 25km" ? true : false}
                onClick={() => handleTag("Within 25km")}
            >
                Within 25km
            </Tag>
            <Tag
                active={applicationRange == "Within Mumbai" ? true : false}
                onClick={() => handleTag("Within Mumbai")}
            >
                Within Mumbai
            </Tag>
            </div>
            </div>
        </>)
        
    }
    const handleContinue = () => {
        if(formStep === "one") {
            if (error.jobrole === "" && error.qualification === "") {
                setFormStep("two")    
            }
        } else if (formStep === "two") {
            if (error.minimumSalary === "" && error.maximumSalary === "" && error.position === "") {
                setFormStep("three");
            }
        } else if (formStep === "three") {
            if (error.companyname === "" && error.recruitername === "" && error.recruiternumber === "" && error.recruiterEmail === "") {
                setFormStep("four");
            }
        }
    }
    const handleBack = () => {
        if (formStep === "two") {
            setFormStep("one")
        } else if (formStep === "three") {
            setFormStep("two");
        } else if (formStep === "four") {
            setFormStep("three")
        }
    }
    
    return (
        <PostModal modalStatus={props.jobModalStatus} onRequestClose={props.handleCloseModal}>
            <div style={{height: "90%", boxSizing: "border-box"}}>
                <div className={classes.heading_wrapper}>
                    <img src={Back} alt="Back" className={classes.backButton} onClick={handleBack}/>
                    <div className={classes.modal_heading}>Post a new job</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleCloseModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                {modal_content}
            </div>
            {
                formStep === "four" ? <Button large width="100%" contained onClick={handlePostJob}>Post this job</Button> : <div style={{display:"flex", justifyContent:"space-between"}}>
                <Button large width="45%" onClick={handleCancel}>Cancel</Button>
                <Button large contained width="45%" onClick={handleContinue}>Continue</Button>
            </div>
            }
            
        </PostModal>
    )
}

export default PostJobModal
