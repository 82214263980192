import { faArrowDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import classes from "./Dropdown.module.css"
import {Link} from "react-router-dom"

const Dropdown = (props) => {
    let dropdowncontentstyle;
    let dropdown_head_style;
    if(props.size === "large") {
        dropdown_head_style = {
            color: props.white ? "#202020" : "",
            backgroundColor: props.white ? "#fff" : "",
            boxShadow: props.white ? "0 0 4px 2px rgba(0,0,0,0.06)" : "",
        }
    } else if(props.size === "small") {
        dropdown_head_style = {
            color: props.white ? "#202020" : "",
            backgroundColor: props.white ? "#fff" : "",
            boxShadow: props.white ? "0 0 4px 2px rgba(0,0,0,0.06)" : "",
        }
    } else {
        dropdown_head_style = {
            color: props.white ? "#202020" : "",
            backgroundColor: props.white ? "#fff" : "",
            boxShadow: props.white ? "0 0 4px 2px rgba(0,0,0,0.06)" : "",
        }
    }
    if(props.drop) {
        dropdowncontentstyle= {
            opacity: "1",
            visibility: "visible",
            transition: "all 0.4s ease-in-out",
            
        }
    } else {
        dropdowncontentstyle= {
            marginTop: "7px",
            display: "block",
            visibility: "hidden",
            opacity: "0",
            position: "absolute",
            boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
            minWidth: "150px",
            boxSizing: "border-box",
            border: "5px solid #fff",
            borderRadius: "5px",
            backgroundColor: "#fff",
            
        }
    }
    return (
        <div className={classes.dropdown_wrapper}>
            <span className={classes.dropdown_head} style={dropdown_head_style}>
                <div style={{marginRight: "1rem", display: "flex", gap: "10px", alignItems: "center"}}>
                    {
                        props.icon ? <div style={{border: "2px solid white", borderRadius: "16px",  overflow: "hidden", display: "flex", justifyContent: "center", alignItems: 'center'}}><img src={props.icon} alt="logo" /></div> : null
                    }
                    
                    {props.selected}</div>
                <div className={classes.dropdown_arrow}></div>
            </span>
            <div className={classes.dropdown_content_wrapper} style={{right: props.rtl ? "20px": ""}}>
                <div className={classes.dropdown_content}>
                    {
                        props.items.map((item) => {
                            return (
                                <div style={{padding: "6px 24px", 
                                            backgroundColor: props.selected === item.name ? "#f0f7fe": "#fff", 
                                            color: props.selected === item.name ? "#157EF8": "#202020"}}
                                     className={classes.dropdown_link} 
                                     onClick={props.onClick}>
                                    {item.name}
                                    {
                                        item.name === props.selected ? <FontAwesomeIcon icon={faCheck} />: null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Dropdown;
