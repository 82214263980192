import React from "react";
import { Route, BrowserRouter as Router, Redirect } from "react-router-dom";
import Home from "./pages/Home/Home";
import "./App.css";
import Inputs from "./pages/inputs";
import Cookies from "js-cookie";
import Dashboard from "./pages/Employees/Employee";
import Presence from "./pages/Presence/Presence";
import Hire from "./pages/Hire/Hire";
import Settings from "./pages/Settings/Settings";
import Help from "./pages/Help/Help";
import PersonalPresence from "./pages/PersonalPresence/PersonalPresence";
import PersonalPayment from "./pages/PersonalPayment/PersonalPayment";
import PersonalSummary from "./pages/PersonalSummary/PersonalSummary";
import PersonalAllowance from "./pages/PersonalAllowance/PersonalAllowance";
import PersonalBonus from "./pages/PersonalBonus/PersonalBonus";
import PersonalDeductions from "./pages/PersonalDeductions/PersonalDeductions";
import PersonalLoans from "./pages/PersonalLoans/PersonalLoans";
import PersonalOvertime from "./pages/PersonalOvertime/PersonalOvertime";
import PersonalEdit from "./pages/PersonalEdit/PersonalEdit";
import JobApplicants from "./pages/JobApplicants/JobApplicants";
import Editjob from "./pages/Editjob/Editjob";

function App() {
  let id = Cookies.get("order_id");
  return (
    <div className="App">
      <Router>
        <Route path="/employees" render={() => <Dashboard />} />
        <Route exact path="/">
          {Cookies.get("owner_id") ? <Redirect to="/employees" /> : <Home />}
        </Route>
        <Route exact path="/inputs" render={() => <Inputs />} />
        <Route path="/presence" render={() => <Presence />} />
        <Route path="/hire" render={() => <Hire />} />
        <Route path="/settings" render={() => <Settings />} />
        <Route path="/help" render={() => <Help />} />
        <Route path="/:id/presence" render={() => <PersonalPresence />} />
        <Route path="/:id/payments" render={() => <PersonalPayment />} />
        <Route path="/:id/summary" render={() => <PersonalSummary />} />
        <Route path="/:id/allowance" render={() => <PersonalAllowance />} />
        <Route path="/:id/bonus" render={() => <PersonalBonus />} />
        <Route path="/:id/deductions" render={() => <PersonalDeductions />} />
        <Route path="/:id/loans" render={() => <PersonalLoans />} />
        <Route path="/:id/overtime" render={() => <PersonalOvertime />} />
        <Route path="/:id/edit" render={() => <PersonalEdit />} />
        <Route path="/:jobid/jobapplicants" render={() => <JobApplicants />} />
        <Route path="/:jobid/editjob" render={() => <Editjob />} />
      </Router>
    </div>
  );
}

export default App;
