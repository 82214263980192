import classes from "./Employee.module.css";
import React from "react";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import Search from "../../assets/icons/search_grey.svg";
import AddEmployee from "../../assets/icons/plus-circle_white.svg";
import Dropdown from "../../compnents/dropdown/Dropdown";
import Button, { createRipple } from "../../compnents/buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import axios from "axios";
import Lottie from "react-lottie";
import loader from "../../compnents/animations/finding.json";
import {
  getstaff,
  getstaffSuccess,
  getstaffFailure,
} from "../../actions/getStaff";
import EmployeeData from "../../compnents/employee_data/EmployeeData";

import AddEmployeeModal from "../../compnents/modal/AddEmployeeModal/AddEmployeeModal";
import AddPaymentModal from "../../compnents/modal/AddPaymentModal/AddPaymentModal";

const Employee = (props) => {
  const [search, setSearch] = React.useState("");
  const [employeeOption, setEmployeeOption] =
    React.useState("Monthly Employees");
  const [modalStatus, setModalStatus] = React.useState(false);

  const staffDetails = useSelector((state) => state.staffResponse);
  const dispatch = useDispatch();
  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [salary, setSalary] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [formStep, setFormStep] = React.useState("one");
  const [tag, setTag] = React.useState("Monthly");
  const [salaryCycle, setSalaryCycle] = React.useState("1");
  const [openCalendar, setOpenCalendar] = React.useState(false);
  const [radio, setRadio] = React.useState("");
  const [startingAmount, setStartingAmount] = React.useState("");

  const [staffPaymentDetails, setStaffPaymentDetails] = React.useState("");

  const [addPaymentModal, setPaymentModal] = React.useState(false);
  const businessid = useSelector((state) => state.businessid.value);

  const handleOpenPaymentModal = (id, name) => {
    setPaymentModal(true);
    setStaffPaymentDetails((prevState) => ({
      ...prevState,
      id: id,
      name: name,
    }));
  };
  const handleClosePaymentModal = () => {
    setPaymentModal(false);
  };

  const handleInput = (e, type) => {
    switch (type) {
      case "name":
        setName(e.target.value);
        break;
      case "mobile":
        setMobile(e.target.value);
        break;
      case "salary":
        setSalary(e.target.value);
        break;
      case "startingAmount":
        setStartingAmount(e.target.value);
    }
  };

  const handleValidation = (type) => {
    let phoneRegx = /^\d[0-9]/;
    let amount = /^\d+$/;
    switch (type) {
      case "name":
        if (name.length <= 3) {
          setError((prevState) => ({
            ...prevState,
            name: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            name: "",
          }));
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            name: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            name: "",
          }));
        }
        break;

      case "mobile":
        if (mobile.length !== 10 || phoneRegx.test(mobile) == false) {
          setError((prevState) => ({
            ...prevState,
            mobile: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            mobile: "",
          }));
          break;
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            mobile: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            mobile: "",
          }));
          break;
        }
      case "salary":
        if (salary === "0" || salary === "" || amount.test(salary) === false) {
          setError((prevState) => ({
            ...prevState,
            salary: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            salary: "",
          }));
          break;
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            salary: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            salary: "",
          }));
          break;
        }
      case "startingAmount":
        if (
          startingAmount === "0" ||
          startingAmount === "" ||
          amount.test(startingAmount) === false
        ) {
          setError((prevState) => ({
            ...prevState,
            amount: "Enter Valid Amount",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            amount: "",
          }));
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            amount: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            amount: "",
          }));
          break;
        }
      default:
        break;
    }
  };
  const handleContinue = (e) => {
    createRipple(e);
    if (formStep === "one") {
      if (error.name === "" || error.mobile === "") {
        setFormStep("two");
      }
    } else if (formStep === "two") {
      setFormStep("three");
    } else if (formStep === "three") {
      if (error.salary === "") {
        setFormStep("four");
      }
    }
  };
  const handleBack = () => {
    if (formStep === "two") {
      setFormStep("one");
    } else if (formStep === "three") {
      setFormStep("two");
    } else if (formStep === "four") {
      setFormStep("three");
    } else if (formStep === "five") {
      setFormStep("four");
    }
  };
  const handleSalaryCycle = (e) => {
    setSalaryCycle(e.target.innerText);
    setOpenCalendar(false);
  };
  const handleCalendar = () => {
    setOpenCalendar(!openCalendar);
  };
  const handleRadio = (val) => {
    setRadio(val);
  };

  let owner_id = Cookies.get("owner_id");
  let salary_type;
  if (employeeOption === "Work Basis") {
    salary_type = "Work_Basis";
  } else {
    salary_type = employeeOption.split(" ")[0];
  }
  const handleAddEmployee = () => {
    console.log(
      owner_id,
      name,
      mobile,
      tag,
      salary,
      salaryCycle,
      radio,
      startingAmount
    );
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/add_staff`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? owner_id : businessid,
        id: owner_id,
        employee_name: name,
        employee_mobile: mobile,
        salary_type: tag,
        salary: salary,
        salary_cycle: salaryCycle,
        amount_status: radio,
        amount: startingAmount,
        api_access: "web",
      },
    };
    axios(settings)
      .then((res) => {
        if (res.data.result == true) {
          setOpenCalendar(false);
          alert(res.data.message);
        } else {
          alert("Something is not right! Try Again");
        }
      })
      .catch((err) => {
        alert("Something Went Wrong !! Try Again");
        console.log(err);
      });
  };

  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_staff`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? owner_id : businessid,
        salary_type: salary_type,
      },
    };
    dispatch(getstaff());
    axios(settings)
      .then((res) => {
        if (res.data.result == true) {
          dispatch(getstaffSuccess(res.data.staff_data[salary_type]));
        } else {
          dispatch(getstaffFailure());
        }
      })
      .catch((err) => {
        dispatch(getstaffFailure());
      });
    return () => {};
  }, []);

  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_staff`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? owner_id : businessid,
        salary_type: salary_type,
      },
    };
    dispatch(getstaff());
    axios(settings)
      .then((res) => {
        console.log(res.data.staff_data[salary_type]);
        if (res.data.result == true) {
          dispatch(getstaffSuccess(res.data.staff_data[salary_type]));
        } else {
          dispatch(getstaffFailure());
        }
      })
      .catch((err) => {
        dispatch(getstaffFailure());
      });
    return () => {};
  }, [employeeOption, businessid]);

  const handleDropdown = (e) => {
    setEmployeeOption(e.target.innerText);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleCloseModal = () => {
    setModalStatus(false);
  };

  const handleOpenModal = (e) => {
    createRipple(e);
    setModalStatus(true);
  };
  const handleTag = (e) => {
    setTag(e);
  };

  let showStaffDetails;
  if (staffDetails.loading == true) {
    showStaffDetails = (
      <div
        style={{
          // position: "absolute",
          // width: "100vw",
          // height: "100vh",
          // backgroundColor: "rgba(0,0,0,0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
          height={200}
          width={200}
          style={{ padding: "0", marginTop: "-50px" }}
        />
      </div>
    );
  } else {
    if (staffDetails.response.length == 0 || staffDetails.response == "") {
      showStaffDetails = <div>Add Employee</div>;
    } else {
      showStaffDetails = staffDetails.response
        .filter(
          (f) => f.employee_name.toLowerCase().includes(search) || search === ""
        )
        .map((staff) => {
          return (
            <EmployeeData
              staff={staff}
              handleOpenPaymentModal={handleOpenPaymentModal}
            />
          );
        });
    }
  }

  return (
    <Boilerplate>
      <div className={classes.stats_container}>
        <div className={classes.stats_wrapper}>
          <div className={classes.stats_numbers}>&#8377; 123500</div>
          <div
            className={classes.stats_description}
            title="PENDING PAYMENTS THIS MONTH"
          >
            PENDING PAYMENTS THIS MONTH
          </div>
        </div>
        <div className={classes.stats_wrapper}>
          <div className={classes.stats_numbers}>&#8377; 423500</div>
          <div
            className={classes.stats_description}
            title="PAYMENTS MADE THIS MONTH"
          >
            PAYMENTS MADE THIS MONTH
          </div>
        </div>
        <div className={classes.stats_wrapper}>
          <div className={classes.stats_numbers}>&#8377; 123500</div>
          <div
            className={classes.stats_description}
            title="TOTAL PENDING PAYMENTS"
          >
            TOTAL PENDING PAYMENTS
          </div>
        </div>
      </div>
      <div className={classes.searchbar_container}>
        <div className={classes.searchbar_left}>
          <div className={classes.searchbar_wrapper}>
            <img src={Search} alt="Search" />
            <input
              type="text"
              className={classes.searchbar}
              value={search}
              onChange={(e) => handleChange(e)}
              placeholder="Search Employee"
            />
          </div>
          <Dropdown
            items={[
              { id: "1", name: "Monthly Employees" },
              { id: "2", name: "Weekly Employees" },
              { id: "3", name: "Hourly Employees" },
              { id: "4", name: "Daily Employee" },
              { id: "5", name: "Work Basis" },
            ]}
            // padding={window.innerWidth >= 1400 ? "15px" : "10px"}

            white
            selected={employeeOption}
            onClick={(e) => handleDropdown(e)}
          />
        </div>
        <Button
          contained
          large
          onClick={(e) => handleOpenModal(e)}
          style={{ height: "48px" }}
        >
          <img src={AddEmployee} alt="plus" />
          <div
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordWrap: "normal",
            }}
          >
            Add Employee
          </div>
        </Button>
      </div>
      <div className={classes.data_container}>{showStaffDetails}</div>
      <AddEmployeeModal
        modalStatus={modalStatus}
        onRequestClose={handleCloseModal}
        handleCloseModal={handleCloseModal}
        formStep={formStep}
        name={name}
        handleInput={handleInput}
        handleValidation={handleValidation}
        handleTag={handleTag}
        error={error}
        success={success}
        mobile={mobile}
        handleContinue={handleContinue}
        handleBack={handleBack}
        tag={tag}
        salary={salary}
        handleCalendar={handleCalendar}
        salaryCycle={salaryCycle}
        handleSalaryCycle={handleSalaryCycle}
        openCalendar={openCalendar}
        radio={radio}
        handleRadio={handleRadio}
        handleAddEmployee={handleAddEmployee}
        startingAmount={startingAmount}
      />
      <AddPaymentModal
        addPaymentModal={addPaymentModal}
        handleOpenPaymentModal={handleOpenPaymentModal}
        handleClosePaymentModal={handleClosePaymentModal}
        name={staffPaymentDetails.name}
        id={staffPaymentDetails.id}
      />
    </Boilerplate>
  );
};

export default Employee;
