import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  error: false,
  response: "",
};

const getMonthlyAttendanceSlice = createSlice({
  name: "getMonthlyAttendance",
  initialState,
  reducers: {
    getMonthlyAttendance: (state) => {
      state.loading = true;
    },
    getMonthlyAttendanceSuccess: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = false;
    },
    getMonthlyAttendanceFailure: (state) => {
      state.loading = false;
      state.error = "Something went wrong";
      state.response = "";
    },
  },
});
export default getMonthlyAttendanceSlice.reducer;

export const {
  getMonthlyAttendance,
  getMonthlyAttendanceSuccess,
  getMonthlyAttendanceFailure,
} = getMonthlyAttendanceSlice.actions;

export const getMonthlyAttendanceSelector = (state) => state.loading;

// export const fetchgetstaff = (owner_id, salary_type) => async (dispatch) => {
//   var settings = {
//     url: `http://salarybook.co.in/public/api/get_staff`,
//     method: "POST",
//     data: {
//       owner_id: owner_id,
//       salary_type: salary_type,
//     },
//   };
//   dispatch(getstaff());
//   axios(settings).then((res) => {
//     if (res.data.owner == "yes") {
//       dispatch(getstaffSuccess(res.data));
//     } else {
//       dispatch(getstaffFailure());
//     }
//   });
// };
