import styled from 'styled-components'

const Tag = styled.button`
    padding: 9px 40px;
    background-color: #fff;
    border: ${(props) => props.active ? "2px solid #157EF8" : "2px solid #fff"};
    color: ${(props) => props.active ? "#157EF8" : "#262626"};
    border-radius: 12px;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    box-shadow: ${(props) => props.active ? "none" : "0 0 4px 2px rgba(0,0,0,0.06)"};
    cursor: pointer;
`

export default Tag
