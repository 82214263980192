import React from 'react'
import Modal from '../Modal'
import classes from "./latefeeModal.module.css"
import Cross from "../../../assets/icons/Cross.svg";
import InputField from "../../inputs/Input";
import Button from '../../buttons/Button';
import ButtonError from '../../buttons/ButtonError';
import axios from 'axios';
import Delete from "../../../assets/icons/Delete_white.svg";
import Edit from "../../../assets/icons/edit_red.svg";
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getallstaff, getallstaffFailure, getallstaffSuccess } from '../../../actions/getAllStaff';

const LatefeeModal = (props) => {
    const businessid = useSelector((state) => state.businessid.value);
    const {date} = props;
    const dispatch = useDispatch()
    const [time, setTime] = React.useState("");
    const handleTime = (e) => {
        setTime(e.target.value)
    }
    const handleLateFees = () => {
        let fine = 100;
        let hours = parseInt(time.split(":")[0])
        let minutes = parseInt(time.split(":")[1])
        fine = (100 * hours)+(100 * (minutes/60)).toFixed(2);

        var settings = {
            url: `${process.env.REACT_APP_API_URL}/update_staff_attendance`,
            method: "POST",
            data: {
              staff_id: props.staff_id,
              attendance: "Present",
              date: props.date,
              late_fine: hours,
              status: "approved",
            },
          };
          axios(settings).then((res) => {
              if (res.data.result === true) {
                  alert(res.data.message);
              } else {
                  console.log(res);
              }
          }).catch((err) => {
              alert(err)
          })
          var settings = {
            url: `${process.env.REACT_APP_API_URL}/get_staff_attendance`,
            method: "POST",
            data: {
              owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid,
              date: date,
            },
          };
          dispatch(getallstaff());
          axios(settings)
            .then((res) => {
              if (res.data.result == true) {
                dispatch(getallstaffSuccess(res.data));
              } else {
                dispatch(getallstaffFailure());
              }
            })
            .catch((err) => {
              dispatch(getallstaffFailure());
            });
    }
    const handleRemoveLateFees = () => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/update_staff_attendance`,
            method: "POST",
            data: {
              staff_id: props.staff_id,
              attendance: "Present",
              date: props.date,
              late_fine: 0,
              status: "approved",
            },
          };
          axios(settings).then((res) => {
              if (res.data.result === true) {
                  alert(res.data.message);
                  
              } else {
                  console.log(res);
              }
          }).catch((err) => {
              alert(err)
          })
          var settings = {
            url: `${process.env.REACT_APP_API_URL}/get_staff_attendance`,
            method: "POST",
            data: {
              owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid,
              date: date,
            },
          };
          dispatch(getallstaff());
          axios(settings)
            .then((res) => {
              if (res.data.result == true) {
                dispatch(getallstaffSuccess(res.data));
              } else {
                dispatch(getallstaffFailure());
              }
            })
            .catch((err) => {
              dispatch(getallstaffFailure());
            });
    }
    
    return (
        <Modal modalStatus={props.latefeeModalStatus} onRequestClose={props.handleLateFeeModalClose}>
            <div>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>{props.staff_latefee === 0 ? "Add" : "Edit"} Late fee</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleLateFeeModalClose}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>For {props.staffName}</div>
                {
                    props.staff_latefee === 0 ? <div className={classes.description}>
                        
                        Add number of hours to be deducted from total
                    shift hours.</div> : <div style={{margin: "20px 0"}}></div>
                }
                
                <InputField type="time" value={time} onChange={handleTime} width="100%"/>
            </div>
            {
                props.staff_latefee === 0 ? (<Button contained large onClick={handleLateFees}>Add Late Fee</Button>) : (
                    <div className={classes.buttons_row}>
                        <ButtonError contained large width="45%" onClick={handleRemoveLateFees}>
                            <img src={Delete} alt="Remove" />
                            <span>Remove</span>
                        </ButtonError>
                        <ButtonError large width="45%" onClick={handleLateFees}>
                            <img src={Edit} alt="Edit" />
                            <span>Edit</span>
                        </ButtonError>
                    </div>
                )
            }
            
        </Modal>
    )
}

export default LatefeeModal
