import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  error: false,
  response: "",
};

const getstaffdeductionSlice = createSlice({
  name: "getstaffdeduction",
  initialState,
  reducers: {
    getstaffdeduction: (state) => {
      state.loading = true;
    },
    getstaffdeductionSuccess: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = false;
    },
    getstaffdeductionFailure: (state) => {
      state.loading = false;
      state.error = "Something went wrong";
      state.response = "";
    },
  },
});
export default getstaffdeductionSlice.reducer;

export const {
  getstaffdeduction,
  getstaffdeductionSuccess,
  getstaffdeductionFailure,
} = getstaffdeductionSlice.actions;

export const getstaffdeductionSelector = (state) => state.loading;
