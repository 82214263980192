import React from 'react'
import Modal from '../Modal'
import classes from "./AddManagerModal.module.css";
import Cross from "../../../assets/icons/Cross.svg";
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import Button from '../../buttons/Button';

const AddManagerModal = (props) => {
    const businessid = useSelector((state) => state.businessid.value);
    const [staffList, setStaffList] = React.useState("");
    React.useEffect(() => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/get_all_staff`,
            method: "POST",
            data: {
                owner_id: businessid === "0" ? Cookies.get("owner_id") : businessid
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === true) {
                setStaffList(res.data.staff_data)
            } else {
                if (res.data.message !== "no data found") {
                    alert("Something went wrong while fetching staff!!")
                }
            }
        }).catch((err) => {
            alert("Something went wrong !! Try again after sometimes")
        })
    }, [])

    const handleMakeManager = (id) => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/edit_staff`,
            method: "POST",
            data: {
                id: id,
                type: "Manager"
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === true) {
                alert(res.data.message);
                window.location.reload();
            } else {
                alert("Something went wrong while making manager!!")
            }
        }).catch((err) => {
            alert("Something went wrong !! Try again after sometimes")
        })
    }

    let staff_list;
    if (staffList.length === 0) {
        staff_list = <div>No Staff Available</div>
    } else {
        staff_list = staffList.map((staff) => {
            return (
                <div style={{display: "flex", gap: "20px", justifyContent: "space-between", alignItems: "center", margin: "20px 0"}}>
                    <div>{staff.employee_name}</div>
                    <Button contained onClick={() => handleMakeManager(staff.id)}>Make Manager</Button>
                </div>
            )
        })
    }
    return (
        <Modal
            modalStatus={props.managerModalStatus}
            onRequestClose={props.handleCloseManagerModal}
        >
        <div>
            <div className={classes.heading_wrapper}>
            <div className={classes.modal_heading}>Add Manager</div>
            <img
                src={Cross}
                alt="Close"
                onClick={props.handleCloseManagerModal}
                style={{ cursor: "pointer" }}
            />
            </div>
            <div className={classes.secondary_heading}>
            For Managing Salarybook App
            </div>
            <div className={classes.list_wrapper}>
                {staff_list}
            </div>
        </div>
        </Modal>
    )
}

export default AddManagerModal
