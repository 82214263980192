import React, { createRef, useRef } from "react";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import classes from "./Presence.module.css";
import Download from "../../assets/icons/Download_blue.svg";
import Settings from "../../assets/icons/Internal_Settings_blue.svg";
import LeftArrow from "../../assets/icons/Left_blue.svg";
import RightArrow from "../../assets/icons/Right_blue.svg";
import CalendarIcon from "../../assets/icons/calendar_blue.svg";
import Search from "../../assets/icons/search_grey.svg";
import AddEmployee from "../../assets/icons/plus-circle_white.svg";
import loader from "../../compnents/animations/finding.json";
import { useDispatch, useSelector } from "react-redux";
import {
  getallstaff,
  getallstaffFailure,
  getallstaffSuccess,
} from "../../actions/getAllStaff";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import Dropdown from "../../compnents/dropdown/Dropdown";
import Button from "../../compnents/buttons/Button";
import Lottie from "react-lottie";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import AttendanceforEmployee from "../../compnents/attendanceforemployee/AttendanceforEmployee";
import LatefeeModal from "../../compnents/modal/Latefee/LatefeeModal";
import OvertimeModal from "../../compnents/modal/Overtime/OvertimeModal";
import Switch from "../../compnents/switch/Switch";
import NoteModal from "../../compnents/modal/Note/NoteModal";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { fonts } from "../../compnents/pdffonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Presence = (props) => {
  const owner_id = Cookies.get("owner_id");
  const staffData = useSelector((state) => state.allstaffResponse);
  const [search, setSearch] = React.useState("");
  const [employeeOption, setEmployeeOption] =
    React.useState("Monthly Employees");
  const [showCalendar, setShowCalendar] = React.useState(false);
  const [calendar, setCalendar] = React.useState(new Date());
  const [latefeeModalStatus, setLateFeeModalStatus] = React.useState(false);
  const [overtimeModalStatus, setOvertimeModalStatus] = React.useState(false);
  const [noteModalStatus, setNoteModalStatus] = React.useState(false);

  const [showinternalSettings, setShowInternalSettings] = React.useState(false);
  const [defaultPresent, setDefaultPresent] = React.useState(true);
  const [smsAlerts, setSmsAlerts] = React.useState(false);
  const [staffForModal, setStaffForModal] = React.useState("");
  const [time, setTime] = React.useState(
    moment(calendar).format("DD MMMM, YYYY")
  );
  const [pdf, setPdf] = React.useState("");

  const businessid = useSelector((state) => state.businessid.value);

  const handleSwitch = (type) => {
    switch (type) {
      case "present":
        setDefaultPresent(!defaultPresent);
        break;
      case "sms":
        setSmsAlerts(!smsAlerts);
        break;
    }
  };
  const handleNoteModalClose = () => {
    setNoteModalStatus(false);
  };

  const handleLateFeeModalClose = () => {
    setLateFeeModalStatus(false);
  };
  const handleOvertimeModalClose = () => {
    setOvertimeModalStatus(false);
  };
  const handleNoteModalOpen = (id, name, lateFees, presence) => {
    setNoteModalStatus(true);
    setStaffForModal((prevState) => ({
      ...prevState,
      id: id,
      name: name,
      lateFees: lateFees,
      presence: presence,
    }));
  };

  const handleLateFeeModalOpen = (id, name, lateFees) => {
    setLateFeeModalStatus(true);
    setStaffForModal((prevState) => ({
      ...prevState,
      id: id,
      name: name,
      lateFees: lateFees,
    }));
  };
  const handleOvertimeModalOpen = (id, name, overtime) => {
    setOvertimeModalStatus(true);
    setStaffForModal((prevState) => ({
      ...prevState,
      id: id,
      name: name,
      overtime: overtime,
    }));
  };

  const dispatch = useDispatch();
  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_staff_attendance`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? owner_id : businessid,
        date: time,
      },
    };
    dispatch(getallstaff());
    axios(settings)
      .then((res) => {
        if (res.data.result == true) {
          dispatch(getallstaffSuccess(res.data));
        } else {
          dispatch(getallstaffFailure());
        }
      })
      .catch((err) => {
        dispatch(getallstaffFailure());
      });
  }, []);
  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_staff_attendance`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? owner_id : businessid,
        date: time,
      },
    };
    dispatch(getallstaff());
    axios(settings)
      .then((res) => {
        if (res.data.result == true) {
          dispatch(getallstaffSuccess(res.data));
        } else {
          dispatch(getallstaffFailure());
        }
      })
      .catch((err) => {
        dispatch(getallstaffFailure());
      });
  }, [employeeOption, calendar, time, businessid]);

  let salary_type;
  if (employeeOption === "Work Basis") {
    salary_type = "Work_Basis";
  } else {
    salary_type = employeeOption.split(" ")[0];
  }

  const handleDropdown = (e) => {
    setEmployeeOption(e.target.innerText);
  };
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleCalendar = (e) => {
    setCalendar(e);
    setTime(moment(e).format("DD MMMM, YYYY"));
    setShowCalendar(false);
  };

  let showStaffDetails;
  if (staffData.loading == true) {
    showStaffDetails = (
      <div
        style={{
          // position: "absolute",
          // width: "100vw",
          // height: "100vh",
          // backgroundColor: "rgba(0,0,0,0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
          height={200}
          width={200}
          style={{ padding: "0" }}
        />
      </div>
    );
  } else {
    if (
      staffData.response.staff_list === undefined ||
      staffData.response.staff_list === ""
    ) {
      showStaffDetails = <div>Add Employee</div>;
    } else {
      showStaffDetails = staffData.response.staff_list[salary_type]
        .filter(
          (f) => f.employee_name.toLowerCase().includes(search) || search === ""
        )
        .map((staff) => {
          return (
            <AttendanceforEmployee
              staff={staff}
              handleLateFeeModalOpen={handleLateFeeModalOpen}
              handleOvertimeModalOpen={handleOvertimeModalOpen}
              handleNoteModalOpen={handleNoteModalOpen}
              date={time}
            />
          );
        });
    }
  }
  const handlePerviousDay = () => {
    let currentday = moment(time).format("DD MMMM, YYYY");

    let previousday = moment(currentday).subtract(1, "days");
    previousday = moment(previousday).format("DD MMMM, YYYY");
    setTime(previousday);
  };
  const handleNextDay = () => {
    let currentday = moment(time).format("DD MMMM, YYYY");
    if (currentday < moment().format("DD MMMM, YYYY")) {
      let nextday = moment(currentday).add(1, "days");
      nextday = moment(nextday).format("DD MMMM, YYYY");
      setTime(nextday);
    }
  };
  const generatePDF = () => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/attendance_report`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? owner_id : businessid,
      },
    };
    axios(settings)
      .then((res) => {
        if (res.data.result === false) {
          alert("Something went wrong ||");
        } else {
          console.log(res.data.staff_report);
          function buildTableBody(data, columns) {
            var body = [];
            body.push(columns);
            data.forEach(function (row) {
              var dataRow = [];

              columns.forEach(function (column) {
                dataRow.push(row[column].toString());
              });

              body.push(dataRow);
            });
            return body;
          }
          function table(data, columns) {
            return {
              table: {
                headerRows: 1,
                widths: [100, 100, 60, 70, 70, 70, 70, 70],
                body: buildTableBody(data, columns),
              },
            };
          }
          var dd = {
            pageSize: "A4",
            pageOrientation: "landscape",
            pageMargins: [40, 60, 40, 60],
            content: [
              {
                columns: [
                  {
                    text: "Attendance Report",
                    style: "header",
                    width: "70%",
                  },
                ],
              },

              table(res.data.staff_report, [
                "staff_name",
                "month_range",
                "present",
                "absent",
                "paid_holiday",
                "half_day",
                "late_fine",
                "overtime",
              ]),
            ],
            styles: {
              header: {
                fontSize: 25,
                margin: [250, 0, 50, 20],
                font: "Roboto",
                bold: true,
                italic: false,
                alignment: "right",
              },
              table: {
                widths: [30, 30, 30, 30, 30, 30, 30, 30],
                font: "Roboto",
                bold: false,
                italic: false,
              },
              content: {
                margin: [20, 0, 0, 20],
                font: "Roboto",
                bold: false,
                italic: false,
              },
            },
          };

          pdfMake.createPdf(dd, null, fonts).download("report.pdf");
        }
      })
      .catch((err) => {
        alert("something went wrong !!!");
      });
  };
  return (
    <Boilerplate>
      {/* <div ref={elementRef} style={{ width: "100%", zIndex: "-10" }} /> */}

      <div className={classes.stats_container}>
        <div className={classes.stats_firstrow}>
          {/* <ReactToPdf targetRef={elementRef} filename="Attendance_report.pdf">
            {({ toPdf }) => (
              <div className={classes.download_button} onClick={toPdf}>
                <img src={Download} alt="Download" />
              </div>
            )}
          </ReactToPdf> */}
          <div className={classes.download_button} onClick={generatePDF}>
            <img src={Download} alt="Download" />
          </div>
          <div className={classes.dates_settings}>
            <img
              src={LeftArrow}
              alt="Previous"
              style={{ cursor: "pointer" }}
              onClick={handlePerviousDay}
            />
            <div className={classes.date}>{time}</div>
            <img
              src={RightArrow}
              alt="Next"
              style={{ cursor: "pointer" }}
              onClick={handleNextDay}
            />
            <div
              className={classes.download_button}
              onClick={() => setShowCalendar(!showCalendar)}
            >
              <img src={CalendarIcon} alt="Calendar" />
            </div>
            {showCalendar ? (
              <Calendar
                value={calendar}
                onChange={(e) => handleCalendar(e)}
                className={classes.calendar}
                style={{ display: "none" }}
                maxDate={new Date()}
              />
            ) : null}
          </div>
          <div
            className={classes.download_button}
            onClick={() => setShowInternalSettings(!showinternalSettings)}
          >
            <img src={Settings} alt="Settings" />
          </div>
          {showinternalSettings ? (
            <div className={classes.internal_settings}>
              <div className={classes.settings_row}>
                <div className={classes.text}>Mark Present by default</div>
                <Switch
                  checked={defaultPresent}
                  onChange={() => handleSwitch("present")}
                />
              </div>
              <div className={classes.settings_row}>
                <div className={classes.text}>
                  Send sms alerts to the employees
                </div>
                <Switch
                  checked={smsAlerts}
                  onChange={() => handleSwitch("sms")}
                />
              </div>
              <div className={classes.settings_row}>Edit weekly holidays</div>
            </div>
          ) : null}
        </div>
        <div className={classes.stats_secondrow}>
          <div className={classes.tabs}>
            <div className={classes.tabs_numbers}>
              {staffData.response.present}
            </div>
            <div className={classes.tabs_description}>Present</div>
          </div>
          <div className={classes.tabs}>
            <div className={classes.tabs_numbers}>
              {staffData.response.absent}
            </div>
            <div className={classes.tabs_description}>Absent</div>
          </div>
          <div className={classes.tabs}>
            <div className={classes.tabs_numbers}>
              {staffData.response.half_day}
            </div>
            <div className={classes.tabs_description}>Half Day</div>
          </div>
          <div className={classes.tabs}>
            <div className={classes.tabs_numbers}>
              {staffData.response.overtime}
            </div>
            <div className={classes.tabs_description}>Overtime</div>
          </div>
          <div className={classes.tabs}>
            <div className={classes.tabs_numbers}>
              {staffData.response.late_fine}
            </div>
            <div className={classes.tabs_description}>Late Hours</div>
          </div>
          <div className={classes.tabs}>
            <div className={classes.tabs_numbers}>
              {staffData.response.paid_holiday}
            </div>
            <div className={classes.tabs_description}>Paid Leave</div>
          </div>
        </div>
      </div>
      <div className={classes.searchbar_container}>
        <div className={classes.searchbar_left}>
          <div className={classes.searchbar_wrapper}>
            <img src={Search} alt="Search" />
            <input
              type="text"
              className={classes.searchbar}
              value={search}
              onChange={(e) => handleChange(e)}
              placeholder="Search Employee"
            />
          </div>
          <Dropdown
            items={[
              { id: "1", name: "Monthly Employees" },
              { id: "2", name: "Weekly Employees" },
              { id: "3", name: "Hourly Employees" },
              { id: "4", name: "Daily Employee" },
              { id: "5", name: "Work Basis" },
            ]}
            // padding={window.innerWidth >= 1400 ? "15px" : "10px"}
            height="48px"
            white
            fontsize="14px"
            selected={employeeOption}
            onClick={(e) => handleDropdown(e)}
          />
        </div>
        <Button
          contained
          large
          // onClick={(e) => (e)}
          style={{ height: "48px" }}
        >
          <img src={AddEmployee} alt="plus" />
          Set Alarm
        </Button>
      </div>
      <div className={classes.data_container}>{showStaffDetails}</div>
      <LatefeeModal
        handleLateFeeModalClose={handleLateFeeModalClose}
        handleLateFeeModalOpen={handleLateFeeModalOpen}
        latefeeModalStatus={latefeeModalStatus}
        staffName={staffForModal.name}
        staff_id={staffForModal.id}
        staff_latefee={staffForModal.lateFees}
        date={time}
      />
      <OvertimeModal
        handleOvertimeModalClose={handleOvertimeModalClose}
        handleOvertimeModalOpen={handleOvertimeModalOpen}
        overtimeModalStatus={overtimeModalStatus}
        staffName={staffForModal.name}
        staff_id={staffForModal.id}
        staff_overtime={staffForModal.overtime}
        date={time}
      />
      <NoteModal
        handleNoteModalClose={handleNoteModalClose}
        handleNoteModalOpen={handleNoteModalOpen}
        noteModalStatus={noteModalStatus}
        staffName={staffForModal.name}
        staff_id={staffForModal.id}
        staff_overtime={staffForModal.overtime}
        staff_presence={staffForModal.presence}
        date={time}
      />
    </Boilerplate>
  );
};

export default Presence;
