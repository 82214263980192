import React from 'react'
import classes from "./AttendanceforEmployee.module.css"
import Notes from "../../assets/icons/Note_blue.svg";
import Tag from '../tag/Tag';
import TagError from '../tag/TagError';
import moment from 'moment';
import axios from 'axios';
import LatefeeModal from '../modal/Latefee/LatefeeModal';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { getallstaff, getallstaffFailure, getallstaffSuccess } from '../../actions/getAllStaff';

const AttendanceforEmployee = (props) => {
    const dispatch = useDispatch();
    const businessid = useSelector((state) => state.businessid.value)
    const {staff, handleLateFeeModalOpen, handleOvertimeModalOpen, handleNoteModalOpen, date} = props;
    const handleAttendance = (e,type ) => {
        console.log(e.target.innerText);
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/update_staff_attendance`,
            method: "POST",
            data: {
              staff_id: staff.id,
              attendance: type,
              date: moment(new Date()).format("DD MMMM, YYYY"),
              status: "approved",
            },
          };
          axios(settings).then((res) => {
              if (res.data.result === true) {
                  alert(res.data.message);
                //   window.location.reload();
              } else {
                  console.log(res);
              }
          }).catch((err) => {
              alert(err)
          })
          var settings = {
            url: `${process.env.REACT_APP_API_URL}/get_staff_attendance`,
            method: "POST",
            data: {
              owner_id: businessid === "0" ? Cookies.get("owner_id") : businessid ,
              date: date,
            },
          };
          dispatch(getallstaff());
          axios(settings)
            .then((res) => {
              if (res.data.result == true) {
                dispatch(getallstaffSuccess(res.data));
              } else {
                dispatch(getallstaffFailure());
              }
            })
            .catch((err) => {
              dispatch(getallstaffFailure());
            });
        
    }
    return (
        
        <div className={classes.data_wrapper}>
            <div className={classes.left_row}>
                <div className={classes.name}>{staff.employee_name}</div>
                <div className={classes.time}>{staff.total_time}</div>
                <div className={classes.comment}>
                    <div className={classes.icon_wrapper} onClick={() => handleNoteModalOpen(staff.id, staff.employee_name, staff.late_fine, staff.note)}>
                        <img src={Notes} alt="Notes" />
                    </div>
                    <span>{staff.note}</span>
                </div>
            </div>
            <div className={classes.attendance_manager}>
                <div className={classes.row}>
                    <Tag style={{width: "150px", padding: "10px", fontWeight: "500", letterSpacing: "0.5px"}} active={staff.attendance === "Present" } onClick={(e) => {handleAttendance(e, "Present")}}>Present</Tag>
                    <TagError style={{width: "150px", padding: "10px", fontWeight: "500", letterSpacing: "0.5px"}} active={staff.attendance === "Absent" } onClick={(e) => {handleAttendance(e, "Absent")}}>Absent</TagError>
                    <Tag style={{width: "150px", padding: "10px", fontWeight: "500", letterSpacing: "0.5px"}} active={staff.attendance === "Half Day" } onClick={(e) => {handleAttendance(e, "Half Day")}}>Half Day</Tag>
                </div>
                <div className={classes.row}>
                    <TagError style={{width: "150px", padding: "10px", fontWeight: "500", letterSpacing: "0.5px"}} active={staff.late_fine !== 0 } onClick={() => handleLateFeeModalOpen(staff.id, staff.employee_name, staff.late_fine)}>Late Fee</TagError>
                    <Tag style={{width: "150px", padding: "10px", fontWeight: "500", letterSpacing: "0.5px"}} active={staff.overtime !== 0} onClick={() => handleOvertimeModalOpen(staff.id, staff.employee_name, staff.overtime)}>Overtime</Tag>
                    <Tag style={{width: "150px", padding: "10px", fontWeight: "500", letterSpacing: "0.5px"}} active={staff.attendance === "Paid Holiday" } onClick={(e) => {handleAttendance(e, "Paid Holiday")}}>Paid Leave</Tag>
                </div> 
            </div>
        </div>
    )
}

export default AttendanceforEmployee
