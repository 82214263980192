import React from 'react';
import classes from "../../pages/PersonalPresence/PersonalPresence.module.css";
import Tag from "../../compnents/tag/Tag";
import TagError from "../../compnents/tag/TagError";
import axios from 'axios';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import Notes from "../../assets/icons/Note_blue.svg";
import { getMonthlyAttendance, getMonthlyAttendanceFailure, getMonthlyAttendanceSuccess } from '../../actions/getMonthlyAttendence';

const IndividualMonthlyAttendance = (props) => {
    const {entry, id, name,  handleLateFeeModalOpen, handleOvertimeModalOpen, handleNoteModalOpen} = props;
    
    const dispatch = useDispatch();
    const handleAttendance = (e,type ) => {
        // console.log(e.target.innerText);
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/update_staff_attendance`,
            method: "POST",
            data: {
              staff_id: id,
              attendance: type,
              date: entry.date_full,
              status: entry.status,
            },
          };
          axios(settings).then((res) => {
              if (res.data.result === true) {
                  alert(res.data.message);
                //   window.location.reload();
              } else {
                  console.log(res);
              }
          }).catch((err) => {
              alert(err)
          })
        var settings = {
          url: `${process.env.REACT_APP_API_URL}/get_monthly_attendance`,
          method: "POST",
          data: {
            staff_id: id,
            month: moment(new Date()).format("MM"),
            year: moment(new Date()).format("YYYY"),
          },
        };
        dispatch(getMonthlyAttendance());
        axios(settings)
          .then((res) => {
            if (res.data.result == true) {
              dispatch(getMonthlyAttendanceSuccess(res.data));
            } else {
              dispatch(getMonthlyAttendanceFailure());
            }
          })
          .catch((err) => {
            dispatch(getMonthlyAttendanceFailure());
          });
        }
        
    return (
        <div className={classes.details_wrapper}>
            <div className={classes.left_detail}>
              <div className={classes.date_detail}>
              {entry.date_full}
              </div>
              <div className={classes.comment}>
                <div className={classes.icon_wrapper} onClick={() => handleNoteModalOpen(id, name, entry.note)}>
                    <img src={Notes} alt="Notes" />
                </div>
                <span>{entry.note}</span>
              </div>
              
            </div>
            <div className={classes.right_detail}>
              <div className={classes.row}>
                <Tag
                  style={{
                    width: "150px",
                    padding: "10px",
                    fontWeight: "500",
                    letterSpacing: "0.5px",
                  }}
                  active={entry.attendance === "Present"}
                  onClick={(e) => {
                    handleAttendance(e, "Present");
                  }}
                >
                  Present
                </Tag>
                <TagError
                  style={{
                    width: "150px",
                    padding: "10px",
                    fontWeight: "500",
                    letterSpacing: "0.5px",
                  }}
                  active={entry.attendance === "Absent"}
                  onClick={(e) => {
                    handleAttendance(e, "Absent");
                  }}
                >
                  Absent
                </TagError>
                <Tag
                  style={{
                    width: "150px",
                    padding: "10px",
                    fontWeight: "500",
                    letterSpacing: "0.5px",
                  }}
                  active={entry.attendance === "Half Day"}
                  onClick={(e) => {
                    handleAttendance(e, "Half Day");
                  }}
                >
                  Half Day
                </Tag>
              </div>
              <div className={classes.row}>
                <TagError
                  style={{
                    width: "150px",
                    padding: "10px",
                    fontWeight: "500",
                    letterSpacing: "0.5px",
                  }}
                  active={entry.late_fine !== 0}
                  onClick={() =>
                    handleLateFeeModalOpen(
                      id,
                      name,
                      entry.late_fine
                    )
                  }
                >
                  Late Fee
                </TagError>
                <Tag
                  style={{
                    width: "150px",
                    padding: "10px",
                    fontWeight: "500",
                    letterSpacing: "0.5px",
                  }}
                  active={entry.overtime !== 0}
                  onClick={() =>
                    handleOvertimeModalOpen(
                      id,
                      name,
                      entry.overtime,
                      entry.date_full
                    )
                  }
                >
                  Overtime
                </Tag>
                <Tag
                  style={{
                    width: "150px",
                    padding: "10px",
                    fontWeight: "500",
                    letterSpacing: "0.5px",
                  }}
                  active={entry.attendance === "Paid Holiday"}
                  onClick={(e) => {
                    handleAttendance(e, "Paid Holiday");
                  }}
                >
                  Paid Leave
                </Tag>
              </div>
            </div>
          </div>
    )
}

export default IndividualMonthlyAttendance
