import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import classes from "./TelInput.module.css"


const TelInput = (props) => {
    
    
    let dropdowncontentstyle;
    let dropdown_head_style;
    if(props.large) {
        dropdown_head_style = {
            padding: "13px 10px",
            borderRadius: "18px"
        }
    } else if(props.small) {
        dropdown_head_style = {
            padding: "12px 20px",
            borderRadius: "18px"
        }
    } else {
        dropdown_head_style = {
            padding: "16px",
            borderRadius: "18px"
            
        }
    }
    if(props.drop) {
        dropdowncontentstyle= {
            opacity: "1",
            visibility: "visible",
            transition: "all 0.4s ease-in-out"   
        }
    } else {
        dropdowncontentstyle= {
            marginTop: "7px",
            display: "block",
            visibility: "hidden",
            opacity: "0",
            position: "absolute",
            boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
            minWidth: "150px",
            boxSizing: "border-box",
            border: "5px solid #fff",
            borderRadius: "5px",
            backgroundColor: "#fff"
        }
    }
    return (
        <div className={classes.dropdown_wrapper}>
            <span className={classes.dropdown_head} style={dropdown_head_style}>
                <img src={props.flagImg} alt="country flag" width="40px" style={{borderRadius: "5px"}}/>
                <div style={{fontSize: "14px",margin: "0 10px", display: "flex", gap: "10px", alignItems: "center"}}>
                    {props.selected}
                </div>
                <div className={classes.dropdown_arrow}></div>
            </span>
            <div className={classes.dropdown_content_wrapper}>
                <div className={classes.dropdown_content}>
                    <input className={classes.search_input} type="text" value={props.search} onChange={props.onSearch }/>
                    {
                        props.items.filter(f => f.name.toLowerCase().includes(props.search) || props.search === '').map((item) => {
                            return (
                                <div style={{padding: "6px 24px", 
                                            backgroundColor: props.selected === item.dial_code ? "#f0f7fe": "#fff", 
                                            color: props.selected === item.dial_code ? "#157EF8": "#202020"}}
                                     className={classes.dropdown_link} 
                                     onClick={props.onClick} value={item.value} key={item.id}>
                                    {item.name}
                                    {
                                        item.dial_code === props.selected ? <FontAwesomeIcon icon={faCheck} style={{width: "20px"}}/>: null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
        
    )
}

export default TelInput
