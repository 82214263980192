import React from 'react'
import Modal from '../Modal'
import classes from "./EditPersonalDetailsModal.module.css";
import Cross from "../../../assets/icons/Cross.svg"
import Button from '../../buttons/Button';
import Switch from "../../../compnents/switch/Switch";
import InputField from '../../inputs/Input';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';

const EditPersonalDetailsModal = (props) => {
    const { details, name, phone, email, status, error, success,handleBlur, handleChange, handleSwitch, personalDetailsModal, handleOpenPersonalDetailsModal, handleClosePersonalDetailsModal} = props;
    const businessid = useSelector((state) => state.businessid.value);

    let senddetails;
    if (name === "" && phone === "") {
        senddetails={
            id: details.id,
            owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid,
            status: status
        }
    } else if (name === "" && phone !== "") {
        senddetails = {
            id: details.id,
            owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid,
            employee_phone: phone,
            status: status
        }
    } else if(name !== "" && phone === "") {
        senddetails = {
            id: details.id,
            owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid,
            employee_name: name,
            status: status
        }
    } else if (name !== "" && phone !== "") {
        senddetails= {
            id: details.id,
            owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid,
            employee_name: name,
            employee_phone: phone,
            status: status
        }
    }
    const handleSavepersonalDetails = () => {
        var settings = {
          url: `${process.env.REACT_APP_API_URL}/edit_staff`,
          method: "POST",
          data: senddetails
        };
        axios(settings)
          .then((res) => {
            if (res.data.result === true) {
              alert("Update Sucessfully");
            } else {
                console.log(res);
              alert("Something is required");
            }
          })
          .catch((err) => {
            alert("Something went wrong");
          });
      };
    return (
        <Modal modalStatus={personalDetailsModal} onRequestClose={handleClosePersonalDetailsModal}>
            <div>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Edit Personal Details</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={handleClosePersonalDetailsModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>For {name}</div>
                <div className={classes.row}>
                    <div className={classes.text}>Employee Status</div>
                    <Switch checked={status} onChange={handleSwitch} />
                </div>
                <InputField
                    type="text"
                    label="Employee Name"
                    placeholder="Employee Name"
                    id="name"
                    onChange={(e) => handleChange(e, "name")}
                    onBlur={() => handleBlur("name")}
                    value={name}
                    error={error.name}
                    success={success.name}
                    width="100%"
                    margin="10px 0"
                />
                <InputField
                    type="text"
                    label="Email"
                    placeholder="Email"
                    id="email"
                    onChange={(e) => handleChange(e, "email")}
                    onBlur={() => handleBlur("email")}
                    value={email}
                    error={error.email}
                    success={success.email}
                    width="100%"
                    margin="10px 0"
                />
                <InputField
                    type="text"
                    label="Employee Mobile Number"
                    placeholder="Employee Mobile Number"
                    id="phone"
                    onChange={(e) => handleChange(e, "phone")}
                    onBlur={() => handleBlur("phone")}
                    value={phone}
                    error={error.phone}
                    success={success.phone}
                    width="100%"
                    margin="10px 0"
                />
            </div>
            <Button contained width="100%" large onClick={handleSavepersonalDetails}>Save Details</Button>
        </Modal>
    )
}

export default EditPersonalDetailsModal
