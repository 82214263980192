import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  error: false,
  response: "",
};

const getjoblistSlice = createSlice({
  name: "getjoblist",
  initialState,
  reducers: {
    getjoblist: (state) => {
      state.loading = true;
    },
    getjoblistSuccess: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = false;
    },
    getjoblistFailure: (state) => {
      state.loading = false;
      state.error = "Something went wrong";
      state.response = "";
    },
  },
});
export default getjoblistSlice.reducer;

export const { getjoblist, getjoblistSuccess, getjoblistFailure } =
  getjoblistSlice.actions;

export const getjoblistSelector = (state) => state.loading;
