import classes from "./PersonalPresence.module.css";
import React from "react";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";

import { useLocation } from "react-router-dom";

import LeftArrow from "../../assets/icons/Left_blue.svg";
import RightArrow from "../../assets/icons/Right_blue.svg";
import EmployeeTopPanel from "../../compnents/EmployeeTopPanel/EmployeeTopPanel";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getMonthlyAttendance,
  getMonthlyAttendanceFailure,
  getMonthlyAttendanceSuccess,
} from "../../actions/getMonthlyAttendence";
import loader from "../../compnents/animations/finding.json";
import moment from "moment";
import Switch from "../../compnents/switch/Switch";
import AttendanceforEmployee from "../../compnents/attendanceforemployee/AttendanceforEmployee";
import Lottie from "react-lottie";
import NoteModal from "../../compnents/modal/Note/NoteModal";
import OvertimeModal from "../../compnents/modal/Overtime/OvertimeModal";
import LatefeeModal from "../../compnents/modal/Latefee/LatefeeModal";
import IndividualMonthlyAttendance from "../../compnents/IndividualMonthlyAttendance/IndividualMonthlyAttendance";

const PersonalPresence = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const monthlyAttendance = useSelector((state) => state.monthlyAttendance);
  const [switchOn, setSwitchOn] = React.useState(false);
  const [latefeeModalStatus, setLateFeeModalStatus] = React.useState(false);
  const [overtimeModalStatus, setOvertimeModalStatus] = React.useState(false);
  const [noteModalStatus, setNoteModalStatus] = React.useState(false);
  const [staffForModal, setStaffForModal] = React.useState(false);
  const [time, setTime] = React.useState(
    moment(new Date()).format("MMMM, YYYY")
  );

  const handleNoteModalClose = () => {
    setNoteModalStatus(false);
  };

  const handleLateFeeModalClose = () => {
    setLateFeeModalStatus(false);
  };
  const handleOvertimeModalClose = () => {
    setOvertimeModalStatus(false);
  };
  const handleNoteModalOpen = (id, name, lateFees, presence) => {
    setNoteModalStatus(true);
    setStaffForModal((prevState) => ({
      ...prevState,
      id: id,
      name: name,
      lateFees: lateFees,
      presence: presence,
    }));
  };

  const handleLateFeeModalOpen = (id, name, lateFees) => {
    setLateFeeModalStatus(true);
    setStaffForModal((prevState) => ({
      ...prevState,
      id: id,
      name: name,
      lateFees: lateFees,
    }));
  };
  const handleOvertimeModalOpen = (id, name, overtime, date) => {
    setOvertimeModalStatus(true);
    setStaffForModal((prevState) => ({
      ...prevState,
      id: id,
      name: name,
      overtime: overtime,
      date: date,
    }));
  };

  const handleSwitch = () => {
    setSwitchOn(!switchOn);
  };

  const details = location.state;
  // console.log(details);

  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_monthly_attendance`,
      method: "POST",
      data: {
        staff_id: details.id,
        month: moment(time).format("MM"),
        year: moment(time).format("YYYY"),
      },
    };
    dispatch(getMonthlyAttendance());
    axios(settings)
      .then((res) => {
        if (res.data.result == true) {
          dispatch(getMonthlyAttendanceSuccess(res.data));
        } else {
          dispatch(getMonthlyAttendanceFailure());
        }
      })
      .catch((err) => {
        dispatch(getMonthlyAttendanceFailure());
      });
  }, [time]);

  let staff_data;
  if (monthlyAttendance.loading == true) {
    staff_data = (
      <div
        style={{
          // position: "absolute",
          // width: "100vw",
          // height: "100vh",
          // backgroundColor: "rgba(0,0,0,0.1)",
          margin: "20px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
          height={200}
          width={200}
          style={{ padding: "0", marginTop: "-50px" }}
        />
      </div>
    );
  } else if (monthlyAttendance.response.result === false) {
    staff_data = (
      <div
        style={{
          // position: "absolute",
          // width: "100vw",
          // height: "100vh",
          // backgroundColor: "rgba(0,0,0,0.1)",
          margin: "20px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Something went wrong
      </div>
    );
  } else {
    if (monthlyAttendance.response !== "") {
      staff_data = monthlyAttendance.response.staff_data.map((entry) => {
        return (
          <IndividualMonthlyAttendance
            entry={entry}
            id={details.id}
            name={details.employee_name}
            handleLateFeeModalOpen={handleLateFeeModalOpen}
            handleOvertimeModalOpen={handleOvertimeModalOpen}
            handleNoteModalOpen={handleNoteModalOpen}
          />
        );
      });
    }
  }
  const handlePreviousMonth = () => {
    let month = time;
    let previousMonth = moment(time).subtract(1, "months").calendar();
    previousMonth = moment(previousMonth).format("MMM, YYYY");
    setTime(previousMonth);
  };
  const handleNextMonth = () => {
    let currentMonth = moment().format("M");
    let nextMonth = moment(time).add(1, "months").calendar();
    nextMonth = moment(nextMonth).format("MMM, YYYY");
    if (currentMonth >= moment(nextMonth).format("M")) {
      setTime(nextMonth);
    }
  };
  return (
    <Boilerplate>
      <EmployeeTopPanel details={details} />
      <div className={classes.date_wrapper}>
        <div className={classes.date}>
          <img
            src={LeftArrow}
            alt="Previous"
            onClick={handlePreviousMonth}
            style={{ cursor: "pointer", zIndex: "2" }}
          />
          <div>{time}</div>
          <img
            src={RightArrow}
            alt="Next"
            onClick={handleNextMonth}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className={classes.smsswitch}>
          <div>Send Absent sms to Employees</div>
          <Switch checked={switchOn} onChange={handleSwitch} />
        </div>
      </div>
      <div className={classes.employee_stats}>
        <div className={classes.box}>
          <div className={classes.boxtop}>{moment(new Date()).format("D")}</div>
          <div className={classes.boxbottom}>Total Days</div>
        </div>
        <div className={classes.box}>
          <div className={classes.boxtop}>
            {monthlyAttendance.response.present}
          </div>
          <div className={classes.boxbottom}>Present</div>
        </div>
        <div className={classes.box}>
          <div className={classes.boxtop}>
            {monthlyAttendance.response.absent}
          </div>
          <div className={classes.boxbottom}>Absent</div>
        </div>
        <div className={classes.box}>
          <div className={classes.boxtop}>
            {monthlyAttendance.response.half_day}
          </div>
          <div className={classes.boxbottom}>Half Day</div>
        </div>
        <div className={classes.box}>
          <div className={classes.boxtop}>
            {monthlyAttendance.response.overtime}
          </div>
          <div className={classes.boxbottom}>Overtime</div>
        </div>
        <div className={classes.box}>
          <div className={classes.boxtop}>
            {monthlyAttendance.response.late_fine}
          </div>
          <div className={classes.boxbottom}>Late Hours</div>
        </div>
        <div className={classes.box}>
          <div className={classes.boxtop}>
            {monthlyAttendance.response.paid_holiday}
          </div>
          <div className={classes.boxbottom}>Paid Leaves</div>
        </div>
      </div>
      <div className={classes.content_wrapper}>{staff_data}</div>
      <LatefeeModal
        handleLateFeeModalClose={handleLateFeeModalClose}
        handleLateFeeModalOpen={handleLateFeeModalOpen}
        latefeeModalStatus={latefeeModalStatus}
        staffName={staffForModal.name}
        staff_id={staffForModal.id}
        staff_latefee={staffForModal.lateFees}
        // date={regularDate}
      />
      <OvertimeModal
        handleOvertimeModalClose={handleOvertimeModalClose}
        handleOvertimeModalOpen={handleOvertimeModalOpen}
        overtimeModalStatus={overtimeModalStatus}
        staffName={staffForModal.name}
        staff_id={staffForModal.id}
        staff_overtime={staffForModal.overtime}
        date={staffForModal.date}
      />
      <NoteModal
        handleNoteModalClose={handleNoteModalClose}
        handleNoteModalOpen={handleNoteModalOpen}
        noteModalStatus={noteModalStatus}
        staffName={staffForModal.name}
        staff_id={staffForModal.id}
        staff_overtime={staffForModal.overtime}
        staff_presence={staffForModal.presence}
        // date={regularDate}
      />
    </Boilerplate>
  );
};

export default PersonalPresence;
