import Button, { createRipple } from "../../compnents/buttons/Button";
import classes from "../Home.module.scss";
import TelInput from "../../compnents/telinput/TelInput";
import telephone from "../../compnents/telinput/countrycode.json";
import React from "react";
import InputField from "../../compnents/inputs/Input";
import Logo from "../../assets/logos/Gradient/Main.svg";
import Back from "../../assets/icons/arrow-left.svg";
import OtpInput from "react-otp-input";
import Lottie from "react-lottie";
import Tag from "../../compnents/tag/Tag";
import animationData from "../../compnents/animations/Waiting.json";
import loader from "../../compnents/animations/finding.json";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import {
  getLogin,
  getLoginFailure,
  getLoginSuccess,
} from "../../actions/login";
import {
  getVerifyLogin,
  getVerifyLoginFailure,
  getVerifyLoginSuccess,
} from "../../actions/verifylogin";
import axios from "axios";
import {
  getupdateprofile,
  getupdateprofileFailure,
  getupdateprofileSuccess,
  updateprofileSelector,
} from "../../actions/update_profile";
import { useHistory } from "react-router-dom";
import {
  getprofile,
  getprofileFailure,
  getprofileSuccess,
} from "../../actions/getProfile";

const Home = () => {
  const history = useHistory();
  const loginResponse = useSelector((state) => state.loginResponse);
  const profile = useSelector((state) => state.profile);
  console.log(profile);
  const verifyLoginResponse = useSelector((state) => state.verifyResponse);
  const updateProfileResponse = useSelector(
    (state) => state.updateprofileresponse
  );
  const dispatch = useDispatch();

  const [phone, setPhone] = React.useState("");
  const [selectDropdown, setSelectDropdown] = React.useState("+91");
  const [flagImg, setFlagImg] = React.useState(
    "https://flagpedia.net/data/flags/h80/in.webp"
  );
  const [search, setSearch] = React.useState("");
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState("");
  const [formStep, setFormStep] = React.useState("one");
  const [otp, setOtp] = React.useState("");
  const [name, setName] = React.useState("");
  const [businessName, setBusinessName] = React.useState("");
  const [tag, setTag] = React.useState("");
  const [shift, setShift] = React.useState({
    hours: "7",
    minutes: "30",
  });

  const handleOtp = (val) => {
    setOtp(val);
  };
  const handleValidation = (type) => {
    let phoneRegx = /^\d[0-9]/;
    switch (type) {
      case "phone":
        if (phone.length !== 10 || phoneRegx.test(phone) == false) {
          setError((prevState) => ({
            ...prevState,
            phone: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            phone: "",
          }));
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            phone: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            phone: "",
          }));
        }
        break;
      case "name":
        if (name.length <= 3) {
          setError((prevState) => ({
            ...prevState,
            name: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            name: "",
          }));
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            name: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            name: "",
          }));
        }
        break;
      case "businessName":
        if (businessName.length <= 3) {
          setError((prevState) => ({
            ...prevState,
            businessName: "Not Valid",
          }));
          setSuccess((prevState) => ({
            ...prevState,
            businessName: "",
          }));
        } else {
          setSuccess((prevState) => ({
            ...prevState,
            businessName: "success",
          }));
          setError((prevState) => ({
            ...prevState,
            businessName: "",
          }));
        }
        break;
    }
  };
  const handleDropdown = (e) => {
    let name = e.target.innerText;
    let countryCode = telephone.filter((country) => {
      if (country.name === name) {
        return country.dial_code;
      }
    });
    let countryshortform = telephone.filter((country) => {
      if (country.name === name) {
        return country.code;
      }
    });
    setSelectDropdown(countryCode[0].dial_code);
    setFlagImg(
      `https://flagpedia.net/data/flags/h80/${countryshortform[0].code.toLowerCase()}.webp`
    );
  };
  const handleContinue = async (e, step) => {
    createRipple(e);
    switch (step) {
      case "one":
        if (error.phone == "") {
          var settings = {
            url: `${process.env.REACT_APP_API_URL}/login`,
            method: "POST",
            data: {
              mobile: phone,
              country_code: selectDropdown,
            },
          };
          dispatch(getLogin());
          axios(settings).then((res) => {
            if (res.data.result == true) {
              dispatch(getLoginSuccess(res.data));
              setFormStep("two");
            } else {
              dispatch(getLoginFailure(res.data.message));
            }
          });
        }
        break;
      case "two":
        if (otp !== "") {
          var settings = {
            url: `${process.env.REACT_APP_API_URL}/login_verify`,
            method: "POST",
            data: {
              mobile: phone,
              otp: otp,
            },
          };
          dispatch(getVerifyLogin());
          axios(settings).then((res) => {
            if (res.data.owner == "yes") {
              dispatch(getVerifyLoginSuccess(res.data));
              var settings = {
                url: `${process.env.REACT_APP_API_URL}/get_profile`,
                method: "POST",
                data: {
                  owner_id: verifyLoginResponse.response.owner_id,
                  id: verifyLoginResponse.response.owner_id,
                },
              };
              dispatch(getprofile());
              axios(settings)
                .then((res) => {
                  if (res.data.result === true) {
                    dispatch(getprofileSuccess(res.data.data));
                    setFormStep("three");
                  } else {
                    dispatch(getprofileFailure());
                  }
                })
                .catch((err) => {
                  dispatch(getprofileSuccess());
                });
            } else {
              dispatch(getVerifyLoginFailure());
              setError((prevState) => ({
                ...prevState,
                otp: verifyLoginResponse.error,
              }));
            }
          });
        }

        break;
      case "three":
        if (name !== "" && businessName !== "") {
          setFormStep("four");
        }
        break;
      case "four":
        if (tag !== "") {
          var settings = {
            url: `${process.env.REACT_APP_API_URL}/update_profile`,
            method: "POST",
            data: {
              id: verifyLoginResponse.response.owner_id,
              mobile: phone,
              otp: otp,
              name: name,
              business_name: businessName,
              calender_type: tag,
              shift_hours: shift.hours,
            },
          };
          dispatch(getupdateprofile());
          axios(settings).then((res) => {
            if (res.data.result == true) {
              dispatch(getupdateprofileSuccess(res.data));
              Cookies.set("owner_id", verifyLoginResponse.response.owner_id, {
                expires: 7,
              });
              setFormStep("five");
              if (Cookies.get("owner_id")) {
                history.push("/employees");
              }
            } else {
              dispatch(getupdateprofileFailure(res.data.message));
              setError((prevState) => ({
                ...prevState,
                server: "Something went Wrong",
              }));
            }
          });
        }
    }
  };
  const handleInput = (e, type) => {
    switch (type) {
      case "name":
        setName(e.target.value);
        break;
      case "businessName":
        setBusinessName(e.target.value);
        break;
      case "search":
        setSearch(e.target.value);
        break;
      case "phone":
        setPhone(e.target.value);
        break;
    }
  };
  const handleSlider = (e, type) => {
    switch (type) {
      case "hours":
        setShift((prevState) => ({
          ...prevState,
          hours: e.target.value,
        }));
        break;
      case "minutes":
        setShift((prevState) => ({
          ...prevState,
          minutes: e.target.value,
        }));
    }
  };

  let pageContent;
  if (formStep === "one") {
    pageContent = (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <img src={Logo} alt="Logo" className={classes.logo} />
          <div className={classes.heading}>Welcome !</div>
          <div className={classes.instruction}>
            Please enter your phone number to login or signup
          </div>
          <div className={classes.inputRow}>
            <TelInput
              items={telephone}
              selected={selectDropdown}
              onClick={(e) => handleDropdown(e)}
              flagImg={flagImg}
              search={search}
              onSearch={(e) => handleInput(e, "search")}
              large
            />
            <div>
              <InputField
                id="phone"
                large
                label="Phone"
                placeholder="Phone"
                value={phone}
                onChange={(e) => handleInput(e, "phone")}
                onBlur={() => handleValidation("phone")}
                error={error.phone}
                success={success.phone}
                width="100%"
              />
            </div>
          </div>
        </div>
        <Button
          contained
          width="100%"
          large
          onClick={(e) => handleContinue(e, "one")}
        >
          Continue
        </Button>
      </>
    );
  } else if (formStep === "two") {
    pageContent = (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <div
            className={classes.backButton}
            onClick={() => setFormStep("one")}
          >
            <img src={Back} alt="back" />
          </div>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
            }}
            height={200}
            width={200}
            style={{ padding: "0", marginTop: "-50px" }}
          />
          <div className={classes.heading} style={{ margin: "0" }}>
            Welcome !
          </div>
          <div className={classes.instruction}>
            Please enter the otp we sent to your device ending with
            {phone.substr(phone.length - 4, phone.length)}
          </div>
          <OtpInput
            value={otp}
            onChange={handleOtp}
            numInputs={5}
            containerStyle={{
              width: "70%",
              display: "flex",
              justifyContent: "space-around",
              marginTop: "5vh",
            }}
            inputStyle={{
              outline: "none",
              height: "30px",
              width: "30px",
              borderTopStyle: "hidden",
              borderLeftStyle: "hidden",
              borderRightStyle: "hidden",
              borderBottom: "2px solid #bcbcbc",
              fontSize: "1rem",
              fontWeight: "500",
            }}
            hasErrored={false}
            errorStyle={{
              color: "red",
            }}
            isInputNum={true}
          />
          <div
            style={{
              color: "#FF605C",
              fontSize: "14px",
              fontWeight: "500",
              marginTop: "2vh",
            }}
          >
            {verifyLoginResponse.error}
          </div>
        </div>
        <Button
          large
          contained
          width="100%"
          onClick={(e) => handleContinue(e, "two")}
        >
          Continue
        </Button>
      </>
    );
  } else if (formStep === "three") {
    if (
      profile.response.name !== "" &&
      profile.response.business_name !== "" &&
      profile.response.calender_type !== "" &&
      profile.response.shift_hours !== ""
    ) {
      Cookies.set("owner_id", profile.response.id, { expires: 7 });
      if (Cookies.get("owner_id")) {
        history.push("/employees");
      }

      pageContent = (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
              }}
              height={200}
              width={200}
            />
            <div className={classes.heading} style={{ width: "100%" }}>
              Welcome back, <br /> {profile.response.name}
            </div>
          </div>
        </>
      );
    } else if (
      profile.response.name !== "" &&
      profile.response.business_name !== ""
    ) {
      pageContent = (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <div
              className={classes.backButton}
              onClick={() => setFormStep("three")}
            >
              <img src={Back} alt="back" />
            </div>
            <div className={classes.heading} style={{ marginTop: "0" }}>
              Few More Things !
            </div>
            <div className={classes.instruction} style={{ width: "100%" }}>
              How do you calculate monthly salary?
            </div>
            <div className={classes.tag_wrapper}>
              <Tag
                active={tag === "calender"}
                value="calender"
                onClick={() => setTag("calender")}
              >
                <div className={classes.tag_heading}>Calendar month</div>
                <div className={classes.tag_content}>
                  (Ex. March - 31 days,
                  <br /> April - 30 days)
                </div>
              </Tag>
              <Tag
                active={tag === "Monthly"}
                value="Monthly"
                onClick={() => setTag("Monthly")}
              >
                <div className={classes.tag_heading}>30 days month</div>
                <div className={classes.tag_content}>
                  (Ex. March - 30 days,
                  <br /> April - 30 days)
                </div>
              </Tag>
            </div>
            <div className={classes.instruction} style={{ width: "80%" }}>
              Your employee’s shift duration is{" "}
              <span style={{ fontWeight: "500", textDecoration: "underline" }}>
                {shift.hours}h {shift.minutes}mins
              </span>
            </div>
            <div className={classes.slider_container}>
              <input
                type="range"
                min="0"
                max="12"
                value={shift.hours}
                onChange={(e) => handleSlider(e, "hours")}
                className={classes.slider}
              />
              <div className={classes.slider_indicator}>
                <div>0h</div>
                <div>12h</div>
              </div>
            </div>
            <div className={classes.slider_container}>
              <input
                type="range"
                min="0"
                max="59"
                value={shift.minutes}
                onChange={(e) => handleSlider(e, "minutes")}
                className={classes.slider}
              />
              <div className={classes.slider_indicator}>
                <div>0 m</div>
                <div>59 m</div>
              </div>
            </div>
            <div
              style={{
                color: "#FF605C",
                fontSize: "14px",
                fontWeight: "500",
                marginTop: "2vh",
              }}
            >
              {error.server}
            </div>
          </div>

          <Button
            large
            contained
            width="100%"
            onClick={(e) => handleContinue(e, "four")}
          >
            Continue
          </Button>
        </>
      );
    } else {
      pageContent = (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <div
              className={classes.backButton}
              onClick={() => setFormStep("two")}
            >
              <img src={Back} alt="back" />
            </div>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
              }}
              height={200}
              width={200}
              style={{ padding: "0", marginTop: "-50px" }}
            />
            <div className={classes.heading} style={{ margin: "0" }}>
              Almost there !
            </div>
            <div className={classes.instruction} style={{ width: "100%" }}>
              Please enter your name and business name & continue <br />
              to dashboard
            </div>
            <div style={{ width: "100%", marginBottom: "2vh" }}>
              <InputField
                label="Your Name"
                placeholder="Your Name"
                id="your name"
                value={name}
                onChange={(e) => handleInput(e, "name")}
                onBlur={() => handleValidation("name")}
                error={error.name}
                success={success.name}
                large
                width="100%"
              />
            </div>
            <div style={{ width: "100%" }}>
              <InputField
                label="Business Name"
                placeholder="Business Name"
                id="business name"
                value={businessName}
                onChange={(e) => handleInput(e, "businessName")}
                onBlur={() => handleValidation("businessName")}
                error={error.businessName}
                success={success.businessName}
                large
                width="100%"
              />
            </div>
          </div>
          <Button
            large
            contained
            width="100%"
            onClick={(e) => handleContinue(e, "three")}
          >
            Continue
          </Button>
        </>
      );
    }
  } else if (formStep == "four") {
    pageContent = (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          <div
            className={classes.backButton}
            onClick={() => setFormStep("three")}
          >
            <img src={Back} alt="back" />
          </div>
          <div className={classes.heading} style={{ marginTop: "0" }}>
            Few More Things !
          </div>
          <div className={classes.instruction} style={{ width: "100%" }}>
            How do you calculate monthly salary?
          </div>
          <div className={classes.tag_wrapper}>
            <Tag
              active={tag === "calender"}
              value="calender"
              onClick={() => setTag("calender")}
            >
              <div className={classes.tag_heading}>Calendar month</div>
              <div className={classes.tag_content}>
                (Ex. March - 31 days,
                <br /> April - 30 days)
              </div>
            </Tag>
            <Tag
              active={tag === "Monthly"}
              value="Monthly"
              onClick={() => setTag("Monthly")}
            >
              <div className={classes.tag_heading}>30 days month</div>
              <div className={classes.tag_content}>
                (Ex. March - 30 days,
                <br /> April - 30 days)
              </div>
            </Tag>
          </div>
          <div className={classes.instruction} style={{ width: "80%" }}>
            Your employee’s shift duration is{" "}
            <span style={{ fontWeight: "500", textDecoration: "underline" }}>
              {shift.hours}h {shift.minutes}mins
            </span>
          </div>
          <div className={classes.slider_container}>
            <input
              type="range"
              min="0"
              max="12"
              value={shift.hours}
              onChange={(e) => handleSlider(e, "hours")}
              className={classes.slider}
            />
            <div className={classes.slider_indicator}>
              <div>0h</div>
              <div>12h</div>
            </div>
          </div>
          <div className={classes.slider_container}>
            <input
              type="range"
              min="0"
              max="59"
              value={shift.minutes}
              onChange={(e) => handleSlider(e, "minutes")}
              className={classes.slider}
            />
            <div className={classes.slider_indicator}>
              <div>0 m</div>
              <div>59 m</div>
            </div>
          </div>
          <div
            style={{
              color: "#FF605C",
              fontSize: "14px",
              fontWeight: "500",
              marginTop: "2vh",
            }}
          >
            {error.server}
          </div>
        </div>

        <Button
          large
          contained
          width="100%"
          onClick={(e) => handleContinue(e, "four")}
        >
          Continue
        </Button>
      </>
    );
  } else if (formStep === "five") {
    pageContent = (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            boxSizing: "border-box",
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: animationData,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
            }}
            height={200}
            width={200}
          />
          <div className={classes.heading} style={{ width: "100%" }}>
            Welcome back, <br /> {updateProfileResponse.response.user_data.name}
          </div>
        </div>
      </>
    );
  }
  let loading;
  if (
    loginResponse.loading ||
    verifyLoginResponse.loading ||
    updateProfileResponse.loading
  ) {
    loading = (
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "1",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
          height={200}
          width={200}
          style={{ padding: "0", marginTop: "-50px" }}
        />
      </div>
    );
  }
  return (
    <>
      {loading}
      <div className={classes.container}>
        <div className={classes.container_content}>{pageContent}</div>
      </div>
    </>
  );
};
export default Home;
