import React from "react";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import classes from "./Help.module.css";
import Whatsapp from "../../assets/icons/whatsapp_blue.svg";
import Mail from "../../assets/icons/Mail_blue.svg";
import Help_blue from "../../assets/icons/Help_blue.svg";

const Help = (props) => {
  return (
    <Boilerplate>
      <div className={classes.scroll_wrapper}>
        <div className={classes.wrapper}>
          <img src={Whatsapp} alt="Whatsapp" />
          <div className={classes.content_wrapper}>
            <div className={classes.heading}>Whatsapp Chat</div>
            <div className={classes.content}>
              Chat with us on Whatsapp <br /> Monday - Friday : 9:30 AM - 7:00
              PM <br /> Saturday : 9:30 AM - 2:30 PM
            </div>
          </div>
        </div>
        <a href="mailto: admin@salarybook.co.in" className={classes.wrapper2}>
          <img src={Mail} alt="Email" />
          <div className={classes.content_wrapper}>
            <div className={classes.heading}>Email</div>
            <div className={classes.content}>
              admin@salarybook.co.in <br /> Replies within 24 hrs
            </div>
          </div>
        </a>
        <div className={classes.wrapper}>
          <img src={Help_blue} alt="Help" />
          <div className={classes.content_wrapper}>
            <div className={classes.heading}>Help & Support</div>
            <div className={classes.content}>Replies within 3 minutes</div>
          </div>
        </div>
      </div>
    </Boilerplate>
  );
};

export default Help;
