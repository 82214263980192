import React from 'react'
import classes from "./Radio.module.css"

const Radio = (props) => {
    return (
        <label className={classes.container}>{props.description}
            <input type="radio" checked={props.checked} name={props.name ? props.name : "radio"} value={props.value} onChange={props.onChange} />
            <span className={classes.checkmark}></span>
        </label>
    )
}

export default Radio
