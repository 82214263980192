import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  error: false,
  response: "",
};

const verifyloginSlice = createSlice({
  name: "verifylogin",
  initialState,
  reducers: {
    getVerifyLogin: (state) => {
      state.loading = true;
    },
    getVerifyLoginSuccess: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = false;
    },
    getVerifyLoginFailure: (state) => {
      state.loading = false;
      state.error = "Enter valid OTP";
      state.response = "";
    },
  },
});
export default verifyloginSlice.reducer;

export const {
  getVerifyLogin,
  getVerifyLoginSuccess,
  getVerifyLoginFailure,
} = verifyloginSlice.actions;

export const verifyloginSelector = (state) => state.response;

export const fetchVerifyLogin = (phone, otp) => async (dispatch) => {
  var settings = {
    url: `http://salarybook.co.in/public/api/login_verify`,
    method: "POST",
    data: {
      mobile: phone,
      otp: otp,
    },
  };
  dispatch(getVerifyLogin());
  axios(settings).then((res) => {
    if (res.data.owner == "yes") {
      dispatch(getVerifyLoginSuccess(res.data));
    } else {
      dispatch(getVerifyLoginFailure());
    }
  });
};
