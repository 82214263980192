import React from 'react';
import Modal from '../Modal'
import Cross from "../../../assets/icons/Cross.svg";
import classes from "./NewBusinessModal.module.css"
import Button from '../../buttons/Button';
import InputField from '../../inputs/Input';

const NewBusinessModal = (props) => {
    return (
        <Modal modalStatus={props.newBusinessModalStatus} onRequestClose={props.handleCloseNewBusinessModal}>
            <div>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Add New Business</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleCloseNewBusinessModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <InputField
                    id="business"
                    label="Business Name"
                    placeholder="Business Name"
                    value={props.business}
                    onChange={(e) => props.handleInput(e, "business")}
                    onBlur={() => props.handleValidation("business")}
                    error={props.error.business}
                    success={props.success.business}
                    width="100%"
                    margin="2vh 0 0 0 "
                    large
                />
                <InputField
                large
                    id="mobile"
                    label="Business Mobile Number"
                    placeholder="Business Mobile Number"
                    value={props.mobile}
                    onChange={(e) => props.handleInput(e, "mobile")}
                    onBlur={() => props.handleValidation("mobile")}
                    error={props.error.mobile}
                    success={props.success.mobile}
                    width="100%"
                    margin="2vh 0 0 0 "
                />
            </div>
            <Button contained width="100%" large onClick={props.handleNewBusiness}>
                Add Business
            </Button>
        </Modal>
    )
}

export default NewBusinessModal;