import styled from "styled-components";
import classes from "./Button.module.scss"
import React from "react"

export function createRipple(event) {
    const button = event.currentTarget;
    const circle = document.createElement("span");
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    circle.style.width = circle.style.height = `${diameter}px`;
    circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
    circle.classList.add(classes.ripple);

    const ripple = button.getElementsByClassName(classes.ripple)[0];

    if (ripple) {
      ripple.remove();
    }
    button.appendChild(circle);
}
const Button = (props) => {
    const [windowSize, setWindowSize] = React.useState({
        width: undefined,
        height: undefined,
      });

      React.useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
          // Set window width/height to state
          setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
      }, []); // Empty array ensures that effect is only run on mount
      return (
          <Button_style {...props} windowSize={windowSize} fontSize={windowSize.width < "1400" ? props.fontSmall : props.fontSize}>{props.children}</Button_style>
      )
}

const Button_style = styled.button`
    width: ${(props) => props.width ? props.width : "auto"};
    padding: ${(props) => props.windowSize.width > "1400" ? "13px 35px" : "13px 15px"};
    margin: ${(props) => props.margin};
    position: relative;
    overflow: hidden;
    height: ${(props) => props.large ? "48px !important" : "36px !important"};
    outline: none;
    background-color: ${(props) => props.contained ? "#157EF8" : "#fff"};
    border: ${(props) => props.contained ? "2px solid #157EF8" : "2px solid #157EF8"};
    border-radius: ${(props) => props.large ? "16px" : "12px"};
    color:${(props) => props.contained ? "#fff" : "#157EF8"}; 
    font-size: ${(props) => props.fontSize ? props.fontSize : "14px"};
    font-weight: ${(props) => props.fontWeight ? props.fontWeight : "500"};
    font-family: "Poppins", sans-serif;
    letter-spacing: 0.5px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    &:disabled {
        background-color: ${(props) => props.contained ? "#EBEBEB" : "#fff"};
        color: #BCBCBC;
        border: ${(props) => props.contained ? "none" : "2px solid #EBEBEB"};
        &:hover {
            color: #BCBCBC;
        }
    }
    &:hover {
        // background-color: ${(props) => props.contained ? "#1371DE" : "#fff"};
        color: ${(props) => props.contained ? "#fff" : "#1371DE"};
    }
    &:focus {
        border: ${(props) => props.contained ? "2px solid #1371DE" : "2px solid #157EF8"}
    }
    & span{
        & .ripple {
            background-color: ${(props) => props.contained ? "#1371DE" : "#157EF8"};
        }
    }
    @media (max-width: 1369px) {
        font-size: 0.8rem;
    }
`
export default Button