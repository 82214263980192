import axios from "axios";
import moment from "moment";
import React from "react";
import classes from "./PersonalSummary.module.css";
import LeftArrow from "../../assets/icons/Left_blue.svg";
import RightArrow from "../../assets/icons/Right_blue.svg";
import Payment from "../../assets/icons/Payalt_white.svg";
import Expand from "../../assets/icons/Downalt_blue.svg";
import { useLocation } from "react-router";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import EmployeeTopPanel from "../../compnents/EmployeeTopPanel/EmployeeTopPanel";
import Button from "../../compnents/buttons/Button";
import loader from "../../compnents/animations/finding.json";
import Lottie from "react-lottie";
import Summary_month_data from "../../compnents/Summary_month_data";

const PersonalSummary = () => {
  const location = useLocation();
  const details = location.state;
  const [time, setTime] = React.useState(
    moment(new Date()).format("MMMM, YYYY")
  );
  const [summary, setSummary] = React.useState("");
  const [mainToggle, setMainToggle] = React.useState(false);
  const [innerToggleEarning, setInnerToggleEarning] = React.useState(false);
  const [innerToggleDeduction, setInnerToggleDeduction] = React.useState(false);
  const handleMainToggle = () => {
    setMainToggle(!mainToggle);
  };
  const handleInnerToggleEarning = () => {
    setInnerToggleEarning(!innerToggleEarning);
  };
  const handleInnerToggleDeduction = () => {
    setInnerToggleDeduction(!innerToggleDeduction);
  };
  const handlePreviousMonth = () => {
    let month = time;
    let previousMonth = moment(time).subtract(1, "months").calendar();
    previousMonth = moment(previousMonth).format("MMM, YYYY");
    setTime(previousMonth);
  };
  const handleNextMonth = () => {
    let currentMonth = moment().format("M");
    let nextMonth = moment(time).add(1, "months").calendar();
    nextMonth = moment(nextMonth).format("MMM, YYYY");
    if (currentMonth >= moment(nextMonth).format("M")) {
      setTime(nextMonth);
    }
  };

  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/staff_salaryslip`,
      method: "POST",
      data: {
        staff_id: details.id,
        date_from: moment(time).subtract(3, "months").format("MMM YYYY"),
        date_to: time,
      },
    };
    axios(settings)
      .then((res) => {
        if (res.data.result === false) {
          alert("Something went wrong !!");
        } else {
          console.log(res.data.staff_salaryslip);
          setSummary(res.data.staff_salaryslip);
        }
      })
      .catch((err) => {
        alert("Something went wrong!! Try Again after sometimes");
      });
  }, [time]);

  let summary_data;
  if (summary === "") {
    summary_data = (
      <div
        style={{
          // position: "absolute",
          // width: "100vw",
          // height: "100vh",
          // backgroundColor: "rgba(0,0,0,0.1)",
          margin: "20px 0",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
          height={200}
          width={200}
          style={{ padding: "0", marginTop: "-50px" }}
        />
      </div>
    );
  } else {
    summary_data = (
      <>
        <div className={classes.entry_wrapper}>
          <div className={classes.heading_row}>
            <div className={classes.left_head}>Salary Summary</div>
            <div className={classes.right_head}>
              Current Balance:{" "}
              <span style={{ color: "#202020" }}>&#8377; {details.amount}</span>
            </div>
          </div>
          <div className={classes.entry_wrapper}>
            <div className={classes.heading_row}>
              <div className={classes.column1}>
                <img
                  src={Expand}
                  alt="Expand"
                  onClick={handleMainToggle}
                  style={{
                    transform:
                      mainToggle === true ? "rotate(-90deg)" : "rotate(90deg)",
                  }}
                />
              </div>
              <div className={classes.column2}>
                {moment(time).format("MMMM")} Net Salary
              </div>
              <div className={classes.column3}>
                {summary[summary.length - 1].date_from} -
                {summary[summary.length - 1].date_to}
              </div>
              <div className={classes.column4}>
                &#8377; {summary[summary.length - 1].month_amount}
              </div>
            </div>

            <div
              className={classes.heading_row}
              style={{
                margin: "20px 0",
                display: mainToggle === true ? "flex" : "none",
              }}
            >
              <div className={classes.column1}></div>
              <div className={classes.column2}>
                <img
                  src={Expand}
                  alt="Expand"
                  onClick={handleInnerToggleEarning}
                  style={{
                    transform:
                      innerToggleEarning === true
                        ? "rotate(-90deg)"
                        : "rotate(90deg)",
                  }}
                />
                <div>Earnings</div>
              </div>
              <div className={classes.column3}>
                {summary[summary.length - 1].present_count} Payable Days |{" "}
                {summary[summary.length - 1].overtime_count} Hrs Overtime
              </div>
              <div className={classes.column4}>
                &#8377; {summary[summary.length - 1].month_amount}
              </div>
            </div>
            <div
              className={classes.heading_row}
              style={{
                margin: "0",
                display: innerToggleEarning === true ? "flex" : "none",
              }}
            >
              <div className={classes.column1}></div>
              <div className={classes.column2}>
                <img
                  src={Expand}
                  alt="Expand"
                  style={{ visibility: "hidden" }}
                />
                <div>Presents</div>
              </div>
              <div className={classes.column3}>
                {summary[summary.length - 1].present_count} Days
              </div>
              <div className={classes.column4}>
                &#8377; {summary[summary.length - 1].month_amount}
              </div>
            </div>

            <div
              className={classes.heading_row}
              style={{
                margin: "20px 0",
                display: mainToggle === true ? "flex" : "none",
              }}
            >
              <div className={classes.column1}></div>
              <div className={classes.column2}>
                <img
                  src={Expand}
                  alt="Expand"
                  onClick={handleInnerToggleDeduction}
                  style={{
                    transform:
                      innerToggleDeduction === true
                        ? "rotate(-90deg)"
                        : "rotate(90deg)",
                  }}
                />
                <div>Deductions</div>
              </div>
              <div className={classes.column3}></div>
              <div className={classes.column4} style={{ color: "#27AE60" }}>
                {" "}
                &#8377;
                {summary[summary.length - 1].current_month_deduction_arr
                  .length === 0 ||
                summary[summary.length - 1].current_month_loan_deduction_arr
                  .length === 0
                  ? 0
                  : ""}
              </div>
            </div>
            <div
              className={classes.heading_row}
              style={{
                margin: "0",
                display: innerToggleDeduction === true ? "flex" : "none",
              }}
            >
              <div className={classes.column1}></div>
              <div className={classes.column2}>
                <img
                  src={Expand}
                  alt="Expand"
                  style={{ visibility: "hidden" }}
                />
                <div>Absents</div>
              </div>
              <div className={classes.column3}>
                {summary[summary.length - 1].absent_count} Days
              </div>
              <div className={classes.column4}></div>
            </div>
            <div
              className={classes.heading_row}
              style={{
                margin: "0",
                display: innerToggleDeduction === true ? "flex" : "none",
              }}
            >
              <div className={classes.column1}></div>
              <div className={classes.column2}>
                <img
                  src={Expand}
                  alt="Expand"
                  style={{ visibility: "hidden" }}
                />
                <div>Late Fines</div>
              </div>
              <div className={classes.column3}>
                {summary[summary.length - 1].late_fine_count} Days
              </div>
              <div className={classes.column4}></div>
            </div>
          </div>
        </div>
        {summary
          .slice()
          .reverse()
          .slice(1)
          .map((month_summary) => {
            return <Summary_month_data month_summary={month_summary} />;
          })}
      </>
    );
  }

  return (
    <Boilerplate>
      <EmployeeTopPanel details={details} />
      <div className={classes.date_wrapper}>
        <div className={classes.date}>
          <img
            src={LeftArrow}
            alt="Previous"
            onClick={handlePreviousMonth}
            style={{ cursor: "pointer", zIndex: "2" }}
          />
          <div>{time}</div>
          <img
            src={RightArrow}
            alt="Next"
            onClick={handleNextMonth}
            style={{ cursor: "pointer" }}
          />
        </div>
        <Button contained large>
          <img src={Payment} alt="Alarm" />
          <div>Make Payment</div>
        </Button>
      </div>
      <div className={classes.details_wrapper}>{summary_data}</div>
    </Boilerplate>
  );
};

export default PersonalSummary;
