import React from "react";
import classes from "./Hire.module.css";
import Boilerplate from "../../compnents/boilerplate/Boilerplate";
import Search from "../../assets/icons/search_grey.svg";
import AddEmployee from "../../assets/icons/plus-circle_white.svg";
import Dropdown from "../../compnents/dropdown/Dropdown";
import Button, { createRipple } from "../../compnents/buttons/Button";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  getjoblist,
  getjoblistFailure,
  getjoblistSuccess,
} from "../../actions/getjobslist";
import Lottie from "react-lottie";
import loader from "../../compnents/animations/finding.json";
import PostJobModal from "../../compnents/modal/PostJobModal/PostJobModal";
import { useHistory } from "react-router";

const Hire = (props) => {
  const [search, setSearch] = React.useState("");
  const businessid = useSelector((state) => state.businessid.value);
  const [jobModalStatus, setJobStatusModal] = React.useState(false);
  const joblist = useSelector((state) => state.joblist);
  const history = useHistory();

  const dispatch = useDispatch();
  const handleChange = (e) => {
    setSearch(e.target.value);
  };
  const handleCloseModal = () => {
    setJobStatusModal(false);
  };

  const handleOpenModal = () => {
    // createRipple(e);
    setJobStatusModal(true);
  };
  const handlejob = (jobid, jobstatus) => {
    if (jobstatus === "Active") {
      var settings = {
        url: `${process.env.REACT_APP_API_URL}/update_job`,
        method: "POST",
        data: {
          id: jobid,
          status: "Inactive",
        },
      };
    } else if (jobstatus === "Inactive") {
      var settings = {
        url: `${process.env.REACT_APP_API_URL}/update_job`,
        method: "POST",
        data: {
          id: jobid,
          status: "Active",
        },
      };
    }
    axios(settings)
      .then((res) => {
        alert(res.data.message);
        window.location.reload();
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };
  const handleApplicantsPage = (jobid) => {
    history.push({
      pathname: `/${jobid}/jobapplicants`,
      state: { details: joblist.response, jobid: jobid },
    });
  };

  let jobs;
  if (joblist.loading === true) {
    jobs = (
      <div
        style={{
          // position: "absolute",
          // width: "100vw",
          // height: "100vh",
          // backgroundColor: "rgba(0,0,0,0.1)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: loader,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
          height={200}
          width={200}
          style={{ padding: "0", marginTop: "-50px" }}
        />
      </div>
    );
  } else {
    if (joblist.response.length == 0 || joblist.response == "") {
      jobs = <div>Add Jobs</div>;
    } else {
      jobs = joblist.response
        .filter(
          (f) => f.job_category.toLowerCase().includes(search) || search === ""
        )
        .map((job) => {
          let statuscolor;
          if (job.status === "Active") {
            statuscolor = {
              color: "#27AE60",
            };
          } else {
            statuscolor = {
              color: "#FF605C",
            };
          }
          return (
            <div
              className={classes.data_wrapper}
              onClick={() => handleApplicantsPage(job.id)}
            >
              <div className={classes.job_details}>
                <div className={classes.job_name}>{job.job_profile}</div>
                <div className={classes.details}>
                  &#8377; {job.salary_from} - &#8377; {job.salary_to}
                </div>
              </div>
              <div className={classes.date_details}>
                <div className={classes.date}>03/05/2021</div>
                <div className={classes.details}>Posted On</div>
              </div>
              <div className={classes.status_details}>
                <div className={classes.status} style={statuscolor}>
                  {job.status}
                </div>
                <div className={classes.details}>Status</div>
              </div>
              <div className={classes.button_wrapper}>
                <Button large onClick={() => handlejob(job.id, job.status)}>
                  <div
                    style={{
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      wordWrap: "normal",
                    }}
                  >
                    {job.status === "Active"
                      ? "Deactivate Job"
                      : "Reactivate Job"}
                  </div>
                </Button>
              </div>
            </div>
          );
        });
    }
  }
  React.useEffect(() => {
    var settings = {
      url: `${process.env.REACT_APP_API_URL}/get_owner_jobs`,
      method: "POST",
      data: {
        owner_id: businessid === "0" ? Cookies.get("owner_id") : businessid,
      },
    };
    dispatch(getjoblist());
    axios(settings)
      .then((res) => {
        if (res.data.result === true) {
          dispatch(getjoblistSuccess(res.data.data));
        } else {
          dispatch(getjoblistFailure());
          if (res.data.message !== "no data found") {
            alert("Something went wrong !!");
          }
        }
      })
      .catch((err) => {
        dispatch(getjoblistFailure());
        alert("Something went wrong !! Try again after sometimes");
      });
  }, []);

  return (
    <Boilerplate>
      <div className={classes.stats_container}>
        <div className={classes.stats_wrapper}>
          <div className={classes.stats_numbers}>
            {joblist.response ? joblist.response.length : "-"}
          </div>
          <div className={classes.stats_description}>TOTAL JOBS POSTED</div>
        </div>
        <div className={classes.stats_wrapper}>
          <div className={classes.stats_numbers}>256</div>
          <div className={classes.stats_description}>TOTAL APPLICANTS</div>
        </div>
        <div className={classes.stats_wrapper}>
          <div className={classes.stats_numbers}>16</div>
          <div className={classes.stats_description}>TOTAL EMPLOYEES HIRED</div>
        </div>
      </div>
      <div className={classes.searchbar_container}>
        <div className={classes.searchbar_wrapper}>
          <img src={Search} alt="Search" />
          <input
            type="text"
            className={classes.searchbar}
            value={search}
            onChange={(e) => handleChange(e)}
            placeholder="Search Job"
          />
        </div>
        <Button
          contained
          large
          onClick={handleOpenModal}
          style={{ height: "48px" }}
        >
          <img src={AddEmployee} alt="plus" />
          Post Job
        </Button>
      </div>
      <div className={classes.data_container}>{jobs}</div>
      <PostJobModal
        jobModalStatus={jobModalStatus}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
      />
    </Boilerplate>
  );
};

export default Hire;
