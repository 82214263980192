import React from 'react'
import PostModal from '../PostModal'
import classes from "./PostJobModal.module.css"
import Cross from "../../../assets/icons/Cross.svg";
import InputField from '../../inputs/Input';
import Radio from '../../radio/Radio';
import Button from '../../buttons/Button';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Back from "../../../assets/icons/arrow-left.svg"
import { useHistory, useLocation } from 'react-router';

const EditInterviewerDetails = (props) => {
    const businessid = useSelector((state) => state.businessid.value);
    const [companyname, setCompanyName] = React.useState("");
    const [recruitername, setRecruiterName] = React.useState("");
    const [recruiternumber, setRecruiterNumber] = React.useState("");
    const [recruiterEmail, setRecruiterEmail] = React.useState("");
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    
    const handleInput = (e, type) => {
        switch(type) {
            case "Company Name":
                setCompanyName(e.target.value);
                break;
            case "Recruiter's Name":
                setRecruiterName(e.target.value);
                break;
            case "Recruiter's Number":
                setRecruiterNumber(e.target.value);
                break;
            case "Recruiter's Email":
                setRecruiterEmail(e.target.value);
        }
    }

    const handleValidation = (type) => {
        let phoneRegx = /^\d+$/;
        switch(type) {
            case "Company Name":
                if (companyname.length <= 3) {
                    setError((prevState) => ({
                        ...prevState,
                        companyname: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        companyname: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        companyname: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        companyname: "",
                    }));
                }
                break;
            case "Recruiter's Name":
                if (recruitername.length <= 3) {
                    setError((prevState) => ({
                        ...prevState,
                        recruitername: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruitername: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruitername: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        recruitername: "",
                    }));
                }
                break;
            case "Recruiter's Number":
                if (recruiternumber.length !== 10 || phoneRegx.test(recruiternumber) == false) {
                    setError((prevState) => ({
                        ...prevState,
                        recruiternumber: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruiternumber: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruiternumber: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        recruiternumber: "",
                    }));
                }
                break;
            case "Recruiter's Email":
                if (recruiterEmail.length <= 3) {
                    setError((prevState) => ({
                        ...prevState,
                        recruiterEmail: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruiterEmail: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        recruiterEmail: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        recruiterEmail: "",
                    }));
                }
                break;
        }
    }
    const handleEdit = () => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/update_job`,
            method: "POST",
            data: {
                id: props.id,
                company_name: companyname,
                contact_person: recruitername,
                phone: recruiternumber,
                email: recruiterEmail,
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === true) {
                alert(res.data.message);
                window.location.reload();
            } else {
                alert("Something went wrong !!")
            }
            }).catch(() => {
                alert("Something went wrong !! Try again after sometimes")
        })
    }
    const handleCancel = () => {
        props.handleCloseModal()
    }
    return (
        <PostModal modalStatus={props.jobModalStatus} onRequestClose={props.handleCloseModal}>
            <div style={{height: "90%", boxSizing: "border-box"}}>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Post a new job</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleCloseModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>Interviewer's Information</div>
            <div className={classes.details_wrapper} style={{marginTop: "2vh"}}>
                <div className={classes.user}>This information will be shared to the candidate who applies to this job.</div>
                <div>
                <InputField
                    type="text"
                    id="Company Name"
                    label="Company Name"
                    placeholder="Company Name"
                    value={companyname}
                    onChange={(e) => handleInput(e, "Company Name")}
                    onBlur={() => handleValidation("Company Name")}
                    error={error.companyname}
                    success={success.companyname}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                </div>
                <div>
                <InputField
                    type="text"
                    id="Recruiter's Name"
                    label="Recruiter's Name"
                    placeholder="Recruiter's Name"
                    value={recruitername}
                    onChange={(e) => handleInput(e, "Recruiter's Name")}
                    onBlur={() => handleValidation("Recruiter's Name")}
                    error={error.recruitername}
                    success={success.recruitername}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                </div>
                <div>
                <InputField
                    type="text"
                    id="Recruiter's Number"
                    label="Recruiter's Number"
                    placeholder="Recruiter's Number"
                    value={recruiternumber}
                    onChange={(e) => handleInput(e, "Recruiter's Number")}
                    onBlur={() => handleValidation("Recruiter's Number")}
                    error={error.recruiternumber}
                    success={success.recruiternumber}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                </div>
                <div>
                <InputField
                    type="text"
                    id="Recruiter's Email"
                    label="Recruiter's Email"
                    placeholder="Recruiter's Email"
                    value={recruiterEmail}
                    onChange={(e) => handleInput(e, "Recruiter's Email")}
                    onBlur={() => handleValidation("Recruiter's Email")}
                    error={error.recruiterEmail}
                    success={success.recruiterEmail}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                </div>
            </div>
            </div>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <Button large width="45%" onClick={handleCancel}>Cancel</Button>
                <Button large contained width="45%" onClick={handleEdit}>Edit Details</Button>
            </div>
        </PostModal>
    )
}

export default EditInterviewerDetails
