import React from 'react'
import PostModal from '../PostModal'
import classes from "./PostJobModal.module.css"
import Cross from "../../../assets/icons/Cross.svg";
import InputField from '../../inputs/Input';
import Radio from '../../radio/Radio';
import Button from '../../buttons/Button';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Back from "../../../assets/icons/arrow-left.svg"
import { useHistory, useLocation } from 'react-router';

const EditBasicDetails = (props) => {
    const businessid = useSelector((state) => state.businessid.value);
    const [jobrole, setJobRole] = React.useState("");
    const [qualification, setQualification] = React.useState("");
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    const [jobtype, setJobtype] = React.useState("Part Time");
    const [shifttype, setShiftType] = React.useState("Day Shift");
    const [gender, setGender] = React.useState("Both");
    const [englishlevel, setEnglishLevel] = React.useState("Great");

    const handleRadioJobtype = (jobtype) => {
        setJobtype(jobtype);
    }
    const handleRadioShifttype = (shifttype) => {
        setShiftType(shifttype)
    }
    const handleRadioGendertype = (gendertype) => {
        setGender(gendertype)
    }
    const handleRadioEnglishLevel = (englishlevel) => {
        setEnglishLevel(englishlevel);
    }
    const handleInput = (e, type) => {
        switch(type) {
            case "jobrole":
                setJobRole(e.target.value);
                break;
            case "qualification":
                setQualification(e.target.value);
                break;
        }
    }

    const handleValidation = (type) => {
        let phoneRegx = /^\d+$/;
        switch(type) {
            case "jobrole":
                if (jobrole.length <= 3) {
                    setError((prevState) => ({
                      ...prevState,
                      jobrole: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                      ...prevState,
                      jobrole: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                      ...prevState,
                      jobrole: "success",
                    }));
                    setError((prevState) => ({
                      ...prevState,
                      jobrole: "",
                    }));
                }
                break;
            case "qualification":
                if (qualification.length <= 2) {
                    setError((prevState) => ({
                      ...prevState,
                      qualification: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                      ...prevState,
                      qualification: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                      ...prevState,
                      qualification: "success",
                    }));
                    setError((prevState) => ({
                      ...prevState,
                      qualification: "",
                    }));
                }
                break;
        }
    }
    const handleEdit = () => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/update_job`,
            method: "POST",
            data: {
                id: props.id,
                job_profile: jobrole,
                job_type: jobtype,
                qualification: qualification,
                gender: gender,
                shift_type: shifttype,
                english_level: englishlevel
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === true) {
                alert(res.data.message);
                window.location.reload();
            } else {
                alert("Something went wrong !!")
            }
            }).catch(() => {
                alert("Something went wrong !! Try again after sometimes")
        })
    }
    const handleCancel = () => {
        props.handleCloseModal()
    }
    return (
        <PostModal modalStatus={props.jobModalStatus} onRequestClose={props.handleCloseModal}>
            <div style={{height: "90%", boxSizing: "border-box"}}>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Post a new job</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleCloseModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>Basic Details</div>
                <div className={classes.details_wrapper}>
                    <div>
                        <InputField
                            type="text"
                            id="job role"
                            label="Job role"
                            placeholder="Job role"
                            value={jobrole}
                            onChange={(e) => handleInput(e, "jobrole")}
                            onBlur={() => handleValidation("jobrole")}
                            error={error.jobrole}
                            success={success.jobrole}
                            width="100%"
                            margin="3vh 0 0 0"
                        />
                    </div>
                    <div>
                        <InputField
                            type="text"
                            id="minimum education"
                            label="Minimum Education"
                            placeholder="Minimum Education"
                            value={qualification}
                            onChange={(e) => handleInput(e, "qualification")}
                            onBlur={() => handleValidation("qualification")}
                            error={error.qualification}
                            success={success.qualification}
                            width="100%"
                            margin="3vh 0 0 0 "
                        />
                    </div>
                    <div className={classes.radiohead}>Job Type</div>
                    <div className={classes.row} id="jobtype">
                        <Radio
                            name="jobtype"
                            description="Part Time"
                            value="Part Time"
                            checked={jobtype === "Part Time" ? true : false}
                            onChange={() => handleRadioJobtype("Part Time")}
                        />
                        <Radio
                            name="jobtype"
                            description="Full Time"
                            value="Full Time"
                            checked={jobtype === "Full Time" ? true : false}
                            onChange={() => handleRadioJobtype("Full Time")}
                        />
                    </div>
                    <div className={classes.radiohead}>Shift Type</div>
                    <div className={classes.row} id="shifttype">
                        <Radio
                            name="shifttype"
                            description="Day Shift"
                            value="Day Shift"
                            checked={shifttype === "Day Shift" ? true : false}
                            onChange={() => handleRadioShifttype("Day Shift")}
                        />
                        <Radio
                            name="shifttype"
                            description="Night Shift"
                            value="Night Shift"
                            checked={shifttype === "Night Shift" ? true : false}
                            onChange={() => handleRadioShifttype("Night Shift")}
                        />
                    </div>
                    <div className={classes.radiohead}>Gender Preference</div>
                    <div className={classes.row} id="gender">
                        <Radio
                            name="gender"
                            description="Female"
                            value="Female"
                            checked={gender === "Female" ? true : false}
                            onChange={() => handleRadioGendertype("Female")}
                        />
                        <Radio
                            name="gender"
                            description="Male"
                            value="Male"
                            checked={gender === "Male" ? true : false}
                            onChange={() => handleRadioGendertype("Male")}
                        />
                        <Radio
                            name="gender"
                            description="Both"
                            value="Both"
                            checked={gender === "Both" ? true : false}
                            onChange={() => handleRadioGendertype("Both")}
                        />
                    </div>
                    <div className={classes.radiohead}>English Preference</div>
                    <div className={classes.row} id="english">
                        <Radio
                            name="english"
                            description="Excellent"
                            value="Excellent"
                            checked={englishlevel === "Excellent" ? true : false}
                            onChange={() => handleRadioEnglishLevel("Excellent")}
                        />
                        <Radio
                            name="english"
                            description="Great"
                            value="Great"
                            checked={englishlevel === "Great" ? true : false}
                            onChange={() => handleRadioEnglishLevel("Great")}
                        />
                        <Radio
                            name="english"
                            description="Average"
                            value="Average"
                            checked={englishlevel === "Average" ? true : false}
                            onChange={() => handleRadioEnglishLevel("Average")}
                        />
                    </div>
                </div>
            </div>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <Button large width="45%" onClick={handleCancel}>Cancel</Button>
                <Button large contained width="45%" onClick={handleEdit}>Edit Details</Button>
            </div>
        </PostModal>
    )
}

export default EditBasicDetails
