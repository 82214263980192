import classes from "./Boilerplate.module.css";
import React, { useLayoutEffect, useState } from "react";
import Logo from "../../assets/logos/Gradient/Main.svg";
import Staff from "../../assets/icons/Staff.svg";
import StaffBlue from "../../assets/icons/Staff_blue.svg";
import Presence from "../../assets/icons/Presence.svg";
import PresenceBlue from "../../assets/icons/Presence_blue.svg";
import Hire from "../../assets/icons/Hire.svg";
import HireBlue from "../../assets/icons/Hire_blue.svg";
import Settings from "../../assets/icons/settings-alt.svg";
import SettingsBlue from "../../assets/icons/settings-alt_blue.svg";
import Help from "../../assets/icons/Help.svg";
import HelpBlue from "../../assets/icons/Help_blue.svg";
import Update from "../../assets/icons/alarm-filled.svg";
import Dropdown from "../../compnents/dropdown/Dropdown";
import { Link, useLocation } from "react-router-dom";
import BusinessDropdown from "../business_dropdown/Business_dropdown";
import ProfileDropdown from "../profileDropdown/ProfileDropdown";

const Boilerplate = (props) => {
  const [selectDropdown, setSelectDropdown] = React.useState("Trail");
  const [windowWidth, windowHeight] = useWindowSize();
  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize([window.innerWidth, window.innerHeight]);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }
  const location = useLocation();
  const handleDropdown = (e) => {
    setSelectDropdown(e.target.innerText);
  };

  return (
    <div className={classes.grid_container}>
      <div className={classes.grid_item}>
        <div className={classes.left_nav}>
          <div
            className={classes.logo_wrapper}
            style={{
              width: windowWidth >= 1369 ? "64px" : "56px",
              height: windowWidth >= 1369 ? "64px" : "56px",
            }}
          >
            <img src={Logo} alt="Logo" width="30px" />
          </div>
          {/* <Dropdown
            size={window.innerWidth >= 1360 ? "large" : ""}
            items={[
              { id: "1", name: "Trail" }
            ]}
            selected={selectDropdown}
            onClick={(e) => handleDropdown(e)}
          /> */}
          <BusinessDropdown/>
          <div className={classes.sidebar}>
            <Link to="/employees" className={classes.link}>
              <div
                className={classes.border}
                style={{
                  border:
                    location.pathname === "/employees"
                      ? "2px solid #157ef8"
                      : "2px solid #fff",
                }}
              ></div>
              <img
                src={location.pathname === "/employees" ? StaffBlue : Staff}
                alt="employees"
                style={{ fill: "#157ef8" }}
              />
              <div
                className={classes.sidebar_content}
                style={{
                  color:
                    location.pathname === "/employees" ? "#157ef8" : "#202020",
                }}
              >
                Employees
              </div>
            </Link>
            <Link to="/presence" className={classes.link}>
              <div
                className={classes.border}
                style={{
                  border:
                    location.pathname === "/presence"
                      ? "2px solid #157ef8"
                      : "2px solid #fff",
                }}
              ></div>
              <img
                src={location.pathname === "/presence" ? PresenceBlue : Presence}
                alt="presence"
              />

              <div
                className={classes.sidebar_content}
                style={{
                  color:
                    location.pathname === "/presence" ? "#157ef8" : "#202020",
                }}
              >
                Presence
              </div>
            </Link>
            <Link to="/hire" className={classes.link}>
              <div
                className={classes.border}
                style={{
                  border:
                    location.pathname === "/hire"
                      ? "2px solid #157ef8"
                      : "2px solid #fff",
                }}
              ></div>
              <img
                src={location.pathname === "/hire" ? HireBlue : Hire}
                alt="hire"
              />
              <div
                className={classes.sidebar_content}
                style={{
                  color: location.pathname === "/hire" ? "#157ef8" : "#202020",
                }}
              >
                Hire
              </div>
            </Link>
            <Link to="/settings" className={classes.link}>
              <div
                className={classes.border}
                style={{
                  border:
                    location.pathname === "/settings"
                      ? "2px solid #157ef8"
                      : "2px solid #fff",
                }}
              ></div>
              <img
                src={
                  location.pathname === "/settings" ? SettingsBlue : Settings
                }
                alt="settings"
              />
              <div
                className={classes.sidebar_content}
                style={{
                  color:
                    location.pathname === "/settings" ? "#157ef8" : "#202020",
                }}
              >
                Settings
              </div>
            </Link>
            <Link to="/help" className={classes.link}>
              <div
                className={classes.border}
                style={{
                  border:
                    location.pathname === "/help"
                      ? "2px solid #157ef8"
                      : "2px solid #fff",
                }}
              ></div>
              <img
                src={location.pathname === "/help" ? HelpBlue : Help}
                alt="help"
              />
              <div
                className={classes.sidebar_content}
                style={{
                  color: location.pathname === "/help" ? "#157ef8" : "#202020",
                }}
              >
                Help
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.grid_item}>{props.children}</div>
      <div className={classes.grid_item}>
        <div className={classes.right_nav}>
          <div
            className={classes.logo_wrapper}
            style={{
              width: windowWidth >= 1369 ? "64px" : "56px",
              height: windowWidth >= 1369 ? "64px" : "56px",
              cursor: "pointer",
            }}
          >
            <img src={Update} alt="Notification" />
          </div>
          
          <ProfileDropdown size={window.innerWidth >= 1360 ? "large" : ""} rtl white />
        </div>
        <div className={classes.update_wrapper}>
          <div className={classes.heading}>Updates</div>
          <div className={classes.update_scroll_wrapper}>
            <div className={classes.update_content}>
              <div className={classes.update_message}>
                Abhishek Upmanyu just marked present for the day.
              </div>
              <div className={classes.time}>2 m</div>
            </div>
            <div className={classes.update_content}>
              <div className={classes.update_message}>
                Abhishek Upmanyu just marked present for the day.
              </div>
              <div className={classes.time}>2 m</div>
            </div>
            <div className={classes.update_content}>
              <div className={classes.update_message}>
                Abhishek Upmanyu just marked present for the day.
              </div>
              <div className={classes.time}>2 m</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Boilerplate;
