import moment from 'moment';
import React from 'react';
import Expand from "../assets/icons/Downalt_blue.svg";
import classes from "../pages/PersonalSummary/PersonalSummary.module.css"

const Summary_month_data = (props) => {
    const [mainToggle, setMainToggle] = React.useState(false);
    const [innerToggleEarning, setInnerToggleEarning] = React.useState(false);
    const [innerToggleDeduction, setInnerToggleDeduction] = React.useState(false);
    const handleMainToggle = () => {
        setMainToggle(!mainToggle);
    };
    const handleInnerToggleEarning = () => {
        setInnerToggleEarning(!innerToggleEarning);
    };
    const handleInnerToggleDeduction = () => {
        setInnerToggleDeduction(!innerToggleDeduction);
    };
    const {month_summary} = props;
    return (
        <div className={classes.entry_wrapper}>
                <div className={classes.heading_row}>
                  <div className={classes.column1}>
                    <img
                      src={Expand}
                      alt="Expand"
                      onClick={handleMainToggle}
                      style={{
                        transform:
                          mainToggle === true
                            ? "rotate(-90deg)"
                            : "rotate(90deg)",
                      }}
                    />
                  </div>
                  <div className={classes.column2}>
                    {moment(month_summary.date_from).format("MMMM")} Net Salary
                  </div>
                  <div className={classes.column3}>
                    {month_summary.date_from} -{month_summary.date_to}
                  </div>
                  <div className={classes.column4} style={{color: month_summary.month_amount < 0 ? "#27AE60" : "#FF605C"}}>
                    &#8377; {month_summary.month_amount}
                  </div>
                </div>

                <div
                  className={classes.heading_row}
                  style={{
                    margin: "20px 0",
                    display: mainToggle === true ? "flex" : "none",
                  }}
                >
                  <div className={classes.column1}></div>
                  <div className={classes.column2}>
                    <img
                      src={Expand}
                      alt="Expand"
                      onClick={handleInnerToggleEarning}
                      style={{
                        transform:
                          innerToggleEarning === true
                            ? "rotate(-90deg)"
                            : "rotate(90deg)",
                      }}
                    />
                    <div>Earnings</div>
                  </div>
                  <div className={classes.column3}>
                    {month_summary.present_count} Payable Days |{" "}
                    {month_summary.overtime_count} Hrs Overtime
                  </div>
                  <div className={classes.column4} style={{color: month_summary.month_amount < 0 ? "#27AE60" : "#FF605C"}}>
                    &#8377; {month_summary.month_amount}
                  </div>
                </div>
                <div
                  className={classes.heading_row}
                  style={{
                    margin: "0",
                    display: innerToggleEarning === true ? "flex" : "none",
                  }}
                >
                  <div className={classes.column1}></div>
                  <div className={classes.column2}>
                    <img
                      src={Expand}
                      alt="Expand"
                      style={{ visibility: "hidden" }}
                    />
                    <div>Presents</div>
                  </div>
                  <div className={classes.column3}>
                    {month_summary.present_count} Days
                  </div>
                  <div className={classes.column4} style={{color: month_summary.month_amount < 0 ? "#27AE60" : "#FF605C"}}>
                    &#8377; {month_summary.month_amount}
                  </div>
                </div>

                <div
                  className={classes.heading_row}
                  style={{
                    margin: "20px 0",
                    display: mainToggle === true ? "flex" : "none",
                  }}
                >
                  <div className={classes.column1}></div>
                  <div className={classes.column2}>
                    <img
                      src={Expand}
                      alt="Expand"
                      onClick={handleInnerToggleDeduction}
                      style={{
                        transform:
                          innerToggleDeduction === true
                            ? "rotate(-90deg)"
                            : "rotate(90deg)",
                      }}
                    />
                    <div>Deductions</div>
                  </div>
                  <div className={classes.column3}></div>
                  <div className={classes.column4} style={{ color: "#27AE60" }}>
                    {" "}
                    &#8377;
                    {month_summary.current_month_deduction_arr.length === 0 ||
                    month_summary.current_month_loan_deduction_arr.length === 0
                      ? 0
                      : ""}
                  </div>
                </div>
                <div
                  className={classes.heading_row}
                  style={{
                    margin: "0",
                    display: innerToggleDeduction === true ? "flex" : "none",
                  }}
                >
                  <div className={classes.column1}></div>
                  <div className={classes.column2}>
                    <img
                      src={Expand}
                      alt="Expand"
                      style={{ visibility: "hidden" }}
                    />
                    <div>Absents</div>
                  </div>
                  <div className={classes.column3}>
                    {month_summary.absent_count} Days
                  </div>
                  <div className={classes.column4}></div>
                </div>
                <div
                  className={classes.heading_row}
                  style={{
                    margin: "0",
                    display: innerToggleDeduction === true ? "flex" : "none",
                  }}
                >
                  <div className={classes.column1}></div>
                  <div className={classes.column2}>
                    <img
                      src={Expand}
                      alt="Expand"
                      style={{ visibility: "hidden" }}
                    />
                    <div>Late Fines</div>
                  </div>
                  <div className={classes.column3}>
                    {month_summary.late_fine_count} Days
                  </div>
                  <div className={classes.column4}></div>
                </div>
              </div>
    )
}

export default Summary_month_data
