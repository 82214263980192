import React from 'react'
import Modal from '../Modal'
import classes from "./NoteModal.module.css"
import Cross from "../../../assets/icons/Cross.svg";
import Button from '../../buttons/Button';
import axios from 'axios';
import { createRipple } from '../../buttons/ButtonError';



const NoteModal = (props) => {
    const [note, setNote] = React.useState("");
    const [error, setError] = React.useState("")
    const handleChangeNote = (e) => {
        setNote(e.target.value);
    }
    const handleError = () => {
        if (note === "") {
            setError("Fields can not be empty")
        } else {
            setError("")
        }
    }
    const handleAddNote = (e) => {
        createRipple(e)
        if (error === "") {
            var settings = {
                url: `${process.env.REACT_APP_API_URL}/update_staff_attendance`,
                method: "POST",
                data: {
                  staff_id: props.staff_id,
                  attendance: "Present",
                  date: props.date,
                  note: note,
                  status: "approved",
                },
              };
              axios(settings).then((res) => {
                  if (res.data.result === true) {
                      alert(res.data.message);
                      window.location.reload();
                  } else {
                      console.log(res);
                  }
              }).catch((err) => {
                  alert(err)
              })
        }
    }
    return (
        <Modal modalStatus={props.noteModalStatus} onRequestClose={props.handleNoteModalClose}>
            <div>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Add Note</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleNoteModalClose}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>For {props.staffName}</div>
                <textarea value={note} onChange={(e) => handleChangeNote(e)} onBlur={handleError}  placeholder="Enter Note Here" className={classes.textarea}/>
                {
                    error !== "" ? <div style={{color: "#FF605C", fontSize: "0.9rem", fontWeight: "500"}}>{error}</div> : null
                }
            </div>
            <Button contained width="100%" onClick={(e) => handleAddNote(e)}>Add Note</Button>
        </Modal>
    )
}

export default NoteModal
