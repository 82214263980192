import { faArrowDown, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import classes from "./Business_dropdown.module.css"
import {Link} from "react-router-dom"
import Button from '../buttons/Button'
import Cookies from 'js-cookie'
import axios from 'axios'
import NewBusinessModal from '../modal/NewBusinessModal/NewBusinessModal'
import { useDispatch, useSelector } from 'react-redux'
import { change } from "../../actions/businessId";

const BusinessDropdown = (props) => {
    const [newBusinessModalStatus, setNewBusinessModalStatus] = React.useState(false);
    const [mobile, setMobile] = React.useState("")
    const [business, setBusiness] = React.useState("");
    const [error, setError] = React.useState("")
    const [success, setSuccess] = React.useState("");
    const businessid = useSelector((state) => state.businessid.value);
    const dispatch = useDispatch();

    const handleInput = (e, type) => {
        switch (type) {
          case "business":
            setBusiness(e.target.value);
            break;
          case "mobile":
            setMobile(e.target.value);
            break;
        }
    }
    const handleValidation = (type) => {
        let phoneRegx = /^\d[0-9]/;
        switch (type) {
          case "business":
            if (business.length <= 3) {
              setError((prevState) => ({
                ...prevState,
                business: "Not Valid",
              }));
              setSuccess((prevState) => ({
                ...prevState,
                business: "",
              }));
            } else {
              setSuccess((prevState) => ({
                ...prevState,
                business: "success",
              }));
              setError((prevState) => ({
                ...prevState,
                business: "",
              }));
            }
            break;
    
          case "mobile":
            if (mobile.length !== 10 || phoneRegx.test(mobile) == false) {
              setError((prevState) => ({
                ...prevState,
                mobile: "Not Valid",
              }));
              setSuccess((prevState) => ({
                ...prevState,
                mobile: "",
              }));
              break;
            } else {
              setSuccess((prevState) => ({
                ...prevState,
                mobile: "success",
              }));
              setError((prevState) => ({
                ...prevState,
                mobile: "",
              }));
              break;
            }
        }
    }
    const handleNewBusiness = () => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/add_subowner`,
            method: "POST",
            data: {
                main_owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid,
                mobile: mobile,
                business_name: business
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === true) {
                alert("Added Successfully");
                window.location.reload();
            } else {
                alert("something went wrong !!")
            }
        }).catch((err) => {
            alert("Something went wrong!! Try again after sometime")
        })
    }

    const handleOpenNewBusinessModal = () => {
        
        setNewBusinessModalStatus(true);
    }
    const handleCloseNewBusinessModal = () => {
        setNewBusinessModalStatus(false);
    }
    let dropdowncontentstyle;

    const [list, setList] = React.useState([])
    const [selected, setSelected] = React.useState("");
    let dropdown_head_style;
    React.useEffect(() => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/get_subowners`,
            method: "POST",
            data: {
                main_owner_id: businessid === "0" ?  Cookies.get("owner_id") : businessid
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === true) {
                console.log(res.data);
                setList(res.data.data)
                setSelected(res.data.data[0].business_name)
            }
        })
    }, [])
    React.useEffect(() => {
        list.map((business) => {
            if (selected === business.business_name) {
                // Cookies.set("owner_id", business.id, {expires: 7});
                dispatch(change(business.id))
                
            }
        })
    }, [selected])
    if(props.size === "large") {
        dropdown_head_style = {
            color: props.white ? "#202020" : "",
            backgroundColor: props.white ? "#fff" : "",
            boxShadow: props.white ? "0 0 4px 2px rgba(0,0,0,0.06)" : "",
        }
    } else if(props.size === "small") {
        dropdown_head_style = {
            color: props.white ? "#202020" : "",
            backgroundColor: props.white ? "#fff" : "",
            boxShadow: props.white ? "0 0 4px 2px rgba(0,0,0,0.06)" : "",
        }
    } else {
        dropdown_head_style = {
            color: props.white ? "#202020" : "",
            backgroundColor: props.white ? "#fff" : "",
            boxShadow: props.white ? "0 0 4px 2px rgba(0,0,0,0.06)" : "",   
        }
    }
    if(props.drop) {
        dropdowncontentstyle= {
            opacity: "1",
            visibility: "visible",
            transition: "all 0.4s ease-in-out",
            fontSize: props.fontsize
        }
    } else {
        dropdowncontentstyle= {
            marginTop: "7px",
            display: "block",
            visibility: "hidden",
            opacity: "0",
            position: "absolute",
            boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.2)",
            minWidth: "150px",
            boxSizing: "border-box",
            border: "5px solid #fff",
            borderRadius: "5px",
            backgroundColor: "#fff",
            fontSize: props.fontsize
        }
    }
    const handleSelection = (e) => {
        setSelected(e.target.innerText);

    }
    let dropdown_content;
    if (list.length === 0) {
        dropdown_content = <div>No Business Available</div>
    } else {
        dropdown_content = list.map((business) => {
            return (
                <div style={{padding: "6px 24px", 
                                backgroundColor: selected === business.business_name ? "#f0f7fe": "#fff", 
                                color: selected === business.business_name ? "#157EF8": "#202020",
                                marginBottom: "10px"
                            }}
                            className={classes.dropdown_link}
                            onClick={handleSelection}>
                        {business.business_name}
                        <FontAwesomeIcon icon={faCheck} />
                    </div>
            )
        })
    }
    return (
        <div className={classes.dropdown_wrapper}>
            <span className={classes.dropdown_head} style={dropdown_head_style}>
                <div style={{marginRight: "1rem", display: "flex", gap: "10px", alignItems: "center", whiteSpace: "nowrap", textOverflow: "ellipsis", width: "90%"}}>
                    {
                        props.icon ? <div style={{border: "2px solid white", borderRadius: "16px",  overflow: "hidden", display: "flex", justifyContent: "center", alignItems: 'center'}}><img src={props.icon} alt="logo" /></div> : null
                    }
                    {selected}</div>
                <div className={classes.dropdown_arrow}></div>
            </span>
            <div className={classes.dropdown_content_wrapper} style={{right: props.rtl ? "20px": ""}}>
                <div className={classes.dropdown_content}>
                    {dropdown_content}
                    {/* {
                        props.items.map((item) => {
                            return (
                                
                            )
                        })
                    } */}
                    <Button contained width="100%" onClick={handleOpenNewBusinessModal}>Add New Business</Button>
                </div>
            </div>
            <NewBusinessModal handleNewBusiness={handleNewBusiness} newBusinessModalStatus={newBusinessModalStatus} handleCloseNewBusinessModal={handleCloseNewBusinessModal} handleOpenNewBusinessModal={handleOpenNewBusinessModal} business={business} mobile={mobile} handleInput={handleInput} handleValidation={handleValidation} error={error} success={success}/>
        </div>
    )
}

export default BusinessDropdown;
