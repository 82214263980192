import classNames from 'classnames'
import React from 'react'
import classes from "./Checkbox.module.css"

const Checkbox = (props) => {
    return (
        <label className={classes.container}>One 
            <input type="checkbox" checked={props.checked} onChange={props.onChange}/>
            <span className={classes.checkmark}></span>
        </label>
    )
}

export default Checkbox;
