import React from 'react'
import PostModal from '../PostModal'
import classes from "./PostJobModal.module.css"
import Cross from "../../../assets/icons/Cross.svg";
import InputField from '../../inputs/Input';
import Radio from '../../radio/Radio';
import Button from '../../buttons/Button';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import Back from "../../../assets/icons/arrow-left.svg"
import { useHistory, useLocation } from 'react-router';
import Tag from '../../tag/Tag';

const EditAddressDetails = (props) => {
    const businessid = useSelector((state) => state.businessid.value);
    const [flatno, setflatno] = React.useState("");
    const [city, setCity] = React.useState("");
    const [area, setArea] = React.useState("");
    const [applicationRange, setApplicationRange] = React.useState("");
    const [error, setError] = React.useState("");
    const [success, setSuccess] = React.useState("");
    
    const handleTag = (range) => {
        setApplicationRange(range);
    }
    const handleInput = (e, type) => {
        switch(type) {
            case "Flat no./Plot no.":
                setflatno(e.target.value);
                break;
            case "City":
                setCity(e.target.value);
                break;
            case "Area":
                setArea(e.target.value);
                break;
        }
    }

    const handleValidation = (type) => {
        let phoneRegx = /^\d+$/;
        switch(type) {
            case "Flat no./Plot no.":
                if (flatno.length < 1) {
                    setError((prevState) => ({
                        ...prevState,
                        flatno: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        flatno: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        flatno: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        flatno: "",
                    }));
                }
                break;
            case "City":
                if (city.length < 3) {
                    setError((prevState) => ({
                        ...prevState,
                        city: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        city: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        city: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        city: "",
                    }));
                }
                break;
            case "Area":
                if (area.length < 3) {
                    setError((prevState) => ({
                        ...prevState,
                        area: "Not Valid",
                    }));
                    setSuccess((prevState) => ({
                        ...prevState,
                        area: "",
                    }));
                } else {
                    setSuccess((prevState) => ({
                        ...prevState,
                        area: "success",
                    }));
                    setError((prevState) => ({
                        ...prevState,
                        area: "",
                    }));
                }
                break;
        }
    }
    const handleEdit = () => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/update_job`,
            method: "POST",
            data: {
                id: props.id,
                city: city,
                area: area,
                application_range: applicationRange,
                address: flatno,
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === true) {
                alert(res.data.message);
                window.location.reload();
            } else {
                alert("Something went wrong !!")
            }
            }).catch(() => {
                alert("Something went wrong !! Try again after sometimes")
        })
    }
    const handleCancel = () => {
        props.handleCloseModal()
    }
    return (
        <PostModal modalStatus={props.jobModalStatus} onRequestClose={props.handleCloseModal}>
            <div style={{height: "90%", boxSizing: "border-box"}}>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Post a new job</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleCloseModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>Interview Address</div>
            <div className={classes.details_wrapper} style={{marginTop: "2vh"}}>
            
                <InputField
                    type="text"
                    id="Flat no./Plot no."
                    label="Flat no./Plot no."
                    placeholder="Flat no./Plot no."
                    value={flatno}
                    onChange={(e) => handleInput(e, "Flat no./Plot no.")}
                    onBlur={() => handleValidation("Flat no./Plot no.")}
                    error={error.flatno}
                    success={success.flatno}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                
                
                <InputField
                    type="text"
                    id="City"
                    label="City"
                    placeholder="City"
                    value={city}
                    onChange={(e) => handleInput(e, "City")}
                    onBlur={() => handleValidation("City")}
                    error={error.city}
                    success={success.city}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                
                <InputField
                    type="text"
                    id="Area"
                    label="Area"
                    placeholder="Area"
                    value={area}
                    onChange={(e) => handleInput(e, "Area")}
                    onBlur={() => handleValidation("Area")}
                    error={error.area}
                    success={success.area}
                    width="100%"
                    margin="3vh 0 0 0 "
                />
                
                <div className={classes.user} style={{fontWeight: "500", margin: "2vh 0"}}>Receive applications from</div>
                    <div className={classes.row}>
                        <Tag
                            active={applicationRange == "Within 10km" ? true : false}
                            onClick={() => handleTag("Within 10km")}
                        >
                            Within 10km
                        </Tag>
                        <Tag
                            active={applicationRange == "Within 25km" ? true : false}
                            onClick={() => handleTag("Within 25km")}
                        >
                            Within 25km
                        </Tag>
                        <Tag
                            active={applicationRange == "Within Mumbai" ? true : false}
                            onClick={() => handleTag("Within Mumbai")}
                        >
                            Within Mumbai
                        </Tag>
                    </div>
                </div>
            </div>
            <div style={{display:"flex", justifyContent:"space-between"}}>
                <Button large width="45%" onClick={handleCancel}>Cancel</Button>
                <Button large contained width="45%" onClick={handleEdit}>Edit Details</Button>
            </div>
        </PostModal>
    )
}

export default EditAddressDetails
