import classes from './Modal.module.css'
import React from 'react'
import ReactModal from 'react-modal'

const Modal = (props) => {
    return (
        <ReactModal ariaHideApp={false} style={{content: props.style}} className={classes.Modal} overlayClassName={classes.Overlay} isOpen={props.modalStatus} onRequestClose={props.onRequestClose} shouldCloseOnOverlayClick={false}>
            {props.children}
        </ReactModal>
    )
}

export default Modal
