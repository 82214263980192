import React from "react";
import classes from "../../pages/Employees/Employee.module.css";
import Download from "../../assets/icons/Download_blue.svg";
import Caret from "../../assets/icons/caret_down.svg";
import moment from "moment";
import Button, { createRipple } from "../../compnents/buttons/Button";
import { Link } from "react-router-dom";

import Summary from "../../assets/icons/User_Details.svg";
import Presence from "../../assets/icons/Presence.svg";
import Payments from "../../assets/icons/payments.svg";
import Payslip from "../../assets/icons/Payment_Slip-Alt.svg";
import Overtime from "../../assets/icons/Overtime_alt.svg";
import Allowance from "../../assets/icons/allowance.svg";
import Bonus from "../../assets/icons/bonus.svg";
import Deductions from "../../assets/icons/deduction.svg";
import Loans from "../../assets/icons/loans.svg";

const Employee = (props) => {
  const [height, setHeight] = React.useState("90px");
  const [visibility, setVisibility] = React.useState("hidden");

  const { staff } = props;
  const handleHeight = () => {
    setHeight(height === "90px" ? "fit-content" : "90px");
    setVisibility(visibility === "hidden" ? "visible" : "hidden");
  };
  const handleClick = (e) => {
    createRipple(e);
  };
  const handlePaymentClick = (e, name, id) => {
    createRipple(e);
    props.handleOpenPaymentModal(id, name);
  };
  return (
    <div
      className={classes.data_wrapper}
      style={{ height: height, transition: "height 0.3s ease" }}
      onClick={handleHeight}
    >
      <div className={classes.info}>
        <div className={classes.column1}>
          <div className={classes.name}>{staff.employee_name}</div>
          <div className={classes.mobile_number}>
            +91{staff.employee_mobile}
          </div>
        </div>
        <div className={classes.column2}>
          <div className={classes.duration}>
            {moment(staff.previous_month).format("DD MMMM")}
          </div>
          <img src={Caret} alt="to" width="10px" />
          <div className={classes.duration}>Present</div>
        </div>
        <div className={classes.column3}>
          <div
            className={classes.amount}
            style={{ color: staff.amount < 0 ? "#FF605C" : "#27AE60" }}
          >
            &#8377; {staff.amount}
          </div>
          <div className={classes.amount_type}>{staff.amount_status}</div>
        </div>
        <div className={classes.column4}>
          <Button
            large
            fontSize="16px"
            fontWeight="600"
            padding="10px"
            fontSmall="0.8rem"
            onClick={(e) => handleClick(e)}
          >
            <img src={Download} alt="download" />
            <div
              style={{
                width: "80%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                wordWrap: "normal",
              }}
              title="Salary Slip"
            >
              salary slip
            </div>
          </Button>
          <Button
            large
            fontSize="16px"
            fontWeight="600"
            padding="10px"
            fontSmall="0.8rem"
            onClick={(e) =>
              handlePaymentClick(e, staff.employee_name, staff.id)
            }
          >
            <div
              style={{
                width: "100%",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                wordWrap: "normal",
              }}
            >
              Add Payment
            </div>
          </Button>
        </div>
      </div>
      <div className={classes.actions} style={{ visibility: visibility }}>
        <Link
          to={{
            pathname: `/${staff.id}/summary`,
            state: staff,
          }}
          className={classes.link}
        >
          <div className={classes.box}>
            <img src={Summary} alt="icon" />
            <div className={classes.boxInfo}>Summary</div>
          </div>
        </Link>
        <Link
          to={{
            pathname: `/${staff.id}/presence`,
            state: staff,
          }}
          className={classes.link}
        >
          <div className={classes.box}>
            <img src={Presence} alt="icon" />
            <div className={classes.boxInfo}>Presence</div>
          </div>
        </Link>
        <Link
          to={{
            pathname: `/${staff.id}/payments`,
            state: staff,
          }}
          className={classes.link}
        >
          <div className={classes.box}>
            <img src={Payments} alt="icon" />
            <div className={classes.boxInfo}>Payments</div>
          </div>
        </Link>
        {/* <Link to="/presence" className={classes.link}> */}
        <div className={classes.box}>
          <img src={Payslip} alt="icon" />
          <div className={classes.boxInfo}>Pay Slip</div>
        </div>
        {/* </Link> */}
        <Link
          to={{
            pathname: `/${staff.id}/overtime`,
            state: staff,
          }}
          className={classes.link}
        >
          <div className={classes.box}>
            <img src={Overtime} alt="icon" />
            <div className={classes.boxInfo}>Overtime</div>
          </div>
        </Link>
        <Link
          to={{
            pathname: `/${staff.id}/allowance`,
            state: staff,
          }}
          className={classes.link}
        >
          <div className={classes.box}>
            <img src={Allowance} alt="icon" />
            <div className={classes.boxInfo}>Allowance</div>
          </div>
        </Link>
        <Link
          to={{
            pathname: `/${staff.id}/bonus`,
            state: staff,
          }}
          className={classes.link}
        >
          <div className={classes.box}>
            <img src={Bonus} alt="icon" />
            <div className={classes.boxInfo}>Bonus</div>
          </div>
        </Link>
        <Link
          to={{
            pathname: `/${staff.id}/deductions`,
            state: staff,
          }}
          className={classes.link}
        >
          <div className={classes.box}>
            <img src={Deductions} alt="icon" />
            <div className={classes.boxInfo}>Deductions</div>
          </div>
        </Link>
        <Link
          to={{
            pathname: `/${staff.id}/loans`,
            state: staff,
          }}
          className={classes.link}
        >
          <div className={classes.box}>
            <img src={Loans} alt="icon" />
            <div className={classes.boxInfo}>Loans</div>
          </div>
        </Link>
      </div>
    </div>
  );
};
export default Employee;
