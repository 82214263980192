import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const initialState = {
  loading: false,
  error: false,
  response: "",
};

const getstaffbonusSlice = createSlice({
  name: "getstaffbonus",
  initialState,
  reducers: {
    getstaffbonus: (state) => {
      state.loading = true;
    },
    getstaffbonusSuccess: (state, { payload }) => {
      state.response = payload;
      state.loading = false;
      state.error = false;
    },
    getstaffbonusFailure: (state) => {
      state.loading = false;
      state.error = "Something went wrong";
      state.response = "";
    },
  },
});
export default getstaffbonusSlice.reducer;

export const { getstaffbonus, getstaffbonusSuccess, getstaffbonusFailure } =
  getstaffbonusSlice.actions;

export const getstaffbonusSelector = (state) => state.loading;
