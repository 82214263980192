import React from 'react'
import classes from "./PaymentReportModal.module.css"
import Cross from "../../../assets/icons/Cross.svg";
import Modal from '../Modal'
import Radio from '../../radio/Radio';
import Calendar from 'react-calendar';
import CalendarIcon from "../../../assets/icons/Calendar_grey.svg"
import moment from 'moment';
import Button from '../../buttons/Button';
import { fonts } from "../../../compnents/pdffonts";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import axios from 'axios';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PaymentReportModal = (props) => {
    const {details, radio, handleRadio} = props;
    const [start_date, setStart_date] = React.useState("");
    const [stop_date, setStop_date] = React.useState("");
    const [showStartCalendar, setShowStartCalendar] = React.useState(false);
    const [showStopCalendar, setShowStopCalendar] = React.useState(false);
    const handleStartDate = (e) => {
        setStart_date(e)
        setShowStartCalendar(false);
    }
    const handleStopDate = (e) => {
        setStop_date(e)
        setShowStopCalendar(false);
    }
    
    const handleDownloadPDF = () => {
        var settings = {
            url: `${process.env.REACT_APP_API_URL}/staff_salaryslip`,
            method: "POST",
            data: {
                staff_id: details.id,
                date_from: start_date,
                date_to: stop_date
            }
        }
        axios(settings).then((res) => {
            if (res.data.result === false) {
                alert("Something went wrong ||");
            } else {
                function buildTableBody(data, columns) {
                    var body = [];
                    body.push(columns);
                    data.forEach(function (row) {
                      var dataRow = [];
        
                      columns.forEach(function (column) {
                        dataRow.push(row[column].toString());
                      });
        
                      body.push(dataRow);
                    });
                    return body;
                }
                function tablemain(data, columns) {
                    return {
                        table: {
                        headerRows: 1,
                        widths: [150,150,150,150],
                        body: buildTableBody(data, columns),
                        },
                    };
                }
                let totalallowance = res.data.staff_salaryslip[0].current_month_allowance_arr.reduce(function(prev, cur) {
                    return prev + cur.amount;
                }, 0)
                let totalbonus = res.data.staff_salaryslip[0].current_month_bonus_arr.reduce(function(prev, cur) {
                    return prev + cur.amount;
                }, 0)
                let totaldeduction = res.data.staff_salaryslip[0].current_month_deduction_arr.reduce(function(prev, cur) {
                    return prev + cur.amount;
                }, 0)
                let totalloan = res.data.staff_salaryslip[0].current_month_loan_deduction_arr.reduce(function(prev, cur) {
                    return prev + cur.amount;
                }, 0)
                var summary = {
                    pageSize: "A4",
                    pageOrientation: "landscape",
                    pageMargins: [40, 60, 40, 60],
                    content: [
                      {
                        columns: [
                          {
                            text: "Payment Summary Report",
                            style: "header",
                            width: "80%",
                          },
                        ],
                      },
                      {
                        columns: [
                            {
                                text: `Total Allowance: \u20A8 ${totalallowance}`,
                                style: "content"
                            }
                        ]
                      },
                      {
                        columns: [
                            {
                                text: `Total Bonus: \u20A8 ${totalbonus}`,
                                style: "content"
                            }
                        ]
                      },
                      {
                        columns: [
                            {
                                text: `Total Deduction: \u20A8 ${totaldeduction}`,
                                style: "content"
                            }
                        ]
                      },
                      {
                        columns: [
                            {
                                text: `Total Loan Amount: \u20A8 ${totalloan}`,
                                style: "content"
                            }
                        ]
                      },
                      tablemain(res.data.staff_salaryslip, [
                        "date_from",
                        "date_to",
                        "amount_status",
                        "month_amount"
                      ]),
                      
                    ],
                    styles: {
                      header: {
                        fontSize: 25,
                        margin: [250, 0, 50, 20],
                        font: "Roboto",
                        bold: true,
                        italic: false,
                        alignment: "right",
                      },
                      content: {
                        margin: [20, 0, 0, 20],
                        font: "Roboto",
                        bold: false,
                        italic: false,
                      },
                      header2: {
                          margin: [20,0,0,20],
                          font: "Roboto",
                          bold: true,
                          alignment: "center",
                          fontSize: 16
                      }
                    },
                };
                function tablepayments(data, columns) {
                    return {
                        table: {
                        headerRows: 1,
                        widths: [150,150,150,150],
                        body: buildTableBody(data, columns),
                        },
                    };
                }
                function tableallowance(data, columns) {
                    return {
                        table: {
                        headerRows: 1,
                        widths: [180,180,180],
                        body: buildTableBody(data, columns),
                        },
                    };
                }
                function tablebonus(data, columns) {
                    return {
                        table: {
                        headerRows: 1,
                        widths: [150,150,150,150],
                        body: buildTableBody(data, columns),
                        },
                    };
                }
                function tablededuction(data, columns) {
                    return {
                        table: {
                        headerRows: 1,
                        widths: [150,150,150,150],
                        body: buildTableBody(data, columns),
                        },
                    };
                }
                const fullslip = {
                    pageSize: "A4",
                    pageOrientation: "landscape",
                    pageMargins: [40, 60, 40, 60],
                    content: [
                      {
                        columns: [
                          {
                            text: "Payment Slip",
                            style: "header",
                            width: "70%",
                          },
                        ],
                      },
                      {
                        columns: [
                            {
                                text: `Total Allowance: \u20A8 ${totalallowance}`,
                                style: "content"
                            }
                        ]
                      },
                      {
                        columns: [
                            {
                                text: `Total Bonus: \u20A8 ${totalbonus}`,
                                style: "content"
                            }
                        ]
                      },
                      {
                        columns: [
                            {
                                text: `Total Deduction: \u20A8 ${totaldeduction}`,
                                style: "content"
                            }
                        ]
                      },
                      {
                        columns: [
                            {
                                text: `Total Loan Amount: \u20A8 ${totalloan}`,
                                style: "content"
                            }
                        ]
                      },
                      {
                          columns: [
                              {
                                  text: "Payments Details",
                                  style:"header2"
                              }
                          ]
                      },
                      tablepayments(res.data.staff_salaryslip[0].current_month_payments_arr, [
                        "date",
                        "pay_status",
                        "amount",
                        "description"
                      ]),
                      
                      {
                        columns: [
                            {
                                text: "Allowance Details",
                                style:"header2"
                            }
                        ]
                      },
                      tableallowance(res.data.staff_salaryslip[0].current_month_allowance_arr, [
                        "date",
                        "amount",
                        "description"
                      ]),
                      {
                        columns: [
                            {
                                text: "Bonus Details",
                                style:"header2"
                            }
                        ]
                      },
                      tablebonus(res.data.staff_salaryslip[0].current_month_bonus_arr, [
                        "date",
                        "amount",
                        "description"
                      ]),
                      {
                        columns: [
                            {
                                text: "Bonus Details",
                                style:"header2"
                            }
                        ]
                      },
                      tablededuction(res.data.staff_salaryslip[0].current_month_deduction_arr, [
                        "date",
                        "amount",
                        "type",
                        "description"
                      ]),

                    ],
                    styles: {
                      header: {
                        fontSize: 25,
                        margin: [250, 0, 50, 20],
                        font: "Roboto",
                        bold: true,
                        italic: false,
                        alignment: "right",
                      }, 
                      content: {
                        margin: [20, 0, 0, 20],
                        font: "Roboto",
                        bold: false,
                        italic: false,
                      },
                      header2: {
                        margin: [0, 0, 0, 20],
                        font: "Roboto",
                        bold: true,
                        italic: false,
                      }
                    },
                }
                if (radio === "Summary") {
                    pdfMake.createPdf(summary, null, fonts).download("summary.pdf");
                } else if (radio === "Full Slip") {
                    pdfMake.createPdf(fullslip, null, fonts).download("summary.pdf");
                }
                
            }
        })
    }
    return (
        <Modal modalStatus={props.payslipModalStatus} onRequestClose={props.handleClosePayslipModal}>
            <div>
                <div className={classes.heading_wrapper}>
                    <div className={classes.modal_heading}>Generate Salary Slip</div>
                    <img
                    src={Cross}
                    alt="Close"
                    onClick={props.handleClosePayslipModal}
                    style={{ cursor: "pointer" }}
                    />
                </div>
                <div className={classes.user}>For {details.employee_name}</div>
                <div className={classes.radio_wrapper}>
                    <Radio
                    description="Full Slip"
                    value="Full Slip"
                    checked={radio == "Full Slip" ? true : false}
                    onChange={() => handleRadio("Full Slip")}
                    />
                    <Radio
                    description="Summary"
                    value="Summary"
                    checked={radio == "Summary" ? true : false}
                    onChange={() => handleRadio("Summary")}
                    />
                </div>
                {
                    start_date === "undefined" || props.date === null ? null : <div className={classes.dateInput} style={{position: "relative", border: "2px solid #bcbcbc", height: "56px", borderRadius: "16px", margin: "20px 0"}}>
                    <div style={{position: "absolute", top: "5px", left: "20px", fontSize: "0.7rem", color: "#707070", fontWeight: "500"}}>Date</div>
                    <div style={{padding: "20px"}}>{moment(start_date).format("DD MMMM, YYYY")}</div>
                    <img src={CalendarIcon} alt="calendar" style={{position: "absolute", top: "20px", right: "20px"}} onClick={() => setShowStartCalendar(!showStartCalendar)}/>
                </div>
                }
                {showStartCalendar ? (
                    <div style={{position:"absolute", zIndex: "1"}}>
                        <Calendar
                            value={start_date}
                            onChange={(e) => handleStartDate(e)}
                            className={classes.calendar}
                            style={{ display: "none" }}
                            maxDate={new Date()}
                        />
                        </div>
                    ) : null
                }
                {
                    stop_date === "undefined" || props.date === null ? null : <div className={classes.dateInput} style={{position: "relative", border: "2px solid #bcbcbc", height: "56px", borderRadius: "16px", margin: "20px 0"}}>
                    <div style={{position: "absolute", top: "5px", left: "20px", fontSize: "0.7rem", color: "#707070", fontWeight: "500"}}>Date</div>
                    <div style={{padding: "20px"}}>{moment(stop_date).format("DD MMMM, YYYY")}</div>
                    <img src={CalendarIcon} alt="calendar" style={{position: "absolute", top: "20px", right: "20px"}} onClick={() => setShowStopCalendar(!showStopCalendar)}/>
                </div>
                }
                {showStopCalendar ? (
                    <div style={{position:"absolute", zIndex: "1"}}>
                        <Calendar
                            value={start_date}
                            onChange={(e) => handleStopDate(e)}
                            className={classes.calendar}
                            style={{ display: "none" }}
                            maxDate={new Date()}
                        />
                        </div>
                    ) : null
                }
            </div>
            <Button contained width="100%" large onClick={handleDownloadPDF}>Download Payslip</Button>
        </Modal>
    )
}

export default PaymentReportModal
