import React from "react";
import classes from "./Calendar.module.css";
import dates from "./dates.json";
// const { datesGenerator } = require("dates-generator");

// const presentDay = new Date();
// const year = presentDay.getFullYear();
// const month = presentDay.getMonth();
// const body = {
//   year,
//   month,
//   startingDay: 0,
// };
// const { dates } = datesGenerator(body);
// console.log(dates);

const Calendar_dates = (props) => {
  let calendar;
  if (props.tag == "Weekly") {
    calendar = (
      <div
        className={classes.week_wrapper}
        style={{ display: props.isopen ? "block" : "none" }}
      >
        <div className={classes.week_name} onClick={props.handleSalaryCycle}>
          Monday
        </div>
        <div className={classes.week_name} onClick={props.handleSalaryCycle}>
          Tuesday
        </div>
        <div className={classes.week_name} onClick={props.handleSalaryCycle}>
          Wednesday
        </div>
        <div className={classes.week_name} onClick={props.handleSalaryCycle}>
          Thursday
        </div>
        <div className={classes.week_name} onClick={props.handleSalaryCycle}>
          Friday
        </div>
        <div className={classes.week_name} onClick={props.handleSalaryCycle}>
          Saturday
        </div>
        <div className={classes.week_name} onClick={props.handleSalaryCycle}>
          Sunday
        </div>
      </div>
    );
  } else {
    calendar = (
      <div
        className={classes.calendar_container}
        style={{ display: props.isopen ? "flex" : "none" }}
      >
        {dates.map((date) => {
          return (
            <div
              className={classes.calendar_date}
              onClick={props.handleSalaryCycle}
              style={{
                border: props.value === date.date ? "2px solid #157EF8" : "0",
              }}
            >
              {date.date}
            </div>
          );
        })}
      </div>
    );
  }
  return <> {calendar} </>;
};

export default Calendar_dates;
